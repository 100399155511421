import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardinativo: {
        opacity: 0.6,
        filter: 'grayscale(1)',
    },
    cardContainer: {
        position: 'relative',
        background: theme.palette.type === 'dark' ? theme.palette.grey[700] : '#FFF',
        marginBottom: '8px',
        padding: theme.spacing(2, 1, 1),
        boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.25)',
        borderRadius: 8
    },
    textInfoPersonalizavel: {
        position: 'absolute',
        top: 3,
        right: 3,
        fontWeight: 400,
        fontSize: 10
    },
    cardImg: {
        display: "flex",
        objectFit: "cover",
        zIndex: 0,
        position: 'relative',
        background: theme.palette.common.white,
        width: "40px",
        height: "40px ",
        borderRadius: "5px",
    },
    descricaoContainer: {
        padding: theme.spacing(1, 0),
        width: '100%'
    },
    invisible: {
        display: 'none'
    }

}));
