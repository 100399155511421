import React, { useCallback } from 'react';
import {
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import TristeImg from 'arquivos-estaticos/global/imagens/triste.png';
import { DocumentTitle } from 'componentes';
import { VariaveisAmbiente } from 'ambiente';
import { isEmpty } from 'lodash';
import { SimplesPagina } from '../templates';
import { useStyles } from './erro-generico-styles';
import { useHistory } from 'react-router-dom';

interface ErroGenericoProps {
  titulo?: string;
  mensagem?: string;
  detalhe?: string;
  onRetry?: () => any;
}

export const ErroGenerico = ({
  onRetry,
  titulo,
  mensagem,
  detalhe
}: ErroGenericoProps) => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();
  const history = useHistory()

  const empresaNaoExiste = mensagem?.includes('403')

  const voltar = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    }
  }, [history]);

  return (
    <SimplesPagina>
      <Grid>
        <DocumentTitle titulo={VariaveisAmbiente.appName + " - Ocorreu um Erro!"} />
        <Grid
          container
          spacing={2}
          className={classes.gridRoot}
          direction={isMobile ? 'column' : 'row-reverse'}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={5} lg={6}>
            <Grid container justify="center">
              <Grid item xs={3} sm={6}>
                <img
                  className={classes.tristeImg}
                  src={TristeImg}
                  alt="Imagem não encontrado"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7} lg={6}>
            <Grid container justify={isMobile ? 'center' : undefined}>
              <Typography
                variant={isMobile ? 'h4' : 'h3'}
                align={isMobile ? 'center' : 'left'}
                gutterBottom
              >
                <strong>{empresaNaoExiste ? 'Cardápio não encontrado' : titulo}</strong>
              </Typography>
              <div className={classes.break}></div>
              <Typography variant="h6" align={isMobile ? 'center' : 'left'} gutterBottom>
                {empresaNaoExiste ? 'Verifique se você digitou o link corretamente ou solicite o QRCode com um atendente.' : isEmpty(mensagem) ? "Desculpe, ocorreu um erro inesperado ao realiazar a ação solicitada." : mensagem}
              </Typography>
              <div className={classes.break}></div>
              <br />
              <br />
              <div className={classes.break}></div>
              <Typography variant="subtitle2" align={isMobile ? 'center' : 'left'} gutterBottom>
                {!isEmpty(detalhe) && "Detalhe do Erro: " + detalhe}
              </Typography>
              <div className={classes.break}></div>
              {!empresaNaoExiste && (
                <Typography variant="subtitle1" align={isMobile ? 'center' : 'left'} gutterBottom>
                  {onRetry && "Clique no botão abaixo em alguns instantes para tentarmos realizar novamente a tarefa..."}
                </Typography>
              )}
              {(empresaNaoExiste || onRetry) &&
                <Button
                  fullWidth={false}
                  color="primary"
                  type="button"
                  size="large"
                  className={classes.btnRetry}
                  onClick={empresaNaoExiste ? voltar : onRetry}
                  variant="contained"
                >
                  {empresaNaoExiste ? 'VOLTAR À PÁGINA ANTERIOR' : "Tentar Novamente!"}
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimplesPagina>
  );
};
