import { makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

export const useStyles = makeStyles((theme) => ({
  appBar: ({ imagemUrl, pesquisa, categorias }: any) => ({
    transition: 'all 0.3s ease-in-out !important',
    backgroundImage: imagemUrl ? `url(${imagemUrl})` : 'none',
    height: isEmpty(categorias) ? (pesquisa ? '135px' : '68px') : pesquisa ? '183px' : '116px',
    backgroundRepeat: 'no-repeat',
    borderRadius: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: theme.palette.text.primary,
  }),
  bgToolbar: {

    background: theme.palette.type === 'dark' ? 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 80%)' : 'linear-gradient(0deg, rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.1) 80%)',
    height: '100%',
    width: '100%',
    position: 'absolute'

  },
  toolbar: {

    display: 'flex',
    minHeight: '68px !important',
    paddingRight: 5,
    paddingLeft: 5,
  },
  contentTabs: {
    justifyContent: 'center',
  },
  tab: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  searchBarContainer: {
    position: 'relative',
  },
  searchBar: {
    display: 'flex',
    background: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)'
  },
  inputRoot: {
    color: 'inherit',
    padding: '0 15px',
    '& input::placeholder': {
      color: theme.palette.text.primary,
      opacity: 1,
    },
  },
  input: {
    padding: theme.spacing(3, 1, 3, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  containerEmpresa: {
    flex: '1 1 100%',
    paddingLeft: 45,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      justifyContent: 'left',
    }
  },
  logo: {
    maxWidth: '50px',
    maxHeight: '50px',
    margin: 0,
    marginRight: 10,
    objectFit: 'contain',
  },
  nomeEmpresa: {
    lineHeight: '50px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  cancelSearchIcon: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: 12,
    top: 12,
    margin: 'auto'
  },
  searchIcon: {
    color: theme.palette.text.primary,
  },
}));
