class TimeStorage {
  constructor(private readonly Storage: Storage) {}
  

  save(key: string, data: any, expirationMinutes: number) {
    if (!this.Storage) {
      return false;
    }

    const expirationMS = expirationMinutes * 60 * 1000;
    const record = {
      value: JSON.stringify(data),
      timestamp: new Date().getTime() + expirationMS,
    };

    this.Storage.setItem(key, JSON.stringify(record));

    return data;
  }

  remove(key: string) {
    if (!this.Storage) {
      return false;
    }
    this.Storage.removeItem(key);
  }
  
  load(key: string) {
    if (!this.Storage) {
      return false;
    }

    const storageItem = this.Storage.getItem(key);
    if (!storageItem) {
      return false;
    }

    const record = JSON.parse(storageItem);

    if (!record) {
      return false;
    }

    return new Date().getTime() < record.timestamp && JSON.parse(record.value);
  }
}

export const timeStorage = new TimeStorage(localStorage);
