import { useEmpresaTema } from 'componentes/empresa-tema';
import React from 'react';
import { useResources } from 'resources';
interface DocumentTitleProps {
  titulo: string;
}

export const DocumentTitle = ({ titulo }: DocumentTitleProps) => {
  const { tema, empresa } = useEmpresaTema();

  const nomeEmpresa = empresa?.nomeFantasia
  const resources = useResources();

  React.useEffect(() => {
    document.title = nomeEmpresa ? `${nomeEmpresa} - ${titulo}` : `${titulo}`;
    resources.favIcon = tema?.logo;
    try {
      let link;
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = resources.favIcon;
      document.getElementsByTagName('head')[0].appendChild(link);
    } catch { }
  }, [nomeEmpresa, resources, resources.favIcon, tema?.logo, titulo]);

  return null;
};
