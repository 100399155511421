import React from 'react';
import { Botao, BotaoProps } from '../botao';
import { IconButton, IconButtonProps } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './botao-transparente-styles';

type BotaoTransparenteProps<Tipo extends 'button' | 'iconButton'> = Tipo extends 'button'
  ? Omit<BotaoProps, 'color' | 'variant'> & {
    tipo: Tipo;
  }
  : Omit<IconButtonProps, 'color'> & {
    tipo: Tipo;
  };

export const BotaoTransparente = <Tipo extends 'button' | 'iconButton'>({
  className,
  tipo,
  ...props
}: BotaoTransparenteProps<Tipo>) => {
  const classes = useStyles();

  return tipo === 'iconButton' ? (
    <IconButton
      {...(props as any)}
      color="inherit"
      className={classNames(classes.iconButton, className)}
    />
  ) : (
    <Botao
      {...props}
      variant="text"
      color="inherit"
      className={classNames(classes.botao, className)}
    />
  );
};
