import React, { ReactElement } from 'react';
import { useTheme, Container, useMediaQuery, Grid } from '@material-ui/core';
import { useResources } from 'resources';
import { MasterPagina } from '..';
import { useStyles } from './pagina-simples-styles';

interface SimplesPaginaProps {
  children: ReactElement;
}

export const SimplesPagina = ({ children }: SimplesPaginaProps) => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();
  const resources = useResources();

  return (
    <MasterPagina>
      <Container fixed>
        <Grid className={isMobile ? classes.gridSaurusMob : classes.gridSaurus} xs={12}>
          <img
            className={classes.saurusImg}
            src={resources.paginas.paginaSimples.logo.src}
            alt={resources.paginas.paginaSimples.logo.alt}
          />
        </Grid>
        <Grid xs={12}>{children}</Grid>
      </Container>
    </MasterPagina>
  );
};
