import { VariaveisAmbiente } from 'ambiente';
import { AuthError, NotificationError } from 'erros';
import { SaurusError } from 'erros/saurus-error';
import { MergeDeep } from 'utils';

export const FetchPedidosApi = (path: RequestInfo, options?: RequestInit) => {
  const optionsDefault: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(`${VariaveisAmbiente.pedidosApi}${path}`, MergeDeep(optionsDefault, options)).then(
    async (response) => {

      if (response.status === 401)
        throw new AuthError();

      const json = await response
        .clone()
        .json()
        .catch(() => null);


      if (response.ok === false) {

        //ERRO DE RETORNO DE API
        if (response.status >= 400 && response.status <= 499)
          throw new SaurusError((response.status ? ('(' + response.status + ') - ') : '') +
            (json.title || 'Verifique se você realizou as ações corretamente e tente novamente em alguns instantes.'),
            response);
        //ERRO DE FALTA DE COMUNICACAO
        else
          throw new NotificationError('error',
            (response.status ? ('(' + response.status + ') - ') : '') +
            (json.title || 'Erro de comunicação com o servidor, tente novamente em alguns instantes.')
          );
      }

      return response;
    },
  )
};
