import { ConfigEmpresaModel } from "api/cardapio/models/empresa/empresa-model"
import { EnumConfigEmpresa } from "api/cardapio/models/enuns/enum-config-empresa"
import { isEmpty } from "lodash"

export const getValueByConfig = (cod: EnumConfigEmpresa, configEmpresa: ConfigEmpresaModel[]) => {
    const configEmp = configEmpresa
    if (isEmpty(configEmp)) {
        return undefined
    }
    const config = configEmp.find(config => config.codigo === cod)

    return config
}