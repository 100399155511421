import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridRoot: {
      minHeight: '100vh',
    },
    naoEncontradoImg: {
      objectFit: 'contain',
      width: '100%',
      margin: '0 auto',
    },
    voltar: {
      marginTop: '10px',
    },
  }));