import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    rootModal: {
        height: '160px',
        [theme.breakpoints.down('sm')]: { height: '105px' },
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    btnsModal: {
        display: 'flex',
        justifyContent: 'center',
    },
    btnModal: {
        fontWeight: 600,

        width: '40%',
    },
    textoModal: {
        fontSize: '1.8rem',
        marginTop: '1rem',
        fontWeight: 'bolder',
        [theme.breakpoints.down('sm')]: { fontSize: '17px', marginTop: 'unset' },
    },
}));