import { ApiQuery } from 'api/interfaces/api-query';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { PedidoModel } from 'api/cardapio/models/pedido/pedido';

interface PedidoModelProps {
    empresaId: string;
    mesaId?: string;
    comandaId?: string;
}

class ObterPedido implements ApiQuery<PedidoModelProps, PedidoModel> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(props: PedidoModelProps): any[] {
        return ['ObterPedido', props]
    }

    async execute(props: PedidoModelProps) {
        let query = ''
        if (props.mesaId) {
            query = `?mesaId=${props.mesaId}`
        }

        if (props.comandaId) {
            query = `?comandaId=${props.comandaId}`
        }
        if (isValidUUIDV4(props.mesaId) || isValidUUIDV4(props.comandaId))
            return await FetchCardapioApiAsPublicUserJSON<PedidoModel>(props.empresaId, `api/v4/empresa/${props.empresaId}/pedidos${query}`);
        else
            return null;
    }
}

export const obterPedido = new ObterPedido();