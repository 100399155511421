import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { TemaPutModel } from 'api/cardapio/models/tema/tema-put-model';
import { getTema } from 'api/cardapio/queries/tema/get-tema';

export interface PutTemaProps {
  empresaId: string;
  tema: TemaPutModel;
}

class PutTema implements ApiMutation<PutTemaProps, void> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  async execute(tema: PutTemaProps): Promise<any> {
    const res = await FetchCardapioApiAsMasterUserJSON(`api/v4/empresa/${tema.empresaId}/tema`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(tema.tema),
    });

    return res;
  }

  updateCache(input: PutTemaProps, retorno: void, cacheInstance: QueryCache) {
    const temaKey = getTema.getKey({ empresaId: input.empresaId! });
    return cacheInstance.invalidateQueries(temaKey);
  }
}

export const putTema = new PutTema();

export const usePutTema = (
  config?: MutationConfig<void, unknown, PutTemaProps>,
) => {
  const res = useMutation(putTema.execute, config);
  return res;
};
