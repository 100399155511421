import { emphasize, makeStyles } from "@material-ui/core";
import { ImagemInputProps } from "./imagem-input-props";

export const useStyles = makeStyles((theme) => ({
    root: ({ value }: ImagemInputProps) => ({
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: value ? '10px 20px' : undefined,
        border: value ? `2px dashed ${theme.palette.primary.main}` : undefined,
        borderRadius: theme.shape.borderRadius,
    }),
    img: ({ imageWidth, imageHeight, mode }: ImagemInputProps) => ({
        objectFit: mode || 'cover',
        width: imageWidth || '150px',
        height: imageHeight || '150px',
        marginTop: '10px',
    }),
    removerImagem: ({ imageWidth }: ImagemInputProps) => ({
        marginTop: '10px',
        width: imageWidth || '150px',
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            background: emphasize(theme.palette.error.main, 0.15),
        },
    }),
}));