import React from 'react';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useStyles } from './card-produto-subitem-modificador-styles';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from 'utils/to-decimal';
import { AddESub } from '../card-produto-subitem/components/add-e-sub/button-add-e-sub';
import { EnumTipoProduto, EnumTpCalculoModificador, EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';
import { useCallback, useState } from 'react';
import { SaurusRadio } from 'componentes/inputs/radio/saurus-radio';
import { isEmpty } from 'lodash';

type CardSubitemModificadorProps = {
  produto: MovSimplesProdutoModel;
  handleClickAddOrSub: (
    valorProdutoPai: number,
    produto: MovSimplesProdutoModel,
    qtd: number,
    modificador?: MovSimplesModificadoresProdutoModel,
    prodGradeId?: string,
    fator?: 'add' | 'sub'
  ) => Promise<void>;
  verificar?: boolean;
  showValue?: boolean;
  edit?: boolean;
  qtdPai: number;
  modificador: MovSimplesModificadoresProdutoModel;
  qtdModificador: number;
  valorProdutoPai?: number
  tpProdutoPai: EnumTpProduto
};

export const CardSubitemModificador = ({
  produto,
  handleClickAddOrSub,
  verificar,
  showValue,
  edit,
  qtdPai,
  modificador,
  qtdModificador,
  valorProdutoPai = 0,
  tpProdutoPai
}: CardSubitemModificadorProps) => {
  // STATES E REFS
  const [qtd, setQtd] = useState<number>(produto.qCom);


  const isRadio = modificador.qMin === 1 && modificador.qMax === 1;
  const isRateio = modificador.tpCalculo === EnumTpCalculoModificador.Rateia;

  // AUX
  const classes = useStyles();

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  if (edit && verificar && produto.qCom < 0) {
    return null;
  }

  return (
    <div className={classes.cardContainer}>
      {/* {produto.contemSubItem && (
        <Typography
          variant="caption"
          className={classes.textInfoPersonalizavel}
        >
          Personalizável
        </Typography>
      )} */}

      <Grid
        style={{
          marginTop: produto.subItens.length > 0 || produto.contemSubItem ? '10px' : 0,
          display: 'flex',
          marginBottom: produto.subItens.length > 0 ? 4 : 0
        }}
      >
        <Grid style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {img(produto.imgUrl)}

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 4,
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 600, fontSize: '14px' }}
            >
              {produto.xProd}
            </Typography>
            {!isEmpty(produto.infAdic) && <Typography
              variant="body1"
              style={{ fontWeight: 400, fontSize: '12px' }}
            >
              {produto.infAdic}
            </Typography>}
            {!isRateio && <Grid container direction="column">
              {!((produto?.infoSubItem?.qPadrao ?? 0) > 0) && <Typography
                variant="body1"
                style={{ fontWeight: 400, fontSize: '12px' }}
              >
                {isRadio && ((tpProdutoPai as unknown as EnumTipoProduto) === EnumTipoProduto.Produto ? EnumTpProduto.ProdutoComSubItem : EnumTpProduto.Combo) === EnumTpProduto.Combo && produto.vUnCom - modificador.valorItemPadrao === 0 ? '' : ((tpProdutoPai as unknown as EnumTipoProduto) === EnumTipoProduto.Produto ? EnumTpProduto.ProdutoComSubItem : EnumTpProduto.Combo) === EnumTpProduto.Combo ? `Preço: R$ ${toDecimalString((isRadio ? produto.vUnCom - modificador.valorItemPadrao : produto.vUnCom) ?? 0, 2)}` : `Preço: R$ ${toDecimalString((produto.vUnCom) ?? 0, 2)}`}
              </Typography>}
              <Typography
                variant="body1"
                style={{ fontSize: '12px', height: '14.4px' }}
              >
                <span className={verificar && showValue ? '' : classes.invisible}>
                  + R$ ${toDecimalString(
                    (produto.qComModificador > 0
                      ? produto.qComModificador
                      : qtd) * produto.vUnCom ?? 0,
                    2
                  )}
                </span>
                <span className={produto.infoSubItem!.qPadrao >= qtd || verificar ? classes.invisible : ''}>
                  + R$ {toDecimalString(
                    (produto.qComModificador > 0
                      ? produto.qComModificador
                      : qtd - produto.infoSubItem!.qPadrao) *
                    produto.vUnCom ?? 0,
                    2
                  )}
                </span>
              </Typography>
            </Grid>}
          </Grid>
        </Grid>
        {isRadio ? (
          <FormControlLabel
            label=""
            checked={
              modificador.produtoSubGradeIdPadrao === produto.produtoGradeId
            }
            onClick={() => {
              handleClickAddOrSub(
                valorProdutoPai,
                produto,
                1,
                modificador,
                produto?.produtoGradeId ?? ''
              );
            }}
            control={<SaurusRadio />}
          />
        ) : (
          <Grid style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
            <AddESub
              edit={edit}
              qtd={qtd}
              setQtd={setQtd}
              model={produto}
              alterarQtdeProduto={handleClickAddOrSub}
              verificar={verificar}
              modificador={modificador}
              qtdModificador={qtdModificador}
              valorProdutoPai={valorProdutoPai}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
