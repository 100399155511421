export const isValidUUIDV4 = (uuid: any): boolean => {
    if (uuid === undefined)
        return false;

    let s = "" + uuid;
    let val = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');

    if (val === null) {
        return false;
    }
    return true;
}