import React from 'react';
import { ConfirmProvider as MaterialConfirmProvider } from 'material-ui-confirm';

interface ConfirmProviderProps {
  children: React.ReactNode;
}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  return (
    <MaterialConfirmProvider

      defaultOptions={{
        title: 'Atenção',
        cancellationText: 'Não',
        confirmationText: 'Sim',
        dialogProps: {
          keepMounted: true,
          disableEscapeKeyDown: true,
          style: {
            zIndex: 9999999999999999999
          }
        } as any,
        cancellationButtonProps: {
          color: 'secondary',
          variant: 'text',
          style: {
            textTransform: 'none',
          },
        },
        confirmationButtonProps: {
          color: 'primary',
          variant: 'text',
          style: {
            textTransform: 'none',
          },
        },
      }}
    >
      {children}
    </MaterialConfirmProvider>
  );
};
