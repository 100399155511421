import { TipoField } from "./text-field-saurus-types";
function mask(
  value: string,
  tipo?: TipoField,
  casasDecimais?: number,
  min?: number,
  max?: number
) {
  try {
    switch (tipo) {
      case "CEP":
        return maskCep(value);
      case "TELEFONE":
        return maskPhone(value);
      case "CPF":
        return maskCpf(value);
      case "CNPJ":
        return maskCnpj(value);
      case "CNPJ_CPF":
        return maskCnpjCpf(value);
      case "CNPJ_CPF_GERAL":
        return maskCnpjCpfGeral(value);
      case "DECIMAL":
        return maskMoedaNCasas(value, casasDecimais || 2);
      case "NUMERO":
        return maskNumeric(value);
      case "NUMERO_RANGE":
        return maskNumericRange(value, min || 0, max || 100);
      case "CARTAO":
        return maskCartao(value)
      case "UPPERCASE":
        return maskUpperCase(value)
      case "MMAA":
        return maskMMMAA(value)
      default:
        return value;
    }
  } catch (e: any) {
    return value;
  }
}

function maskNumeric(value: string) {
  return value.replace(/\D/g, "");
}

function maskNumericRange(value: string, min: number, max: number) {
  //esse input já é do tipo number, então vai
  let valor = parseFloat(value);
  if (valor > max) {
    valor = max;
  }
  if (valor < min) {
    valor = min;
  }
  return valor.toString();
}

function maskMMMAA(value: string) {
  let valor = value.replace(/\D/g, "").substring(0, 4);

  valor = valor.replace(/(\d{2})(\d)/, "$1/$2");

  return valor
}

function maskCep(value: string) {
  return value
    .replace(/\D/g, "")
    .substring(0, 8)
    .replace(/^(\d{5})(\d)/, "$1-$2");
}

function maskCpf(value: string) {
  let valor = value.replace(/\D/g, "").substring(0, 11);

  valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
  valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
  valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return valor;
}

function maskCartao(value: string) {
  let valor = value.replace(/\D/g, "").substring(0, 16);

  valor = valor.replace(/(\d{4})(\d)/, "$1 $2");
  valor = valor.replace(/(\d{4})(\d)/, "$1 $2");
  valor = valor.replace(/(\d{4})(\d)/, "$1 $2");
  valor = valor.replace(/(\d{4})(\d)/, "$1 $2");

  return valor;
}

function maskUpperCase(value: string) {
  return value.toUpperCase()
}

function maskCnpj(value: string) {
  let valor = value.replace(/\D/g, "").substring(0, 14);
  valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
  valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
  valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
  return valor;
}
function maskCnpjCpf(value: string) {
  var valor = value.replace(/\D/g, "");
  if (valor.length > 11) {
    return maskCnpj(value);
  } else if (valor.length <= 11) {
    return maskCpf(value);
  } else {
    return valor;
  }
}

function maskCnpjCpfGeral(value: string) {
  var valor = value.replace(/\D/g, "");

  if (valor.length === 14) {
    return maskCnpj(value);
  } else if (valor.length === 11) {
    return maskCpf(value);
  } else {
    return valor;
  }
}

function maskPhone(value: string) {
  let nvalor;
  let numeros = value.substring(0, 15).replace(/\D/g, "");

  if (numeros.length <= 10) {
    nvalor = numeros.replace(/^(\d\d)(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    nvalor = nvalor.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

    value = nvalor;
  } else if (numeros.length >= 11) {
    nvalor = numeros.replace(/^(\d\d)(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    nvalor = nvalor.replace(/(\d{5})(\d)/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

    value = nvalor;
  }

  return value.substring(0, numeros.length === 11 ? 15 : 14);
}

function formataMoeda(v: string, casas: number) {
  if (casas === null || casas === undefined) casas = 2;

  v = ("" + v).replace(/\D/g, "");
  if (casas === 1) {
    v = v.replace(/(\d+)(\d{1})/, "$1,$2");
  } else if (casas === 2) {
    v = v.replace(/(\d+)(\d{2})/, "$1,$2");
  } else if (casas === 3) {
    v = v.replace(/(\d+)(\d{3})/, "$1,$2");
  } else if (casas === 4) {
    v = v.replace(/(\d+)(\d{4})/, "$1,$2");
  } else if (casas === 5) {
    v = v.replace(/(\d+)(\d{5})/, "$1,$2");
  } else if (casas === 6) {
    v = v.replace(/(\d+)(\d{6})/, "$1,$2");
  } else if (casas === 7) {
    v = v.replace(/(\d+)(\d{7})/, "$1,$2");
  } else if (casas === 8) {
    v = v.replace(/(\d+)(\d{8})/, "$1,$2");
  } else if (casas === 9) {
    v = v.replace(/(\d+)(\d{9})/, "$1,$2");
  } else if (casas === 10) {
    v = v.replace(/(\d+)(\d{10})/, "$1,$2");
  } else if (casas === 0) {
    v = v.replace(/(\d+)(\d{0})/, "$1$2");
  }

  return v;
}

function maskMoedaNCasas(value: string, qtdCasasDecimais: number) {
  if (value.toUpperCase() === 'INFINITY') {
    return "∞";
  }

  let valor = value;
  if (valor.length > 0) {
    var keycode = valor.charCodeAt(valor.length - 1);

    let calcular_qtde =
      (valor.indexOf(",") > -1 && !(keycode === 46 || keycode === 44)) ||
      keycode === 0 ||
      keycode === 13 ||
      keycode > 990;

    let qtde = 0;

    if (calcular_qtde)
      qtde =
        valor.indexOf(",") > -1 ? valor.length - valor.indexOf(",") - 1 : 0;

    if (qtdCasasDecimais < qtde || !calcular_qtde) qtde = qtdCasasDecimais;

    if (keycode === 46 || keycode === 44) {
      if (valor.indexOf(",") > -1) {
        valor = valor.replaceAll(",", "");
      }
      if (valor.indexOf(".") > -1) {
        valor = valor.replaceAll(".", "");
      }

      //MUDO A QTDE PQ ACABEI DE DIGITAR A VIRGULA
      qtde = 0;
    }

    let neg = valor.indexOf("-") > -1;
    let pos = valor.indexOf("+") > -1;
    var numeros = formataMoeda(valor, qtde);

    valor = (neg && !pos ? "-" : "") + numeros;
    if (keycode === 46 || keycode === 44) {
      valor = valor.replaceAll(",", "") + ",";
    }
  }
  return valor;
}

export { mask };
