import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { useQuery, QueryConfig } from 'react-query';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto/produto-categoria-model';
import { useCallback } from 'react';

class GetProdutoCategoria implements ApiQuery<{ empresaId: string }, Array<ProdutoCategoriaModel>> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: { empresaId: string }): any[] {
    return ['GetProdutoCategoria', params];
  }

  async execute(_: any, empresa: { empresaId: string }) {
    return (await FetchCardapioApiAsPublicUserJSON<ProdutoCategoriaModel>(empresa.empresaId, `api/v4/empresa/${empresa.empresaId}/produtos/produto-categoria`)) as unknown as any;
  }
}

export const getProdutoCategoria = new GetProdutoCategoria();

export const useGetProdutoCategoria = (
  input: { empresaId: string },
  config: QueryConfig<Array<ProdutoCategoriaModel>, string>,
) => {

  const handleProdutos = useCallback(async (_: any, empresa: { empresaId: string }) => {

    return (await FetchCardapioApiAsPublicUserJSON<ProdutoCategoriaModel>(empresa.empresaId, `api/v4/empresa/${empresa.empresaId}/produtos/produto-categoria`)) as unknown as any;
  }, [])

  return useQuery(getProdutoCategoria.getKey(input), handleProdutos, config);
};
