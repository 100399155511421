import { makeStyles } from "@material-ui/core";
import Background from '../../../../arquivos-estaticos/global/imagens/bg.png';

export const useStyles = makeStyles((theme) => ({
    bg: ((BgImg) => ({
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0.04',
        filter: theme.palette.type === "light" ? 'invert(1)' : 'none',
        zIndex: 0,
        position: 'fixed',
        backgroundSize: 'auto 100%',
        backgroundImage: `url('${Background}')`,
        backgroundPosition: 'center',
        backgroundRepeatX: 'repeat',
        backgroundRepeatY: 'no-repeat'
    })),
    conteudo: {
        position: 'relative',
        zIndex: 1,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
}));