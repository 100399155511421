class StorageCardapio {
  constructor(private readonly Storage: Storage) {}

  save(key: string, data: any) {
    if (!this.Storage) {
      return false;
    }

    this.Storage.setItem(key, JSON.stringify(data));

    return data;
  }

  remove(key: string) {
    if (!this.Storage) {
      return false;
    }
    this.Storage.removeItem(key);
  }

  load(key: string) {
    if (!this.Storage) {
      return false;
    }

    const storageItem = this.Storage.getItem(key)|| '{}';

    return JSON.parse(storageItem) ;
  }
}

export const storageCardapio = new StorageCardapio(localStorage);
