import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, ItemSacola, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';

export interface EsvaziarSacolaInput {
  empresaId: string
}

class EsvaziarSacola implements ApiMutation<EsvaziarSacolaInput, MovSimplesProdutoModel> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute() {
    return storageCardapio.save(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
      [],
    );
  }

  updateCache(input: EsvaziarSacolaInput, retorno: MovSimplesProdutoModel, cache: QueryCache) {
    const sessoesKey = obterSacola.getKey(input.empresaId);
    return cache.invalidateQueries(sessoesKey);
  }
}

export const esvaziarSacola = new EsvaziarSacola();

export const useEsvaziarSacola = (
  config?: MutationConfig<ItemSacola, unknown, EsvaziarSacolaInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: ''
    },
    esvaziarSacola,
  );

  return useMutation(esvaziarSacola.execute, MergeDeep(defaultConfig, config));
};
