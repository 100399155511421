import React from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useResources } from 'resources';
import { useStyles } from './cardapio-footer-styles';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';

interface CardapioFooterProps {
  tema?: TemaModel;
  empresa?: EmpresaModel;
}

export const CardapioFooter = ({ tema, empresa }: CardapioFooterProps) => {
  const props = {
    capa: tema?.capa
  }
  const classes = useStyles(props);
  const resources = useResources();

  return (
    <footer className={classes.footer}>
      <div
        className={classes.footerBg}
      ></div>
      <div className={classes.containerEmpresa}>
        {!isEmpty(tema?.logo) && (
          <img src={(tema?.logo!)} className={classes.logo} alt="Logotipo do Cliente"></img>
        )}
        <div>
          <Typography variant="h6" align="left">
            {resources.paginas.cardapioFooter.tituloFooter(empresa?.nomeFantasia)}
          </Typography>
          <Typography variant="body2" align="left" style={{ marginTop: -5 }}>
            {resources.paginas.cardapioFooter.textoFooter}
          </Typography>
        </div>
      </div>
    </footer>
  );
};
