import { Cardapio } from "modulos/cardapio/containers";
import { LocalizacaoUsuario } from "modulos/cardapio/containers/cardapio/cardapio-localizacao"
import React from "react"
import { useParams } from "react-router-dom"

export const CardapioMesa = () => {
    const { empresaId } = useParams<{ empresaId: string }>();

    const path = window.location.pathname.split('/')
    
    return (
        <>
            <LocalizacaoUsuario empresaId={empresaId} mesaId={path[2]}>
                <Cardapio somenteLeitura={true} configurando={false} contextoSelecionado="visualizacao" />
            </LocalizacaoUsuario>
        </>
    );
}