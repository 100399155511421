import { guidEmpty } from "utils/guid-empty";

export enum EnumTpPedidoPagamentoSituacao {
    Aprovado = 1,
    Cancelado = 2,
    Adiantado = 3
}

export class EfetuarPedidoModel {
    constructor(
        public comandaId: string | null = null,
        public salaoId: string = guidEmpty(),
        public mesaId: string = guidEmpty(),
        public produtos: EfetuarPedidoProdutoModel[] = [],
        public cliente: EfetuarPedidoClienteModel = new EfetuarPedidoClienteModel(),
        public pags: EfetuarPedidoPagamentoModel[] = [],
        public finalizar: boolean = false
    ) { }
}

export class EfetuarPedidoProdutoModel {
    constructor(
        public setorId: string = guidEmpty(),
        public salaoId: string = guidEmpty(),
        public indFin: boolean = true,
        public vendedor: string | null = null,
        public vendedorId: string | null = null,
        public produtoPai: string | null = null,
        public plu: string = '',
        public descricao: string = '',
        public observacao: string = '',
        public pesoVariavel: boolean = true,
        public codigoBarra: string = '',
        public tpProduto: number = 0,
        public status: number = 1,
        public indDesperdicio: number = 0,
        public nItem: number = 0,
        public posicaoMesa: string = '',
        public codigoReferencia: string = '',
        public quantidade: number = 0,
        public valorUnitario: number = 0,
        public valorTotal: number = 0,
        public taxaServico: number = 0,
        public valorTotalFrete: number = 0,
        public valorTotalServico: number = 0,
        public valorTotalSubItens: number = 0,
        public valorTotalDesconto: number = 0,
        public valorTotalAdicional: number = 0,
        public subItens: EfetuarPedidoProdutoModel[] = [],
        public produtoIdReferencia: string = guidEmpty(),
        public produtoGradeId: string = guidEmpty(),
        public unidadeComercial: string = '',
    ) { }
}

export class EfetuarPedidoClienteModel {
    constructor(
        public referenceId: string | null = null,
        public nomeFantasia: string = '',
        public razaoSocial: string = '',
        public email: string = '',
        public cpfCnpj: string = '',
        public ieRg: string = '',
        public dataNascimento: string = '',
        public telefone: string = '',
    ) { }
}

export enum TipoStatusPagamentoEnum {
    EmAndamento = 0,
    Confirmado = 100,
    Estornado = 200,
    Ignorado = 900
}

export class EfetuarPedidoPagamentoModel {
    constructor(
        public status: TipoStatusPagamentoEnum = TipoStatusPagamentoEnum.Confirmado,
        public pagamentoId: string = "",
        public vPag: number = 0,
        public vTroco: number = 0,
        public concluido: boolean = true,
        public mensagem: string = '',
        public nroCartao: string | null = null,
        public nomeCartao: string | null = null,
        public bandeira: string | null = null,
    ) { }
}