import { VariaveisAmbiente } from "ambiente";
import { useApiBase } from "api/base";
import { PessoaModel } from "api/cardapio/models/pessoa/pessoa-model";
import { useCallback } from "react";

export function usePutPessoa() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const putPessoa = useCallback(
        (pessoa: PessoaModel, empresaId: string) => {
            return invocarApi({
                url: `api/v4/empresa/${empresaId}/pessoas/${pessoa.id}`,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Domain': 'app.touchone.com.br',
                },
                data: JSON.stringify(pessoa),
                enviarTokenUsuario: false,
                baseURL: VariaveisAmbiente.masterApi,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        putPessoa,
    };
}