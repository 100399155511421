import { HorarioModel } from "api/cardapio/models/horario/horario-model";
import { isEmpty } from "lodash";

export const lojaAberta = (horarios: HorarioModel) => {
    let isAberto = false
    let dataAtual = new Date()
    let horaAtual = `${dataAtual
        .getHours()
        .toString()
        .padStart(2, '0')}:${dataAtual
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${dataAtual.getSeconds().toString().padStart(2, '0')}`;

    if (horarios?.empresaHorarioDias !== null) {
        let horariosDiaAtual = horarios?.empresaHorarioDias.filter(x => x.diaSemana === dataAtual.getDay())
        if (isEmpty(horariosDiaAtual)) {
            return false;
        }
        const horarioAtual = horariosDiaAtual.filter(horario => horario.inicio <= horaAtual && horario.fim > horaAtual)

        if (!isEmpty(horarioAtual))
            isAberto = true
        else
            isAberto = false
    } else {
        isAberto = false
    }

    return isAberto
}