import React from 'react';
import { useTheme, useMediaQuery, Typography, Grid, Button } from '@material-ui/core';
import { MeusPedidosDesktopIcon } from 'utils/Icons/meus-pedidos-icon-desktop';
import { MeusPedidosIcon } from 'utils';
import { useStyles } from './button-resumo-pedido-styles';
import { isEmpty } from 'lodash';
import { CardapioStorageKeys, useCardapioStorage } from 'utils/cardapio-storage';
import { MesaModel } from 'api/pedidos/models';

export interface ButtonResumoPedidoProps {
    empresaId: string;
    corLogo: string;
    colorBadge: 'primary' | 'secondary';
    quandoClicado?: () => any;
    size?: string;
    mesaId: string;
}

export const ButtonResumoPedido = ({
    empresaId,
    quandoClicado,
    corLogo,
    colorBadge,
    size,
    mesaId
}: ButtonResumoPedidoProps) => {
    const tema = useTheme();
    const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
    const classes = useStyles();
    const { getRegistro } = useCardapioStorage()
    const mesaAtual = getRegistro(CardapioStorageKeys.MesaAtual, false) as MesaModel | null
    return (
        <>
            {isMobile ? (
                <Grid style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        className={classes.containerMeusPedidos}
                        onClick={quandoClicado}
                        variant="text"
                        size="small"
                    >
                        <MeusPedidosIcon tipo='BUTTON_MEU_PEDIDO' />
                    </Button>
                </Grid>
            ) : (
                <Grid style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        className={classes.containerMeusPedidos}
                        onClick={quandoClicado}
                        variant="text"
                        size="small"
                        fullWidth
                        startIcon={<MeusPedidosDesktopIcon tipo='BUTTON_MEU_PEDIDO' />}
                    >
                        <Typography className={classes.textos}>
                            {`Meus Pedidos ${!isEmpty(mesaAtual) ? `- Mesa ${mesaAtual?.codigo}` : ''}`}
                        </Typography>
                    </Button>
                </Grid>
            )}
        </>
    );
};
