import { FetchPedidosApiWithUserJSON } from 'api/pedidos/utils/fetch-pedidos-api-with-user';
import { ApiQuery } from 'api/interfaces';
import { PedidoLocalModel } from 'api/pedidos/models/pedido-local-model';
import { EfetuarPedidoModel } from 'api/cardapio/models/pedido/efetuar-pedido';

class EnviarDadosPedido implements ApiQuery<string, PedidoLocalModel>{
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: string): any[] {
    return ['EnviarDadosPedidos', params];
  }

  async execute(model: EfetuarPedidoModel, empresaId: string) {

    return await FetchPedidosApiWithUserJSON<any>(`api/v4/empresa/${empresaId}/pedidos/efetuar-pedido`,
      {
        method: 'POST',
        body: JSON.stringify(model),
      });
  };
}

export const enviarDadosPedido = new EnviarDadosPedido();
