import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    searchIcon: {
        color: theme.palette.text.primary,
    },
    tab: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        minWidth: 150
    },
}));