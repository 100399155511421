import React, { useRef } from 'react';
import { Titulo, } from 'componentes';
import { SessaoProps } from './sessao-props';
import { useStyles } from './sessao-styles';
import { isEmpty } from 'lodash';
import { Container, Divider, Fade, Grid } from '@material-ui/core';
import { Produto } from 'modulos/cardapio/componentes/produtos/produto';
import { Skeleton } from '@material-ui/lab';
import InView from 'react-intersection-observer';
import classNames from 'classnames';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto/produto-categoria-model';
import { retiraAcentos } from 'utils/retirar-acentos';


export const Sessao = React.forwardRef<HTMLDivElement, SessaoProps>(
  (
    {
      carregando: carregandoProp,
      sessao,
      destaque,
      somenteLeitura,
      sessaoDeBusca,
      deletar,
      onProdutoClick,
      quandoForVisualizada,
      mostrarCategoria,
      className,
      produtos,
      sessoesRef,
      adicionarProdutoClick,
      sessoes
    },
    ref,
  ) => {
    const classes = useStyles();
    const divRef = useRef<HTMLDivElement>(null);

    const quandoForVisualizadaWrap = React.useCallback(
      (inView: boolean, section: ProdutoCategoriaModel | undefined) => {
        if (inView === false) {
          return;
        }
        if (quandoForVisualizada) {
          quandoForVisualizada(section);
        }
      },
      [quandoForVisualizada],
    );

    const carregando = Boolean(carregandoProp);
    return (
      <div className={classNames(classes.root, className)}>
        <div ref={divRef} className={classes.definirTamanho}>
          <Container fixed>
            {carregando && <Skeleton variant="text" height={50} />}
            {carregando === false && (
              <div className={classes.titulo}>
                <div className={classes.tituloEdicao}>
                  <Titulo>
                    {sessao?.nome}
                    {destaque && <span className={classes.destaque}>{destaque}</span>}
                  </Titulo>
                </div>
              </div>
            )}
            <Grid container spacing={3}>
              {(carregando
                ? Array.from(new Array(6))
                : (produtos && produtos.length > 0 ? produtos : sessao?.produtos) as ProdutoModel[] || ([] as ProdutoModel[])
              ).sort((a, b) => {
                const cateA = sessoes?.find(x => x.id === a?.categoriaId)?.nome
                const cateB = sessoes?.find(x => x.id === b?.categoriaId)?.nome
                if (retiraAcentos(cateA || '').toLowerCase() < retiraAcentos(cateB || '').toLowerCase()) return -1;
                if (retiraAcentos(cateA || '').toLowerCase() > retiraAcentos(cateB || '').toLowerCase()) return 1;
                return 0;
              })
                .map((produto, index, arr) => {
                  return (
                    <>
                      {(index === 0 ||
                        arr[index - 1]?.categoriaId !==
                        produto?.categoriaId) && (

                          <Grid item xs={12} ref={sessoesRef && isEmpty(sessoesRef) === false
                            ? sessoesRef[produto?.categoriaId ?? '']
                            : undefined}>
                            <InView threshold={0.4} onChange={(ev) => quandoForVisualizadaWrap(ev, sessoes?.find(x => x?.id === produto?.categoriaId))}>
                              <Divider className={classes.divider} />
                              <div className={classes.titulo}>
                                <div className={classes.tituloEdicao}>
                                  <Titulo>
                                    {produto?.categoriaDescricao}
                                  </Titulo>
                                </div>
                              </div>
                            </InView>
                          </Grid>
                        )}
                      <Fade
                        key={produto?.id || index}
                        mountOnEnter
                        unmountOnExit
                        in={true}
                        appear={true}
                      >
                        <Produto
                          key={produto?.produtoId || index}
                          produto={produto}
                          somenteLeitura={somenteLeitura}
                          carregando={carregando}
                          deletar={deletar}
                          onClick={onProdutoClick}
                        />
                      </Fade>
                    </>
                  )
                }
                )}
            </Grid>
          </Container>
        </div>
      </div>
    );
  },
);
