import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './titulo-styles';

export interface TituloProps {
  titulo?: string;
  children?: React.ReactNode;
}

export const Titulo = ({
  children,
  titulo,
  ...props
}: TituloProps & Omit<TypographyProps, 'variant'>) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" {...props} className={classNames(classes.root, props.className)}>
      <strong>
        {titulo?.toLowerCase() || typeof children === 'string'
          ? children?.toString().toLowerCase()
          : children}
      </strong>
    </Typography>
  );
};
