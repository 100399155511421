import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridRoot: {
        minHeight: '100vh',
        paddingTop: '8rem',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    olaImg: {
        objectFit: 'contain',
        width: '100%',
        margin: '0 auto',
    },
    cadastrar: {
        marginTop: '10px',
    },
    imgLogo: {
        height: '100px',
        margin: 'auto',
        display: 'flex',
        marginLeft: '.5rem',
    },
    bemvindo: {
        alignItems: 'flex-end',
        display: 'inline-flex',
    },
}));