import { MergeDeep, timeStorage } from 'utils';
import { FetchCardapioApi } from './fetch-cardapio-api';

export const FetchCardapioApiAsMasterUser = (
  path: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {

  const vToken = timeStorage.load('tokenMasterUsuario');
  const Default: RequestInit = {
    headers: {
      Authorization: `Bearer ${vToken}`,
      'Domain': 'app.touchone.com.br',
    },
  };
  return FetchCardapioApi(path, MergeDeep(Default, options));
};

export const FetchCardapioApiAsMasterUserJSON = <Tipo = any>(
  path: RequestInfo,
  options?: RequestInit,
) => {
  return FetchCardapioApiAsMasterUser(path, options).then(
    (response) => response.json() as Promise<Tipo>,
  );
};
