import { ApiQuery } from 'api/interfaces';
import { MesaModel } from 'api/pedidos/models';
import { FetchPedidosApiWithUserJSON } from '../../utils';

class ObterMesa implements ApiQuery<string, MesaModel> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(params: string): any[] {
        return ['ObterMesa', params];
    }

    async execute(empresaId: string, mesaId: string) {
        return await FetchPedidosApiWithUserJSON<MesaModel>(`api/v4/empresa/${empresaId}/mesa/${mesaId}`);
    }
}

export const obterMesa = new ObterMesa();