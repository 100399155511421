import React, { useEffect } from 'react';
import { ThemeProvider, ThemeOptions, createMuiTheme, CssBaseline } from '@material-ui/core';
import { SaurusTema } from 'tema';
import { ValidarCor } from 'utils';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { EmpresaModel } from 'api/cardapio/models/empresa/empresa-model';
import { useCardapioApi, useMasterApi } from 'modulos/cardapio/containers/cardapio/hooks';

const tinycolor = require('tinycolor2')


interface EmpresaTemaContextType {
  tema?: TemaModel;
  isSuccess?: boolean;
  empresaLocalizada?: boolean;
  carregandoEmpresa?: boolean;
  atualizarTema?: (tema?: TemaModel) => any;
  atualizarEmpresa?: (id?: any) => any;
  empresa?: EmpresaModel;
}

const EmpresaTemaContext = React.createContext<EmpresaTemaContextType>({
  tema: undefined,
  carregandoEmpresa: undefined,
  atualizarTema: undefined,
  empresa: undefined,
});

interface EmpresaDadosContextType {
  empresaDados?: EmpresaModel;
  isSuccess?: boolean;
  carregandoEmpresa?: boolean;
  atualizarEmpresaDados?: (id?: any) => any;
}

const EmpresaDadosContext = React.createContext<EmpresaDadosContextType>({
  empresaDados: undefined,
  isSuccess: undefined,
  carregandoEmpresa: undefined,
  atualizarEmpresaDados: undefined,
});

export const useEmpresaTema = () => {
  return React.useContext(EmpresaTemaContext);
};

export const useEmpresaDados = () => {
  return React.useContext(EmpresaDadosContext)
}
export interface EmpresaTemaProviderProps {
  children: React.ReactNode;
}

export const EmpresaTema = ({ children }: EmpresaTemaProviderProps) => {
  const [tema, setarTema] = React.useState(SaurusTema);
  const [empresaId] = window.location.pathname.split('/').filter(x => x)
  const { dados: apiMasterDados } = useMasterApi(empresaId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { dados } = useCardapioApi(empresaId)

  const {
    primaryContrast,
    primaryDark,
    primaryLight,
    primaryMain,
    secondaryContrast,
    secondaryDark,
    secondaryLight,
    secondaryMain,
    textPrimary,
    textSecondary,
    type,
  } = apiMasterDados.data?.configuracoes || {};

  const obterContrastColor = React.useCallback((color) => {
    return tinycolor(color).getBrightness() > 200 ? tinycolor(color).darken(50).toString() : '#fff'
  }, [])

  const obterTipo = React.useCallback((type?: string | number) => {
    if (typeof type === 'number') {
      return type === 0 ? 'light' : 'dark';
    }

    return type === 'dark' || type === '1' ? 'dark' : 'light';
  }, []);

  const saurusTema = React.useMemo(() => {
    return {
      primaryMain: ValidarCor(primaryMain)
        ? (primaryMain as string)
        : SaurusTema.palette.primary.main,
      primaryDark: ValidarCor(primaryDark)
        ? (primaryDark as string)
        : SaurusTema.palette.primary.dark,
      primaryLight: ValidarCor(primaryLight)
        ? (primaryLight as string)
        : SaurusTema.palette.primary.light,
      primaryContrast: ValidarCor(primaryContrast)
        ? (primaryContrast as string)
        : SaurusTema.palette.primary.contrastText,
      secondaryMain: ValidarCor(secondaryMain)
        ? (secondaryMain as string)
        : SaurusTema.palette.secondary.main,
      secondaryDark: ValidarCor(secondaryDark)
        ? (secondaryDark as string)
        : SaurusTema.palette.secondary.dark,
      secondaryLight: ValidarCor(secondaryLight)
        ? (secondaryLight as string)
        : SaurusTema.palette.secondary.light,
      secondaryContrast: ValidarCor(secondaryContrast)
        ? (secondaryContrast as string)
        : SaurusTema.palette.secondary.contrastText,
      textPrimary: ValidarCor(textPrimary)
        ? (textPrimary as string)
        : SaurusTema.palette.text.primary,
      textSecondary: ValidarCor(textSecondary)
        ? (textSecondary as string)
        : SaurusTema.palette.text.secondary,
      type: type || 0,
    } as TemaModel;
  }, [
    primaryContrast,
    primaryDark,
    primaryLight,
    primaryMain,
    secondaryContrast,
    secondaryDark,
    secondaryLight,
    secondaryMain,
    textPrimary,
    textSecondary,
    type,
  ]);


  const atualizarTema = React.useCallback(
    (input?: TemaModel) => {
      const tipo = obterTipo(input?.type);
      const proximoTema = createMuiTheme({
        palette: {
          primary: {
            main: ValidarCor(input?.primaryMain)
              ? input?.primaryMain
              : SaurusTema.palette.primary.main,
            light: ValidarCor(input?.primaryLight)
              ? input?.primaryLight
              : SaurusTema.palette.primary.light,
            dark: ValidarCor(input?.primaryDark)
              ? input?.primaryDark
              : SaurusTema.palette.primary.dark,
            contrastText: ValidarCor(input?.primaryMain)
              ? obterContrastColor(input?.primaryMain)
              : obterContrastColor(input?.primaryMain),
          },
          secondary: {
            main: ValidarCor(input?.secondaryMain)
              ? input?.secondaryMain
              : SaurusTema.palette.secondary.main,
            light: ValidarCor(input?.secondaryLight)
              ? input?.secondaryLight
              : SaurusTema.palette.secondary.light,
            dark: ValidarCor(input?.secondaryDark)
              ? input?.secondaryDark
              : SaurusTema.palette.secondary.dark,
            contrastText: ValidarCor(input?.secondaryMain)
              ? obterContrastColor(input?.secondaryMain)
              : obterContrastColor(input?.secondaryMain),
          },
          type: tipo,
          background: {
            default: tipo === 'light' ? '#fafafa' : '#121212',
          },
        },
        overrides: SaurusTema.overrides,
        props: SaurusTema.props,
        shape: SaurusTema.shape
      } as ThemeOptions);
      setarTema(proximoTema);
    },
    [obterContrastColor, obterTipo],
  );

  useEffect(() => {
    atualizarTema(saurusTema);
  }, [atualizarTema, saurusTema]);

  return (
    <ThemeProvider theme={tema}>
      <CssBaseline>
        <EmpresaTemaContext.Provider
          value={{
            carregandoEmpresa: apiMasterDados.isLoading || apiMasterDados.isFetching,
            atualizarTema,
            tema: apiMasterDados.data?.configuracoes,
            empresa: apiMasterDados.data?.empresa
          }}
        >
          {children}
        </EmpresaTemaContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
};
