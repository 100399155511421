import React, { useState, useLayoutEffect, useRef, useEffect, memo } from 'react';
import { Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './cardapio-categoria-tabs-styles';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto-categoria/produto-categoria-model';
import { retiraAcentos } from 'utils/retirar-acentos';

export interface CardapioCategoriaTabsProps {
  categorias?: ProdutoCategoriaModel[];
  categoriaSelecionada?: string;
  categoriaClick: (categoria: string) => void;
}

export const CardapioCategoriaTabs = ({
  categorias,
  categoriaSelecionada,
  categoriaClick,
}: CardapioCategoriaTabsProps) => {
  const classes = useStyles();
  const catOrdenadas = (categorias || []).sort(function (a, b) {
    if (retiraAcentos(a.nome || '').toLowerCase() < retiraAcentos(b.nome || '').toLowerCase()) return -1;
    if (retiraAcentos(a.nome || '').toLowerCase() > retiraAcentos(b.nome || '').toLowerCase()) return 1;
    return 0;
  });
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  //SESSAO PARA SETAR O WIDTH CERTO DO NAVBAR
  const [width] = useWindowSize();
  const [widthEachItem, setWidthEachItem] = useState<number>(0);
  const [widthNav, setWidhtNav] = useState<number>(0);
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [widthMenu, setWidhtMenu] = useState<number>(0);
  /* eslint-enable  @typescript-eslint/no-unused-vars */
  const itemtab = useRef(null);
  const tabs = useRef(null);
  const appBar = useRef(null);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH
    const newWidth = width;
    if (newWidth === width)
      setWidthEachItem(itemtab.current ? (itemtab.current as any).offsetWidth : 0);
  }, [width]);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH
    const newWidth = width;
    if (newWidth === width) setWidhtNav(tabs.current ? (tabs.current as any).offsetWidth : 0);
  }, [width]);
  useEffect(() => {
    //HACK PRA ELE AIR QNDO ALTERAR O WITDTH

    const newWidth = width;
    if (newWidth === width) setWidhtMenu(appBar.current ? (appBar.current as any).offsetWidth : 0);
  }, [width]);

  return (
    <>
      <Tabs
        ref={tabs}
        indicatorColor="primary"
        variant={catOrdenadas.length * widthEachItem < widthNav ? 'standard' : 'scrollable'}
        centered={catOrdenadas.length * widthEachItem < widthNav ? true : false}
        scrollButtons={isMobile ? 'off' : 'on'}
        textColor="primary"
        value={categoriaSelecionada || false}
      >
        {catOrdenadas?.filter(x => x.id)?.map((categoria, index) => {
          return categoria.ativo ? (
            <Tab
              ref={itemtab}
              key={categoria.id || index}
              className={classes.tab}
              label={categoria?.nome}
              value={categoria?.id}
              onClick={() => {
                if (categoriaClick) {
                  categoriaClick(categoria.id ?? '');
                }
              }}
            />
          ) : null
        }
        )}
      </Tabs>
    </>
  );
};
export const CategoriaTabs = memo(CardapioCategoriaTabs);
