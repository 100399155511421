import React, { memo } from 'react';
import { ProdutoProps } from './produto-props';
import { useStyles } from './produto-styles';
import { Skeleton } from '@material-ui/lab';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  useTheme,
  useMediaQuery,
  Fade,
  Grid,
} from '@material-ui/core';
import classNames from 'classnames';
import { useMovProd } from 'hooks/mov-prod';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';

const ProdutoComp = (props: ProdutoProps) => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('sm'));
  const { carregando: carregandoProp, produto, onClick } = props;
  const carregando = Boolean(carregandoProp);
  const existeImagem = Boolean(produto?.imagemUrl);
  const { insertCentralizadoProduto } = useMovProd()

  const onClickWrap = React.useCallback(() => {
    if (onClick) {
      const prod = insertCentralizadoProduto(
        produto ?? new ProdutoModel(),
        1,
        produto?.vPreco ?? 0,
        0,
        0,
        0,
        ''
      )
      onClick(prod);
    }
  }, [onClick, insertCentralizadoProduto, produto]);

  let imagem = ''
  if (produto?.imagemUrl?.length! > 0) {
    try {
      imagem = atob(produto?.imagemUrl!)
    } catch {
      imagem = produto?.imagemUrl!
    }
  }
  const classes = useStyles({ ...props, imagem });

  const Conteudo = React.useMemo(
    () => ({ children }: any) => (
      <CardActionArea onClick={onClickWrap} className={classes.conteudo}>
        {existeImagem && (
          <img src={imagem} loading="lazy" className={classes.img} alt={produto?.nome} />
        )}
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </CardActionArea>
    ),
    [onClickWrap, classes.conteudo, classes.img, classes.cardContent, existeImagem, imagem, produto?.nome],
  );

  if (carregando) {
    return (
      <Grid item xs={12} md={6}>
        <Skeleton variant="rect" height="185px" width="100%" />
      </Grid>
    );
  }

  return (
    <Fade
      mountOnEnter
      unmountOnExit
      in={true}
      timeout={500}
      appear={true}
    >
      <Grid item xs={12} md={6}>
        <div className={classes.root}>
          <Card className={classes.card} elevation={0}>
            <Conteudo>
              <div>
                <Typography
                  variant='body1'
                  gutterBottom
                >
                  {produto?.nome}
                </Typography>
                <Typography
                  variant={isMobile ? 'caption' : 'subtitle2'}
                  color="textSecondary"
                  className={classNames(classes.text, classes.descricao)}
                  gutterBottom
                >
                  {/*descricao do produto*/}
                  {produto?.infAdic}
                </Typography>
              </div>
              <Typography
                color="textPrimary"
                className={classes.text}
                align="right"
                variant={isMobile ? 'h5' : 'h5'}
              >
                <strong className={classes.valor}>
                  {produto && produto?.subItens.length > 0 && ((produto?.vPreco || 0) === 0) ? (
                    <>
                      SELECIONE
                    </>
                  ) : (
                    <>
                      {`R$ ${(+`${produto?.vPreco || 0}`)?.toFixed(2).replace('.', ',')}`}&nbsp;
                      <small className={classes.medida}>{produto?.medida}</small>
                    </>
                  )}
                </strong>
              </Typography>
            </Conteudo>
          </Card>
        </div>
      </Grid>
    </Fade>
  );
};

export const Produto = memo(ProdutoComp)