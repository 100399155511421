import { isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { CardapioHeaderProps } from './cardapio-header-props';


export const useCardapioHeader = (props: CardapioHeaderProps) => {
  const dados = props.cardapioDados

  const [tema, setTema] = useState(dados.configuracoes);
  const [backupTema, setBackupTema] = useState(dados.configuracoes)

  useEffect(() => {
    setTema(dados.configuracoes)
  }, [dados.configuracoes])

  const { cardapioDados } = props
  const horarios = cardapioDados.horarioFuncionamento;

  const [editandoNome, setarEditandoNome] = useState(false);
  const [editandoInformacao, setarEditandoInformacao] = useState(false);
  const [redefinir, setRedefinir] = useState(false);
  const [editandoImagem, setEditandoImagem] = useState(false)

  const mostrarConfirmacao = useMemo(
    () => props.atualizando === false &&
      (
        editandoNome === true ||
        editandoInformacao === true ||
        redefinir === true ||
        isEqual(tema, dados.configuracoes) === false
      ),
    [props.atualizando, editandoNome, editandoInformacao, redefinir, tema, dados.configuracoes],
  );

  const carregando = isEmpty(tema) || isNil(tema);
  const somenteLeitura = (props.somenteLeitura === false && mostrarConfirmacao === false) === false;
  const mostrarDados = props.atualizando === false && carregando === false;

  const quandoOsDadosMudarem = useCallback(async ({ capa, logo }: any) => {
    setRedefinir(true);
    setTema((prev: any) => ({
      ...prev,
      capa: capa ? capa : prev.capa,
      logo: logo ? logo : prev.logo,
    }));
  }, []);

  useEffect(() => {
    setBackupTema(dados.configuracoes)
  }, [dados.configuracoes])

  const quandoEdicaoNomeForIniciada = useCallback(() => {
    setarEditandoNome(true);
    setTema((prev: any) => ({
      ...prev,
    }));
  }, []);

  const quandoEdicaoInformacoesIniciada = useCallback(() => {
    setRedefinir(true);
    setarEditandoInformacao(true);
    setTema((prev: any) => ({
      ...prev,
    }));
  }, []);

  const quandoNomeEmpresaForAlterada = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTema((prev: any) => ({
      ...prev,
      nome: event.target.value,
    }));
  }, []);

  const quandoInformacoesForAlterada = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTema((prev: any) => ({
      ...prev,
      informacaoAdicional: event.target.value,
    }));
  }, []);

  const quandoRedefinirForAcionado = useCallback(() => {
    if (props.redefinir) {
      props.setValueProps((prev: any) => ({
        ...prev!,
        informacaoAdicional: tema!.informacaoAdicional! = '',
        capa: tema!.capa = '',
        logo: tema!.logo = '',
      }));
    }
  }, [tema, props]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      quandoRedefinirForAcionado();
    }, 300);

    return () => clearTimeout(timeOutId);
  }, [quandoRedefinirForAcionado])

  const quandoCancelado = useCallback(() => {
    setTema(backupTema);
    setarEditandoInformacao(false);
    setarEditandoNome(false);
    setRedefinir(false);
    setEditandoImagem(false);
  }, [backupTema]);

  const quandoAplicado = useCallback(async () => {
    setRedefinir(false);
    setarEditandoInformacao(false);
    setarEditandoNome(false);
  }, []);

  return {
    operacoes: {
      quandoOsDadosMudarem,
      quandoEdicaoNomeForIniciada,
      quandoEdicaoInformacoesIniciada,
      quandoInformacoesForAlterada,
      quandoNomeEmpresaForAlterada,
      quandoCancelado,
      quandoAplicado,
      // logout,
      // navegarParaEdicao,
    },
    dados: {
      cardapioDados: {
        ...cardapioDados,
        configuracoes: tema
      },
      carregando,
      mostrarConfirmacao,
      editandoNome,
      editandoInformacao,
      editandoImagem,
      somenteLeitura,
      mostrarDados,
      horarios,
    },
  };
};
