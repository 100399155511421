import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { obterComanda, obterPedido } from 'api/pedidos/queries';
import { CardapioStorageKeys, useCardapioStorage } from 'utils/cardapio-storage';
import { EfetuarPedidoClienteModel, EfetuarPedidoModel, EfetuarPedidoPagamentoModel, EfetuarPedidoProdutoModel, TipoStatusPagamentoEnum } from 'api/cardapio/models/pedido/efetuar-pedido';
import { enviarDadosPedido } from 'api/pedidos/mutations';
import { useEsvaziarSacola } from 'storage';
import { PedidoModel } from 'api/cardapio/models/pedido/pedido';
import { isEmpty } from 'lodash';
import { ComandaModel, MesaModel } from 'api/pedidos/models';
import { PessoaModel } from 'api/cardapio/models/pessoa/pessoa-model';
import { guidEmpty } from 'utils/guid-empty';
import { useGetLocalizacaoUsuario } from 'utils';
import { DistanciaModel } from 'api/master/models/distancia/distancia-model';
import { postDistancia } from 'api/master/mutations/distancia/enviar-distancia';
import { usePostMovRegistrar } from 'api/cardapio/queries/mov-pag/post-registrar';
import { MovRegistrarModel } from 'api/cardapio/models/mov-pag/mov-registrar';
import { FinalizadoraModel } from 'api/cardapio/models/finalizadora/finalizadora-model';
import { MovRegistrarResponseModel } from 'api/cardapio/models/mov-pag/mov-registrar-response';
import { stringNumeros } from 'utils/string-numeros';
import { CardapioDadosProps } from 'modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props';
import { roundTo } from 'utils/round-to';
import { calcPercent } from 'utils/calc-percent';
import { useGetPessoaById } from 'api/cardapio/queries/pessoa/get-pessoa-by-id';
import { EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { useNotificacoes } from 'componentes/notificacoes';

interface CriarPedidoProps {
    isPedido?: boolean;
    finalizar?: boolean;
    transacao?: EfetuarPedidoPagamentoModel | null
    comandaCod?: string | null;
}

interface PedidosContextValue {
    pedidoAtual: PedidoModel | null;
    carregandoGetPedido: boolean;
    carregandoCriarPedido: boolean;
    carregandoEfetuarNovaVenda: boolean;
    carregandoRegistrarPix: boolean;
    carregandoHandlePedidoPix: boolean;
    codComanda: string | null;
    cliente: PessoaModel | null;
    getPedido: (comandaId?: string) => Promise<void>;
    criarPedido: (model: CriarPedidoProps) => Promise<void>;
    setCliente: (value: PessoaModel | null) => void;
    getCliente: () => null | PessoaModel;
    pegarComanda: (codigo: string) => Promise<ComandaModel | null | undefined>;
    setTransacao: (pagamento: EfetuarPedidoPagamentoModel | null) => void;
    handleRegistroPix: () => Promise<void>;
    dadosPix: MovRegistrarResponseModel | null;
    setDadosPix: (value: MovRegistrarResponseModel) => void;
    setCodComanda: (value: string | null) => void;
    handlePedidoPix: () => Promise<void>;
    handlePedido: () => Promise<void>;
    handlePedidoComanda: (codigo: string) => Promise<void>;
    cancelarPedido: () => void;
    handleCheckout: (transacao?: any) => Promise<void>;
}

const PedidosContext = React.createContext<PedidosContextValue>({
    pedidoAtual: null,
    cliente: null,
    carregandoGetPedido: false,
    carregandoCriarPedido: false,
    carregandoEfetuarNovaVenda: false,
    carregandoRegistrarPix: false,
    carregandoHandlePedidoPix: false,
    codComanda: null,
    getPedido: (comandaId?: string) => new Promise(() => true),
    criarPedido: (model: CriarPedidoProps) => new Promise(() => true),
    setCliente: (value: PessoaModel | null) => { },
    getCliente: (): null | PessoaModel => null,
    pegarComanda: (codigo: string) => new Promise(() => null),
    setTransacao: (pagamento: EfetuarPedidoPagamentoModel | null) => { },
    handleRegistroPix: () => new Promise(() => { }),
    dadosPix: null,
    setDadosPix: (value: MovRegistrarResponseModel) => { },
    setCodComanda: (value: string | null) => { },
    handlePedidoPix: () => new Promise(() => null),
    handlePedido: () => new Promise(() => null),
    handlePedidoComanda: (codigo: string) => new Promise(() => null),
    cancelarPedido: () => null,
    handleCheckout: (transacao?: any) => new Promise(() => null),
});

export const usePedidos = () => useContext(PedidosContext);

export interface PedidosProviderProps {
    children: React.ReactNode;
}

export enum EnumStatusProdutoPedido {
    DISPONIVEL = 1,
    INDISPONIVEL = 2,
    DESISTENCIA = 3,
    TROCADO = 4,
}

export const PedidosProvider = ({ children }: PedidosProviderProps) => {

    //HOOKS
    const [esvaziarSacola] = useEsvaziarSacola();
    const { showErrorMessage } = useNotificacoes();

    //STORAGE
    const { setRegistro, getRegistro } = useCardapioStorage()

    //STATES E REFS
    const { empresaId } = useParams<{ empresaId: string }>();
    const [pedidoAtual, setPedidoAtual] = useState<PedidoModel | null>(null);
    const [carregandoGetPedido, setCarregandoGetPedido] = useState<boolean>(false)
    const [carregandoCriarPedido, setCarregandoCriarPedido] = useState<boolean>(false)
    const [cliente, setCliente] = useState<PessoaModel | null>(null)
    const [codComanda, setCodComanda] = useState<string | null>(null)
    const [, setTransacao] = useState<EfetuarPedidoPagamentoModel | null>(null)
    const [dadosPix, setDadosPix] = useState<MovRegistrarResponseModel | null>(null)
    const [carregandoDistancia, setCarregandoDistancia] = useState<boolean>(false)

    //CHAMADAS API
    const { obterLocalizacao } = useGetLocalizacaoUsuario();
    const { postMovRegistrar, carregando: carregandoMovRegistrar } = usePostMovRegistrar()
    const { getPessoaById, carregando: carregandoPessoaById } = useGetPessoaById()

    // AUX
    const dadosGerais = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps;

    // ID MESA NA URL
    let path = window.location.pathname.split('/').filter(x => x);
    const mesaId = path[1];

    const carregandoEfetuarNovaVenda = [
        carregandoPessoaById,
    ].includes(true)

    const getValueByConfig = useCallback((cod: number) => {
        const configEmp = dadosGerais.configuracoesEmpresa
        if (isEmpty(configEmp)) {
            return undefined
        }
        const config = configEmp.find(config => config.codigo === cod)

        return config
    }, [dadosGerais.configuracoesEmpresa])

    const getClientePadraoWrapper = useCallback(async () => {
        const clientePadraoId = getValueByConfig(326)
        const clientePadraoStorage = getRegistro(CardapioStorageKeys.ClientePadrao) as PessoaModel

        if (!isEmpty(clientePadraoStorage) && clientePadraoStorage.id === (clientePadraoId?.valor ?? '')) {
            return clientePadraoStorage
        }
        debugger

        const res = await getPessoaById(clientePadraoId?.valor ?? '', empresaId)
        if (res.erro) throw res.erro

        setRegistro(CardapioStorageKeys.ClientePadrao, res.resultado?.data)

        return res.resultado?.data as PessoaModel
    }, [empresaId, getPessoaById, getRegistro, getValueByConfig, setRegistro])

    const carregandoRegistrarPix = [carregandoMovRegistrar, carregandoDistancia].includes(true)

    const carregandoHandlePedidoPix = [carregandoCriarPedido].includes(true)


    const pegarComanda = useCallback(async (codigo: string) => {
        const empresa = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps
        if (codigo.length > 3) {

            codigo = codigo.slice(codigo.length - 3, codigo.length)

            if (codigo[0] === '0' && codigo[1] === '0') {
                codigo = codigo.slice(codigo.length - 1, codigo.length)
            }
            else if (codigo[0] === '0') {
                codigo = codigo.slice(codigo.length - 2, codigo.length)
            }
        } else {
            if (codigo[0] === '0' && codigo[1] === '0') {
                codigo = codigo.slice(codigo.length - 1, codigo.length)
            }
            else if (codigo[0] === '0') {
                codigo = codigo.slice(codigo.length - 2, codigo.length)
            }
        }

        const ret = await obterComanda.execute(empresa.empresa.id, codigo);
        if (isEmpty(ret)) {
            throw new Error(`Verifique se é um código valido e tente novamente`);
        }
        const comandas = (ret ?? []) as ComandaModel[]

        return comandas.find(x => x.status.codigo === 1) ?? null

    }, [getRegistro])

    const getPedido = useCallback(async (codComanda?: string) => {
        setCarregandoGetPedido(true)
        setPedidoAtual(null)
        try {
            let res = null
            let comanda: ComandaModel | null | undefined = null
            if (codComanda) {
                comanda = await pegarComanda(codComanda)

                res = await obterPedido.execute({ empresaId: empresaId, comandaId: comanda?.id });
            } else {
                res = await obterPedido.execute({ empresaId: empresaId, mesaId: mesaId });
            }
            setPedidoAtual(res);
        }
        catch (err: any) {
            showErrorMessage(err.message);
        }
        finally {
            setCarregandoGetPedido(false)
        }
    }, [empresaId, mesaId, pegarComanda, showErrorMessage])

    const postDistanciaWrapper = useCallback(async (model: DistanciaModel) => {
        const res = await postDistancia.execute(model, empresaId);

        return res.dentro
    }, [empresaId])

    const pedidoProdutosWrapper = useCallback((
        produtosMovSimples: MovSimplesProdutoModel[],
        salaoId: string | null = null
    ) => {
        const taxaRecomendada = getValueByConfig(800)

        const produtoSerializados = produtosMovSimples.map((prod) => {

            return {
                setorEmpresaId: null,
                plu: prod.cProd,
                descricao: prod.xProd,
                observacao: prod.observacao,
                pesoVariavel: prod.balanca,
                codigoBarra: prod.cEan,
                status: prod.ativo
                    ? EnumStatusProdutoPedido.DISPONIVEL
                    : EnumStatusProdutoPedido.DESISTENCIA,
                nItem: prod.nSeq,
                posicaoMesa: '',
                codigoReferencia: prod.id,
                quantidade: prod.qCom,
                valorTotal: Math.round((prod.vFinal + Number.EPSILON) * 100) / 100,
                valorTotalDesconto: roundTo(prod.vDescUsuario),
                valorUnitario: roundTo(prod.vUnCom),
                valorTotalAdicional: 0,
                vendedor: prod.vendedorNome,
                vendedorId: prod.vendedorId,
                setorId: prod.setorId,
                salaoId: salaoId,
                indFin: prod.tpProduto === EnumTpProduto.Combo ? false : prod.indFin,
                groupId: prod.idGroup,
                adicionalId: prod.idAdicional,
                subItens: pedidoProdutosWrapper(prod.prodSubItem, salaoId),
                produtoPai: prod.produtoPaiId,
                tpProduto: prod.tpProduto,
                taxaServico: prod.cobraTaxaServico && prod.indFin ? Number(taxaRecomendada?.valor ?? 0) : 0,
                valorTotalServico: prod.cobraTaxaServico && prod.indFin ? roundTo(calcPercent(prod?.vFinal ?? 0, Number(taxaRecomendada?.valor ?? 0))) : 0,
                produtoIdReferencia: prod.produtoId,
                quantidadeMax: prod.qCom,
                produtoGradeId: prod.produtoGradeId,
                unidadeComercial: prod.uCom,
            } as unknown;
        }) as EfetuarPedidoProdutoModel[];

        return produtoSerializados;
    }, [getValueByConfig]);

    const criarPedido = useCallback(async ({
        finalizar = false,
        transacao = null,
        comandaCod = null
    }: CriarPedidoProps) => {
        setCarregandoCriarPedido(true)
        try {
            let comanda: ComandaModel | null | undefined = null
            const produtos = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];
            const mesa = getRegistro(CardapioStorageKeys.MesaAtual, false) as MesaModel | null;
            const pedidos = getRegistro(CardapioStorageKeys.Pedidos, false) as any[]


            let request: EfetuarPedidoModel = new EfetuarPedidoModel();

            if (comandaCod || codComanda) {
                comanda = await pegarComanda(comandaCod! ?? codComanda)
            }
            if (mesa) {
                request.salaoId = mesa.salaoId;
                request.mesaId = mesa.id;
            }

            if (comanda) {
                request.comandaId = comanda.id
            }

            request.cliente = new EfetuarPedidoClienteModel();

            if (transacao) {
                request.pags.push(transacao)
            }

            if (cliente && !isEmpty(cliente)) {
                request.cliente.razaoSocial = cliente.nome
                request.cliente.nomeFantasia = cliente.nome
                request.cliente.cpfCnpj = cliente.cpfcnpj
                request.cliente.referenceId = !cliente.id ? null : cliente.id
                request.cliente.telefone = cliente.contatos.find(x => x.tipo === 0)?.valor ?? ''
                request.cliente.email = cliente.contatos.find(x => x.tipo === 1)?.valor ?? ''

            } else {
                const clientePadrao = await getClientePadraoWrapper()
                request.cliente.razaoSocial = clientePadrao.nome
                request.cliente.nomeFantasia = clientePadrao.nome
                request.cliente.cpfCnpj = clientePadrao.cpfcnpj
                request.cliente.referenceId = !clientePadrao.id ? null : clientePadrao.id
                request.cliente.telefone = clientePadrao.contatos.find(x => x.tipo === 0)?.valor ?? ''
                request.cliente.email = clientePadrao.contatos.find(x => x.tipo === 1)?.valor ?? ''
            }

            request.finalizar = finalizar

            const produtosReq: EfetuarPedidoProdutoModel[] = pedidoProdutosWrapper(produtos, mesa?.salaoId)

            request.produtos = produtosReq;

            const localizacao = await obterLocalizacao();

            const resposta = await postDistanciaWrapper(localizacao);

            if (!resposta) {
                throw new Error('Você saiu do raio definido pelo dono da loja. Volte para a loja ou cancele o pedido.')
            }

            const resPedido = await enviarDadosPedido.execute(request, empresaId);

            setRegistro(
                CardapioStorageKeys.Pedidos,
                [
                    ...(!isEmpty(pedidos) ? pedidos : []),
                    resPedido
                ],
                false
            )

            esvaziarSacola({ empresaId: empresaId });
            setTransacao(null)
            setCodComanda(null)
            setCliente(null)
            setDadosPix(null)
            await getPedido();
        }
        finally {
            setCarregandoCriarPedido(false)
        }
    }, [cliente, codComanda, empresaId, esvaziarSacola, getClientePadraoWrapper, getPedido, getRegistro, obterLocalizacao, pedidoProdutosWrapper, pegarComanda, postDistanciaWrapper, setRegistro]);

    const getCliente = useCallback(() => cliente, [cliente])

    const handleRegistroPix = useCallback(async (): Promise<void> => {
        const registrar: MovRegistrarModel = new MovRegistrarModel()
        const produtos = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];

        setCarregandoDistancia(true)
        const localizacao = await obterLocalizacao();

        const resposta = await postDistanciaWrapper(localizacao);

        if (!resposta) {
            setCarregandoDistancia(false)
            throw new Error('Você saiu do raio definido pelo dono da loja. Volte para a loja ou cancele o pedido.')
        }
        setCarregandoDistancia(false)

        const finalizadoras = dadosGerais.finalizadoras as FinalizadoraModel[]
        const pixFinalizador = finalizadoras.find(x => x.credenciais && x.credenciais.includes("\"tipo\":1"))

        if (finalizadoras.length === 0 || isEmpty(finalizadoras)) {
            throw new Error('Forma de pagamento não foi configurada corretamente ou não implementada.')
        }

        registrar.finalizadoraId = pixFinalizador?.id ?? guidEmpty()
        registrar.valor = produtos.reduce((a, b) => a + b.vFinal, 0)

        registrar.pagador = {
            cpfCnpj: cliente?.cpfcnpj ?? '',
            fone: stringNumeros(cliente?.contatos.find(x => x.tipo === 0)?.valor ?? ''),
            email: cliente?.contatos.find(x => x.tipo === 1)?.valor ?? '',
            nome: cliente?.nome ?? 'Consumidor'
        }

        const res = await postMovRegistrar(registrar, dadosGerais.empresa?.id)
        if (res.erro) throw res.erro

        setDadosPix(res.resultado?.data)
    }, [cliente?.contatos, cliente?.cpfcnpj, cliente?.nome, dadosGerais.empresa?.id, dadosGerais.finalizadoras, getRegistro, obterLocalizacao, postDistanciaWrapper, postMovRegistrar])

    const handlePedidoPix = useCallback(async () => {
        const sacola = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[];

        const finalizadoras = dadosGerais.finalizadoras as FinalizadoraModel[]
        const pixFinalizador = finalizadoras.find(x => x.credenciais && x.credenciais.includes("\"tipo\":1"))
        await criarPedido({
            finalizar: true,
            transacao: {
                pagamentoId: pixFinalizador?.id ?? guidEmpty(),
                status: TipoStatusPagamentoEnum.Confirmado,
                vPag: sacola.reduce((a, b) => a + b.vFinal, 0),
                vTroco: 0,
                mensagem: 'PIX',
            } as EfetuarPedidoPagamentoModel
        })
    }, [criarPedido, dadosGerais.finalizadoras, getRegistro])

    const handlePedido = useCallback(async () => {
        await criarPedido({
            finalizar: false
        })
    }, [criarPedido])

    const handlePedidoComanda = useCallback(async (comanda: string) => {
        await criarPedido({
            finalizar: false,
            comandaCod: comanda
        })
    }, [criarPedido])

    const cancelarPedido = useCallback(() => {
        esvaziarSacola({ empresaId: empresaId });
        setTransacao(null)
        setCodComanda(null)
        setCliente(null)
        setDadosPix(null)
    }, [empresaId, esvaziarSacola])

    const handleCheckout = useCallback(async (transacao?: any) => {
        await criarPedido({ isPedido: false, finalizar: true, transacao })
    }, [criarPedido])

    return (
        <PedidosContext.Provider
            value={{
                // VARIÁVEIS
                pedidoAtual,
                carregandoCriarPedido,
                carregandoGetPedido,
                carregandoEfetuarNovaVenda,
                carregandoRegistrarPix,
                carregandoHandlePedidoPix,
                dadosPix,
                cliente,
                codComanda,

                // FUNÇÕES
                criarPedido,
                getPedido,
                setCliente,
                getCliente,
                pegarComanda,
                setTransacao,
                handleRegistroPix,
                setDadosPix,
                setCodComanda,
                handlePedidoPix,
                handlePedido,
                cancelarPedido,
                handleCheckout,
                handlePedidoComanda
            }}>
            {children}
        </PedidosContext.Provider>
    );
};
