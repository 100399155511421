import React from 'react';
import { EmptyState } from 'componentes';
import { isEmpty } from 'lodash';
import { SessoesProps } from './sessoes-props';
import { Sessao } from 'modulos/cardapio/componentes/produtos/sessao';
import { useStyles } from './sessoes-styles';
import { SessoesInstancia } from './sessoes-instancia';
import { useSessoes } from './use-sessoes';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto/produto-categoria-model';
import { retiraAcentos } from 'utils/retirar-acentos';

export const Sessoes = React.forwardRef<SessoesInstancia, SessoesProps>((props, ref) => {
  const classes = useStyles();
  const { dados, operacoes } = useSessoes(props);

  const {
    className,
    somenteLeitura,
    sessoes,
    carregando,
    quandoSessaoForVisualizada,
    deletar,
    adicionarProdutoClick,
    onProdutoClick,
  } = props;

  React.useImperativeHandle(ref, () => ({
    pesquisarSessoes: operacoes.pesquisarSessoes,
    rolarParaSessao: operacoes.rolarParaSessao,
    sessaoStateChanged: sessaoStateChanged,
  }));

  const catOrdenadas = (sessoes || []).sort(function (a, b) {
    if (retiraAcentos(a.nome || '').toLowerCase() < retiraAcentos(b.nome || '').toLowerCase()) return -1;
    if (retiraAcentos(a.nome || '').toLowerCase() > retiraAcentos(b.nome || '').toLowerCase()) return 1;
    return 0;
  });

  const existeSessoes = isEmpty(sessoes) === false;
  const mostrarEmptyState = existeSessoes === false && Boolean(carregando) === false;
  const [filtroSessoes, setFiltroSessoes] = React.useState(Array.from(new Array(1)));

  React.useEffect(() => {
    let sessoesArr = Array.isArray(sessoes)
      ? somenteLeitura
        ? catOrdenadas.filter((x) => x.ativo === true).filter((x) => x.produtos?.length! > 0)
        : catOrdenadas
      : [];
    setFiltroSessoes(sessoesArr);

  }, [catOrdenadas, sessoes, somenteLeitura]);

  const sessaoStateChanged = (categoriaId?: string) => {
    let refArray = filtroSessoes;
    let item = refArray.find((x) => x.categoriaId === categoriaId) || { ativo: false };
    item.ativo = !item.ativo;

    setFiltroSessoes(refArray);
  };
  if (filtroSessoes !== undefined && filtroSessoes.length > 1) {
  }
  const produtos = (filtroSessoes as ProdutoCategoriaModel[])?.map(x => x?.produtos).reduce((resultado, valorAtual) => {
    return resultado.concat(valorAtual);
  }, [])

  return (
    <div className={className}>
      {mostrarEmptyState && somenteLeitura ? (
        <React.Fragment>
          <EmptyState
            className={classes.emptyState}
            titulo={
              somenteLeitura ? (
                <div>Nenhum produto neste Estabelecimento</div>
              ) : (
                <div>Nenhum produto cadastrado no seu Estabelecimento</div>
              )
            }
            mensagem={
              somenteLeitura ? (
                <div>O responsável ainda não cadastrou os produtos para você consumir.</div>
              ) : (
                <div>
                  <strong
                    onClick={() => adicionarProdutoClick && adicionarProdutoClick(undefined)}
                    className={classes.link}
                  >
                    Clique Aqui
                  </strong>{' '}
                  para incluir um produto novo.
                </div>
              )
            }
          />
        </React.Fragment>
      ) : null}
      <div>
        <Sessao
          sessoesRef={dados.sessoesRef}
          sessaoDeBusca={false}
          somenteLeitura={somenteLeitura}
          carregando={carregando}
          produtos={produtos}
          sessoes={sessoes}
          deletar={deletar}
          quandoForVisualizada={quandoSessaoForVisualizada}
          adicionarProdutoClick={adicionarProdutoClick}
          onProdutoClick={onProdutoClick}
        />
      </div>
    </div>
  );
});
