import { mergeWith, isArray, isObject, isFunction } from 'lodash';

const customizer = <A, B>(objValue: A, srcValue: B): any => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }

  if (isFunction(objValue))
    return srcValue;

  if (isObject(objValue)) {
    return mergeWith(objValue, srcValue, customizer);
  }
  
};

export const MergeDeep = <A, B>(objA: A, objB: B) => {
  return mergeWith(objA, objB, customizer);
};
