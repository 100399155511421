import { TemaModel } from 'api/cardapio/models/tema/tema-model';
import { TemaPutModel } from 'api/cardapio/models/tema/tema-put-model';
import { putTema } from 'api/cardapio/mutations/tema/put-tema';
import { useNotificacoes } from 'componentes/notificacoes';
import { isEmpty } from 'lodash';
import { useMasterApi } from 'modulos/cardapio/containers/cardapio/hooks';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEqual } from 'utils/is-equal';
import { picker } from 'utils/picker';

interface AtualizarTemaContextValue {
    alterarTema: (tema: TemaModel) => void,
    salvarHeader: (header: TemaModel) => void,
    salvarDadosEmpresa: (empresa: TemaModel) => void,
    modelFormatado: (valores: TemaModel) => Promise<void | TemaModel>;
    alterarTudo: (valores: TemaModel) => void;
}

export const useAtualizarDadosConfig = () => useContext(AtualizarTemaContext);

const AtualizarTemaContext = React.createContext<AtualizarTemaContextValue>({
    alterarTema: (tema: TemaModel) => true,
    salvarHeader: (header: TemaModel) => true,
    salvarDadosEmpresa: (empresa: TemaModel) => true,
    modelFormatado: (valores: TemaModel) => new Promise((resolve) => resolve()),
    alterarTudo: (valores: TemaModel) => { }
});

export interface AtualizarTemaProviderProps {
    children: React.ReactNode;
}

export const AtualizarTemaProvider = ({ children }: AtualizarTemaProviderProps) => {
    const { empresaId } = useParams<{ empresaId: string }>();
    const history = useHistory();
    const { showSuccessMessage, showErrorMessage, showInfoMessage } = useNotificacoes();
    const [empresaRequest, setEmpresaRequest] = useState<TemaPutModel>(new TemaPutModel());

    //Queries
    const { dados: empresa, operacoes } = useMasterApi(empresaId);

    const salvarHeader = useCallback((header: TemaModel) => {
        setEmpresaRequest(empresaRequest.atualizarHeader({
            informacaoAdicional: header.informacaoAdicional,
        }))
    }, [empresaRequest]);

    const salvarDadosEmpresa = useCallback((empresa: TemaModel) => {
        setEmpresaRequest(empresaRequest.atualizarConfiguracoes({
            permiteEntrega: empresa.permiteEntrega,
            distanciaMaxima: empresa.distanciaMaxima,
            permiteRetirada: empresa.permiteRetirada,
            senhaAcesso: empresa.senhaAcesso,
            autoAtendimento: empresa.autoAtendimento,
            tipoUsoCardapio: empresa.tipoUsoCardapio,
            serie: empresa.serie
        }));
    }, [empresaRequest])

    const alterarTema = useCallback((tema: TemaModel) => {
        setEmpresaRequest(empresaRequest.atualizarTemaCores({
            type: tema.type,
            empresaId: empresaId,
            primaryMain: tema.primaryMain,
            primaryLight: tema.primaryLight,
            primaryDark: tema.primaryDark,
            primaryContrast: tema.primaryContrast,
            secondaryMain: tema.secondaryMain,
            secondaryLight: tema.secondaryLight,
            secondaryDark: tema.secondaryDark,
            secondaryContrast: tema.secondaryContrast,
            textPrimary: tema.textPrimary,
            textSecondary: tema.textSecondary,
        }));
    }, [empresaRequest, empresaId])

    const validarTelefone = useCallback((valor: string) => {
        if (isEmpty(valor))
            return '.';

        return `, ou entre em contato conosco pelo telefone ${valor}.`;
    }, [])

    const alterarTudo = useCallback((valores: TemaModel) => {
        alterarTema(valores)
        salvarDadosEmpresa(valores)
        salvarHeader(valores)
    }, [alterarTema, salvarDadosEmpresa, salvarHeader])

    const modelFormatado = useCallback(async (valores: TemaModel) => {
        //Pega as informações do formulario e faz o request
        try {
            if (valores.serie === 0 || (valores.tipoVenda as any) === 0 || (valores.ambiente as any) === 0) {
                valores.disponibilizarPagamentoFinalizacao = false
            }
            valores.empresaId = empresaId
            const modelPut = picker<TemaPutModel>(valores, new TemaPutModel())
            const equal = isEqual(empresaRequest, modelPut);
            if (equal) {
                return showInfoMessage('Nenhuma Informação Alterada.');
            }
            await putTema.execute({ empresaId: empresaId, tema: modelPut });

            showSuccessMessage("Configurações atualizadas com Sucesso!");
            history.push(`/${empresaId}`)
            alterarTudo(valores)
            operacoes.atualizarCadastroEmpresa.executar();

        } catch (err: any) {
            throw showErrorMessage(`Erro ao salvar as configurações do cardápio. Detalhe: ${err.message}${validarTelefone(empresa.data?.empresa.fone ?? '')}`);
        }
    }, [alterarTudo, empresa.data?.empresa.fone, empresaId, empresaRequest, history, operacoes.atualizarCadastroEmpresa, showErrorMessage, showInfoMessage, showSuccessMessage, validarTelefone])


    return (
        <AtualizarTemaContext.Provider
            value={{
                alterarTema,
                salvarHeader,
                salvarDadosEmpresa,
                modelFormatado,
                alterarTudo
            }}>
            {children}
        </AtualizarTemaContext.Provider>
    )
}