import React from 'react';
import { Settings } from 'react-slick';

const Arrow = () => null;

export const CategoriaCarroselConfig: Settings = {
  dots: false,
  infinite: false,
  speed: 300,
  className: 'slider variable-width',
  centerMode: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
};
