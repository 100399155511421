import React from 'react';
import { Radio, withStyles } from '@material-ui/core';

export const SaurusRadio = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);
