import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    conteudo: ({ capaUrl, height }: any) => ({
        minHeight: '400px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        position: 'relative',
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url( '${capaUrl}' )`
    }),
    conteudoFilho: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        marginTop: '7rem'
    },
    action: {
        position: 'absolute',
        top: '88px',
        left: '10px',
        zIndex: 1,
    },
    marginRight: {
        marginRight: '10px',
    },
}));