function pick<T extends Record<string | number | symbol, T>, K extends keyof T>(
  objectTarget: T,
  objectOrign: T,
  keys: K[]
): Pick<T, K> {
  return Object.assign(
    {},
    ...keys.map((key: K) => {

      if (objectOrign[key] === undefined)
        return { [key]: objectTarget[key] }

      if (objectOrign[key] === null)
        return { [key]: objectTarget[key] }

      let constructorTarget = '';
      let constructorOrigin = '';
      try {
        constructorTarget = objectTarget[key].constructor.name;
      } catch { }
      try {
        constructorOrigin = objectOrign[key].constructor.name;
      } catch { }

      const valorNumber = parseFloat(objectOrign[key].toString().replace(',', '.'));
      return {
        [key]:
          constructorTarget === constructorOrigin ? objectOrign[key] :
            constructorTarget === 'Number' ? isNaN(valorNumber) ? 0 : valorNumber :
              constructorTarget === 'String' ? objectOrign[key].toString() :
                constructorTarget === 'Date' ? new Date(objectOrign[key].toString()) :
                  objectOrign[key]
      };
    })
  );
}
const removeEmpty = (obj: any) => {
  let newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]) && obj[key].constructor.name !== 'Date') {
      newObj[key] = removeEmpty(obj[key]);
    }
    else if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export function picker<T>(obj: any, objTarget: any, removeUndefined = true) {
  let picked = pick(objTarget, obj, Object.keys(objTarget));
  if (removeUndefined) {
    picked = removeEmpty(picked);
    objTarget = { ...objTarget, ...picked };
  } else {
    objTarget = picked;
  }

  return objTarget as T;
}
