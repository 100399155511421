import React from 'react';
import { Badge, IconButton, useTheme } from '@material-ui/core';
import { useObterSacola, ItemSacola } from 'storage';
import { useStyles } from './buttom-sacola-styles';
import { Fab } from 'componentes';

export interface ButtonSacolaProps {
  empresaId: string;
  corLogo: string;
  colorBadge: 'primary' | 'secondary';
  quandoClicado?: () => any;
  size?: string;
}

export const ButtonSacola = ({
  empresaId,
  quandoClicado,
  corLogo,
  colorBadge,
  size,
}: ButtonSacolaProps) => {
  const itens = useObterSacola(empresaId);
  const classes = useStyles();
  const theme = useTheme()

  const totalItensSacola = React.useCallback(() => {
    let total =
      (itens?.data || ([] as ItemSacola[])).length > 0
        ? itens?.data?.filter(x => !x.idDoProdutoPaiInfoSubItem).map((item) => item.qCom).reduce((prev, next) => prev! + next!)
        : 0;

    return total || 0;
  }, [itens]);


  return (
    <Fab style={{
      height: 64,
      width: 64
    }} color='primary'>
      <IconButton
        onClick={quandoClicado}>
        <Badge
          badgeContent={totalItensSacola()}
          color={colorBadge}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{ badge: classes.badge }}
        >
          <svg
            height={size || '35'}
            viewBox="0 0 74 85"
            fill={theme.palette.primary.contrastText}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M73.0938 73.8L66.9938 24.5C66.6938 23.2 65.5938 22.2 64.2938 22.2H52.2938V18.1C52.2938 7.2 46.1938 0 37.1938 0C28.1938 0 22.0938 7.3 22.0938 18.1V22.2H8.79382C7.49382 22.2 6.39382 23.2 6.09382 24.6L0.0938185 73.9C-0.306181 76.6 0.593819 79.5 2.39382 81.5C4.09382 83.6 6.79382 84.9 9.69382 84.9H63.2938C66.0938 84.9 68.8938 83.6 70.4938 81.6C72.4938 79.5 73.4938 76.5 73.0938 73.8ZM27.2938 22.3V18.2C27.2938 12.3 29.8938 5.4 37.0938 5.4C44.2938 5.4 46.8938 12.3 46.8938 18.2V22.3H27.2938Z"
            />
          </svg>
        </Badge>
      </IconButton>
    </Fab>
  );
};
