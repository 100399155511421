import React, { ReactNode, useCallback, useRef, memo } from 'react';
import { Fade } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ImagemBlobParaBase64 } from 'utils';
import AddAPhotoRoundedIcon from '@material-ui/icons/AddAPhotoRounded';
import { BotaoTransparente, useNotificacoes } from 'componentes';
import { useStyles } from './cardapio-header-capa-styles';
import { atualizarTemaCapa } from 'api/cardapio/mutations/tema/put-tema-capa';
import { useMasterApi } from '../../../hooks';

export interface CardapioHeaderCapaProps {
  capaUrl?: string;
  height: number | string;
  children?: ReactNode;
  somenteLeitura?: boolean;
  empresaId: string;
  quandoUmaNovaCapaForSelecionada?: (args: { capa: string }) => any;
}

const CardapioHeaderCapaComp = ({
  capaUrl,
  somenteLeitura,
  children,
  height,
  empresaId,
  quandoUmaNovaCapaForSelecionada,
}: CardapioHeaderCapaProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { showErrorMessage, showSuccessMessage } = useNotificacoes();

  const { operacoes } = useMasterApi(empresaId);

  const salvarCapa = useCallback(async (capa: string) => {
    try {
      await atualizarTemaCapa.execute({ empresaId: empresaId, imagem: { imagemBase64: capa } });

      showSuccessMessage('Capa atualizada com sucesso.');
      operacoes.atualizarCadastroEmpresa.executar()
      if (quandoUmaNovaCapaForSelecionada) {
        quandoUmaNovaCapaForSelecionada({
          capa,
        });
      }
    }
    catch (error: any) {
      showErrorMessage('Erro ao tentar atualizar a capa. Verifique a sua conexão e tente novamente. Detalhe: ' + error.message)
    }
  }, [empresaId, operacoes.atualizarCadastroEmpresa, quandoUmaNovaCapaForSelecionada, showErrorMessage, showSuccessMessage])

  const atualizarFotoDeCapa = useCallback(() => {
    inputFileRef?.current?.click();
  }, []);

  const uploadCapa = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isEmpty(event?.target?.files)) {
        return;
      }

      const file = event?.target?.files![0];
      const capa = await ImagemBlobParaBase64(file);

      await salvarCapa(capa);
    },
    [salvarCapa],
  );

  const classes = useStyles({ capaUrl, height });

  return (
    <div className={classes.conteudo}>
      <Fade in={somenteLeitura === false} appear={false}>
        <div className={classes.action}>
          <input
            ref={inputFileRef}
            multiple={false}
            type="file"
            value={''}
            accept="image/*"
            hidden
            onChange={uploadCapa}
          />
          <BotaoTransparente tipo="button" size="small" onClick={atualizarFotoDeCapa}>
            <AddAPhotoRoundedIcon fontSize="small" className={classes.marginRight} />
            {capaUrl ? 'Alterar Capa' : 'Adicionar Capa'}
          </BotaoTransparente>
        </div>
      </Fade>
      <div className={classes.conteudoFilho}>{children}</div>
    </div>
  );
};
export const CardapioHeaderCapa = memo(CardapioHeaderCapaComp);
