import React from 'react';
import { Route as ReactRoute, RouteProps as ReactRouteProps } from 'react-router-dom';
import { DocumentTitle } from 'componentes/document-title';

export interface RouteProps extends ReactRouteProps {
  titulo: string;
}

export const Route = ({ titulo, ...props }: RouteProps) => {
  return (
    <React.Fragment>
      <DocumentTitle titulo={titulo} />
      <ReactRoute {...props} />
    </React.Fragment>
  );
};
