

export function imagemBase64(img: string) {
   if (img) {
      try {
         return window.atob(img)
      } catch {
         return img
      }
   } else {
      return ''
   }
}