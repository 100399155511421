import { guidEmpty } from "utils/guid-empty";
import { MovSimplesModificadoresProdutoModel } from './mov-simples-modificadores-produto-model';

export enum EnumTpSubItem {
    InsumoProducao = 0,
    InsumoProducaoFixo = 1,
    InsumoAutomatico = 2,
    InsumoAutomaticoFixo = 3,
}

export enum EnumBalanca {
    Normal = 0,
    Unitario = 1,
    PesadoVenda = 2,
    Pesavel = 3,
    Glaciado = 4,
}

export enum EnumTpProduto {
    Produto = 0,
    ProdutoComSubItem = 1,
    Combo = 2,
    Insumo = 3,
    Opcional = 4,
    Adicional = 5,
    Medicamento = 6
}

export enum EnumTipoProduto {
    Produto = 0,
    Combo = 1,
    Insumo = 3,
    Servico = 4
}

export enum EnumIndDesperdicio {
    NaoSeAplica = 0,
    NaoProduzido = 1,
    Produzido = 2,
    Consumido = 3
}


export enum EnumTpCalculoModificador {
    Soma = 0,
    Rateia = 1,
    Maior = 2,
}

export class ProdutoNovoSubItemModel {
    constructor(
        public id: string | null = null,
        public produtoGradeId: string = guidEmpty(),
        public produtoSubGradeId: string = guidEmpty(),
        public modificadorId: string | null = null,
        public tipo: EnumTpSubItem = EnumTpSubItem.InsumoProducao,
        public ordem: number = 0,
        public fator: number = 1,
        public qPadrao: number = 0,
        public qMin: number = 0,
        public qMax: number = 0,
    ) { }
}

export class MovSimplesProdutoModel {
    constructor(
        public nSeq: number = 0,
        public produtoGradeId: string | null = null,
        public tabelaPrecoId: string | null = null,
        public vendedorId: string | null = null,
        public vendedorNome: string = '',
        public cProd: string = '',
        public cProdKit: string = '',
        public cEan: string = '',
        public xProd: string = '',
        public ativo: boolean = true,
        public uCom: string = '',
        public qCom: number = 0,
        public vUnCom: number = 0,
        public vProd: number = 0,
        public vFrete: number = 0,
        public vSeg: number = 0,
        public vDescUsuario: number = 0,
        public vAcrescUsuario: number = 0,
        public vFinal: number = 0,
        public imgUrl: string = '',
        public categoria: string = '',
        public observacao: string = '',
        public ncm: string = '',
        public temImposto: boolean = false,
        public balanca: EnumBalanca = EnumBalanca.Normal,
        public grupoImpostoId: string | null = null,
        public ncmId: string | null = null,
        public vTrib: number = 0,
        public pTribManual: number = 0,
        public pTribFederal: number = 0,
        public pTribEstadual: number = 0,
        public pTribMunicipal: number = 0,
        public produtoId: string | null = null,
        public id: string = guidEmpty(),
        public pedidoId: string = '',
        public comandaId: string = '',
        public mesaId: string = '',
        public setorId: string | null = null,
        public salaoId: string | null = null,
        public taxaServico: number | null = null,
        public valorServico: number | null = null,
        public cobraTaxaServico: boolean = true,
        public subItens: ProdutoNovoSubItemModel[] = [],
        public prodSubItem: MovSimplesProdutoModel[] = [],
        public infoSubItem: ProdutoNovoSubItemModel & { modificadorTipoCalculo: EnumTpCalculoModificador } | null = null,
        public idDoProdutoPaiInfoSubItem: string | null = null,
        public indFin: boolean = true,
        public tpProduto: EnumTpProduto = EnumTpProduto.Produto,
        public produtoPaiId: string | null = null,
        public validacaoSubItem: boolean = false,
        public idAdicional: string | null = null,
        public idGroup: string | null = null,
        public adicionais: MovSimplesProdutoModel[] = [],
        public indDesperdicio: EnumIndDesperdicio = EnumIndDesperdicio.NaoSeAplica,
        public nivel: number = 0,
        public produtoIdReferencia: string | null = null,
        public quantidadeMax: number = 0,
        public mesaCodigo: string | null = null,
        public codigoComanda: string | null = null,
        public empresaId: string = '',
        public contemSubItem: boolean = false,
        public modificadorId: string | null = null,
        public modificadores: MovSimplesModificadoresProdutoModel[] = [],
        public qComModificador: number = 0,
        public ordem: number = 0,
        public categoriaId: string = '',
        public codigo: string = '',
        public imagemUrl: string | null = null,
        public vUnComOrig: number = 0,
        public modificadorUnicoId: string = '',
        public infAdic: string = '',
    ) { }
}
