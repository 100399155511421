import { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Fade,
  Grid,
  IconButton,
  Box,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStyles } from './dialog-saurus-styles';
import React from 'react';
import { CircularLoading } from './components/circular-loading';
import { CloseIcon } from './components/icons/close-icon';
import { DialogSaurusProps } from './dialog-saurus-props';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

const TextWrapper = ({ text, caracteresPorLinha, colorTitulo }) => {
  const breakText = (text, caracteresPorLinha) => {
    const regex = new RegExp(`.{1,${caracteresPorLinha}}`, 'g');
    return text.length < caracteresPorLinha ? text : text.match(regex).join('-\n');
  };

  const result = breakText(text, caracteresPorLinha);

  return (
    <Grid style={{ whiteSpace: 'pre-line' }}>
      <Typography
        variant='h6'
        style={{
          color: colorTitulo ? colorTitulo : '',
          fontWeight: 700,
        }}
      >
        {result}
      </Typography>
    </Grid>
  );
};

export const DialogSaurus = (props: DialogSaurusProps) => {


  const {
    aberto,
    children,
    icone,
    carregando,
    titulo,
    tamanho,
    zIndex,
    isButtonTitleClose,
    handleClickClose,
    fullScreen,
    TransitionComponent,
    bottomArea,
    subTitulo,
    colorTitulo
  } = props

  const classes = useStyles(props);

  return (
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      fullScreen={fullScreen}
      disableScrollLock
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={TransitionComponent ?? Transition}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography>
        {isButtonTitleClose ? (
          <Grid
            container
            style={{
              justifyContent: titulo ? 'space-between' : 'flex-end',
            }}
          >
            <Grid
              item
              xs={11}
              style={{
                paddingRight: 8,
              }}
            >
              <TextWrapper caracteresPorLinha={80} text={titulo} colorTitulo={colorTitulo} />
            </Grid>
            <Grid item xs={1} className={classes.containerBtnClose}>
              <IconButton
                className={classes.closeButton}
                onClick={handleClickClose}
              >
                <CloseIcon tipo='BUTTON' fill='#666' style={{ width: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.titleCentralizado}>
            <Box>
              {icone ? icone : null}
              <Typography variant="h6" className={classes.title}>
                {titulo}
              </Typography>
              {subTitulo && (
                <Typography variant="body1" className={classes.subTitle}>
                  {subTitulo}
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {carregando ? <CircularLoading tipo='FULLSIZED' /> : children}
      </DialogContent>
      {bottomArea && <div>
        {bottomArea}
      </div>}
    </Dialog>
  );
};
