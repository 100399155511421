import { MergeDeep } from "utils";
import { FetchCardapioApi } from "./fetch-cardapio-api";

const FetchCardapioApiAsPublicUser = async (
  empresaId: string,
  path: RequestInfo,
  options?: RequestInit,
  urlApi?: string
): Promise<Response | null> => {
  try {

    const optionsDefault: RequestInit = {
      headers: {
        "Content-Type": "application/json",
        Domain: "app.touchone.com.br",
      },
    };

    const newOptions = MergeDeep(optionsDefault, options);

    const fetApiCardapio = await FetchCardapioApi(path, newOptions, urlApi);
    if (fetApiCardapio.ok) {
      return fetApiCardapio;
    }

    return null;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FetchCardapioApiAsPublicUserJSON = async <T>(
  empresaId: string,
  path: RequestInfo,
  options?: RequestInit,
  urlApi?: string
) => {
  const response = await FetchCardapioApiAsPublicUser(
    empresaId,
    path,
    options,
    urlApi
  );
  if (response?.status === 204 || !response) {
    return null;
  }

  return response.json();
};
