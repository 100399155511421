import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterDadosDelivery, DadosDelivery, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

export interface RemoverDadosDeliveryInput {

  idEmpresa: string;
}

class RemoverDadosDelivery implements ApiMutation<RemoverDadosDeliveryInput, DadosDelivery> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ idEmpresa }: RemoverDadosDeliveryInput) {
    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
    ) as DadosDelivery[];

    return storageCardapio.save(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
      itens.filter((x) => x.idEmpresa !== idEmpresa),
    );
  }

  updateCache(input: RemoverDadosDeliveryInput, retorno: DadosDelivery, cache: QueryCache) {
    const sessoesKey = obterDadosDelivery.getKey({ empresaId: input.idEmpresa });
    return cache.invalidateQueries(sessoesKey);
  }
}

export const removerDadosDelivery = new RemoverDadosDelivery();

export const useRemoverDadosDelivery = (
  config?: MutationConfig<DadosDelivery, unknown, RemoverDadosDeliveryInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Item Removido',
    },
    removerDadosDelivery,
  );

  return useMutation(removerDadosDelivery.execute, MergeDeep(defaultConfig, config));
};
