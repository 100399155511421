import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  card: {
    margin: '.6rem 0rem'
  },
  item: {
    display: 'flex',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    },
    alignItems: 'start'
  },

  btnsAction: {
    cursor: 'pointer',
  },
  buttonPedir: {
    marginTop: '1rem',
    width: '100%',
  },

  img: {
    flex: '1 0 auto',
    width: '120px',
    height: '120px',
    margin: '0 10px',
    alignSelf: 'center',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  info: {
    padding: '0rem .3rem',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  infoText: {
    minHeight: '100px',
  },

  iconBtn: {
    color: theme.palette.text.secondary
  },
  btn: {
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontWeight: 450,
    cursor: 'pointer',
  },
  textosFooter: {
    fontWeight: 450,
    fontSize: '1.1rem'
  },
  textoTotal: {
    marginLeft: '1rem',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  textWithCrop: {
    wordBreak: 'break-all',
    textAlign: 'justify',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    [theme.breakpoints.down('sm')]: {
      lineClamp: 2
    },
  },
  helperText: {
    position: 'absolute',
    bottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '8px',
    left: '40px',
    [theme.breakpoints.down('sm')]: {
      bottom: '3px',
    },
  },
  iconEditar: {
    fontSize: '10px',
    marginRight: '3px',
    color: theme.palette.text.secondary,
  },
  pAdicional: {
    fontWeight: 600,
    fontSize: '14px',
    marginTop: '8px',
    marginBottom: '4px'
  }
}));