import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnIcon: {
        '& svg': {
            margin: 0
        }
    },
    boxQRCode: {
        '& > svg': {
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            borderStyle: 'solid',
            borderSize: 2,
            backgroundColor: '#FFF'
        }
    },
    inputPix: {
        background: theme.palette.type === "dark" ? "#545454" : "#FFFFFF"
    }
}));