export const fetchApiComTimeout = async (url: string, timeout?: number, opcoes?: RequestInit) => {
    timeout = timeout || 1000 * 15; // 15 Segundos
  
    const abortarController = new AbortController();
    const api = fetch(url, { signal: abortarController.signal, ...opcoes });
  
    if (opcoes?.signal) {
      opcoes.signal.addEventListener('abort', () => abortarController.abort());
    }
  
    const abortarTimeout = setTimeout(() => abortarController.abort(), timeout);
  
    const resposta = await api.finally(() => clearTimeout(abortarTimeout));
    const erroServidor = resposta.status >= 500 && resposta.status <= 599;
    const erroUsuario = resposta.status >= 400 && resposta.status <= 499;
    const sucesso = resposta.status >= 200 && resposta.status <= 299;
    const json = await resposta
      .clone()
      .json()
      .catch(() => null);
    const text = await resposta
      .clone()
      .text()
      .catch(() => null);
  
    return {
      resposta,
      erroServidor,
      erroUsuario,
      json,
      text,
      sucesso,
    };
  };
  