import { DistanciaModel } from "api/master/models/distancia/distancia-model";

export const useGetLocalizacaoUsuario = () => {

    const obterLocalizacao = (): Promise<DistanciaModel> => {
        return new Promise((resolve, reject) => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocalização não suportada.'));
            }
        });
    }

    return {
        obterLocalizacao,
    }
}
