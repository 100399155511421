import { HorarioDiaModel } from "./horario-dia-model";

export class HorarioModel {
  constructor(
    public id: string = '',
    public contratoId: string = '',
    public empresaId: string = '',
    public tipo: number = 0,
    public status: number = 0,
    public empresaHorarioDias: Array<HorarioDiaModel> = new Array<HorarioDiaModel>(),  
  ){ }
}