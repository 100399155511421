import * as Yup from 'yup';
import { ValidarCor } from 'utils';

const ValidacaoCor = Yup.string()
  .required('Você precisa informar uma cor!')
  .test('validarCor', 'Você precisa informar uma cor válida!', ValidarCor);

export const EditarTemaFormValidation = Yup.object().shape({
  type: Yup.number()
    .required('Você precisa informar o tipo!')
    .oneOf([0, 1], 'O tipo pode ser apenas escuro ou claro!'),
  primaryMain: ValidacaoCor,
  secondaryMain: ValidacaoCor,
  serie: Yup.number().test({
    message: 'Série deve ser maior do que 0',
    test: (value, context) => {
      if (context.parent.disponibilizarPagamentoFinalizacao) {
        console.log(value)
        if ((value ?? 0) > 0) {
          return true
        }
        return false
      }
      return true
    }
  }).required('Série é obrigatório.'),
});

