import { Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { SimplesPagina } from "modulos/outros";
import React from "react";
import TristeImg from 'arquivos-estaticos/global/imagens/triste.png';
import { useStyles } from "./localizacao-erro-page-style";

interface LocalizacaoErroPageProps {
    titulo?: string;
    mensagem?: React.ReactNode;
    acaoFn?: () => any;
    acaoText?: string;
    cardapioPrincipal: () => void;
    tentarNovamente: () => void;
}

export const LocalizacaoErroPage = ({
    acaoFn,
    acaoText,
    mensagem,
    titulo,
    cardapioPrincipal,
    tentarNovamente
}: LocalizacaoErroPageProps) => {
    const tema = useTheme();
    const classes = useStyles();
    const isMobile = useMediaQuery(tema.breakpoints.down('xs'));

    const mostrarVoltar = Boolean(acaoFn) && Boolean(acaoText);

    return (
        <SimplesPagina>
            <Grid
                container
                spacing={2}
                className={classes.gridRoot}
                direction={isMobile ? 'column' : 'row-reverse'}
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} sm={6}>
                    <Grid container justify="center">
                        <Grid item xs={3} sm={8}>
                            <img
                                className={classes.naoEncontradoImg}
                                src={TristeImg}
                                alt="Imagem não encontrado"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        variant={isMobile ? 'h4' : 'h3'}
                        align={isMobile ? 'center' : 'left'}
                        gutterBottom
                    >
                        <strong>{titulo || 'Não foi possível acessar sua localização'}</strong>
                    </Typography>
                    <Typography variant="subtitle1" align={isMobile ? 'center' : 'left'}>
                        {mensagem || "Tivemos um problema ao tentar obter sua localização. Verifique as configurações do seu dispositivo e tente novamente."}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                color="secondary"
                                fullWidth
                                size="large"
                                className={classes.voltar}
                                onClick={cardapioPrincipal}
                            >
                                Cardápio Principal
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                className={classes.voltar}
                                onClick={tentarNovamente}
                            >
                                Tentar Novamente
                            </Button>
                        </Grid>
                    </Grid>
                    {mostrarVoltar && (
                        <Button
                            fullWidth={isMobile}
                            onClick={acaoFn}
                            className={classes.voltar}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {acaoText}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </SimplesPagina>
    );
}