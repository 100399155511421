import React from 'react';
import { Typography, Paper, InputBase, Fade } from '@material-ui/core';
import { BotaoTransparente } from 'componentes';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import classNames from 'classnames';
import { useStyles } from './cardapio-header-text-styles';

export interface CardapioHeaderTextProps {
  text?: string;
  variant?: string;
  somenteLeitura: boolean;
  editando: boolean;
  strong?: boolean;
  tipoEdicao?: string;
  className?: string;
  quandoEdicaoForIniciada?: () => any;
  quandoTextoForAlterado?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  submitEditar?: () => void;
}

export const CardapioHeaderText = ({
  text,
  somenteLeitura,
  tipoEdicao,
  className,
  quandoEdicaoForIniciada,
  quandoTextoForAlterado,
  editando,
  variant,
  strong = true,
  submitEditar
}: CardapioHeaderTextProps) => {
  const classes = useStyles({ strong, variant: variant || 'h3' });

  const existeTexto = Boolean(text);
  const mostrarEditarCompleto =
    existeTexto === false && editando === false && somenteLeitura === false;
  const mostrarEditar = editando === false && somenteLeitura === false;
  const mostrarTexto = existeTexto && editando === false;

  return (
    <div className={classNames(className, classes.root)}>
      <Fade in={mostrarTexto} mountOnEnter unmountOnExit>
        <div className={classes.textContainer}>
          <Typography
            className={classes.typography}
            align="center"
            variant={(variant as any) || 'h3'}
          >
            {strong ? <strong>{text}</strong> : text}
          </Typography>
          {mostrarEditar && (
            <BotaoTransparente
              tipo="iconButton"
              onClick={quandoEdicaoForIniciada}
              className={classes.iconButton}
            >
              <EditRoundedIcon className={classes.icon} fontSize="small" />
            </BotaoTransparente>
          )}
        </div>
      </Fade>
      {editando && (
        <Paper component="form" variant="outlined" className={classes.paper} onSubmit={ev => {
          ev.preventDefault()
          if (submitEditar) {
            submitEditar()
          }
        }}>
          <InputBase
            type="text"
            value={text}
            onChange={quandoTextoForAlterado}
            placeholder={tipoEdicao}
            classes={{
              input: classes.input,
              root: classes.inputRoot,
            }}
            onFocus={(e) => { e.target.select(); }}
            autoFocus
          />
          <EditRoundedIcon fontSize="small" onClick={() => submitEditar && submitEditar()} style={{
            cursor: 'pointer'
          }} />
        </Paper>
      )}
      <Fade in={mostrarEditarCompleto} mountOnEnter unmountOnExit>
        <BotaoTransparente tipo="button" onClick={quandoEdicaoForIniciada} size="small">
          <AddRoundedIcon fontSize="small" className={classes.marginRight} />
          {`Adicionar ${tipoEdicao || ''}`}
        </BotaoTransparente>
      </Fade>
    </div>
  );
};
