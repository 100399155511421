import React from 'react';
import {
  Fab as MaterialFab,
  FabProps as MaterialFabProps,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';

interface FabProps {
  tooltip?: string;
  carregando?: boolean;
}

export const Fab = ({ tooltip, carregando, children, ...props }: MaterialFabProps & FabProps) => {
  return (
    <Tooltip title={tooltip || ''}>
      <MaterialFab {...props}>
        {carregando ? <CircularProgress size={20} color="inherit" /> : children}
      </MaterialFab>
    </Tooltip>
  );
};
