import { makeStyles, fade } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnMenu: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '1rem',
        fontWeight: 500,
        marginLeft: '.5em',
        color: theme.palette.text.primary,
        padding: '5px',
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.4),
            borderRadius: theme.shape.borderRadius,
        },
    },
    pointer: { cursor: 'pointer' },
    btnBar: { color: '#FFFFFF', fontSize: '1.5rem', fontWeight: 500 },
    meuMenu: {
        display: 'flex',
        marginRight: '1rem',

        [theme.breakpoints.down('sm')]: {
            marginRight: 'unset',
        },
    },
    active: {
        backgroundColor: fade(theme.palette.primary.main, 0.4),
        borderRadius: theme.shape.borderRadius,
    },
}));