interface AuthProps {
    isPublicAccess?: boolean
  }
  
  export class AuthError extends Error {
    constructor(props?: AuthProps) {
      let msg = 'Seu acesso expirou e você deve realizar o login novamente.';
      if (props?.isPublicAccess === true)
        msg = 'O acesso público expirou e deve ser gerado novamente.';
  
      super(msg);
      Object.freeze(this);
    }
  }
  