import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: { marginTop: '1rem' },
    fab: {
        bottom: theme.spacing(4),
        zIndex: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '1rem',
        marginTop: '.5rem',
        paddingRight: '.5rem',
    },
    carregando: { minHeight: '400px' },
}));