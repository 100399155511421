import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

export interface BotaoProps extends ButtonProps {
  carregando?: boolean;
}

export const Botao = ({ carregando, onClick, ...props }: BotaoProps) => {
  const onClickWrap = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (carregando) {
        return;
      }

      if (onClick) {
        onClick(event);
      }
    },
    [onClick, carregando],
  );

  return (
    <Button {...props} onClick={onClickWrap}>
      {carregando ? <CircularProgress color="inherit" size={20} /> : props.children}
    </Button>
  );
};
