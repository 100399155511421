import React, { useState, useCallback, MouseEvent, memo } from 'react';
import { Drawer, Typography, Divider, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { ModalConfirmacao } from '..';
import CropFreeIcon from '@material-ui/icons/CropFree';
import MenuIcon from '@material-ui/icons/Menu';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { GetAuthLoginRedirect, SacolaButtonDrawerMenuicon } from 'utils';
import { useStyles } from './cardapio-drawer-menu-styles';

export interface CardapioDrawerMenuProps {
  // wrapNavEdicao: () => void;
  setCarregando: (carregando: boolean) => any;
  onVisualizarClick: () => any;
  onEditarConfiguracoesClick: () => void;
  gerarQRCode: () => any;
  logout: () => any;
  abrirSacola: () => any;
  buscarProds: () => any;
  capa?: string;
  somenteLeitura: boolean;
  rotaInvalida?: boolean;
  logoUrl?: string;
  mesaId?: string;
}

const CardapioDrawerMenu = ({
  // wrapNavEdicao,
  somenteLeitura,
  rotaInvalida,
  capa,
  logoUrl,
  mesaId,
  setCarregando,
  onVisualizarClick,
  onEditarConfiguracoesClick,
  gerarQRCode,
  logout,
  abrirSacola,
  buscarProds,
}: CardapioDrawerMenuProps) => {
  const classes = useStyles({ capa });
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [modal, setModal] = useState(false);

  const handleModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  const handleMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorMenu(event.currentTarget);
    },
    [setAnchorMenu],
  );
  const handleClose = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleCloseWrapper = useCallback(
    (funcao, carregando?: boolean) => {
      handleClose();
      if (carregando) {
        setCarregando(carregando || false);
        carregando ? setTimeout(() => funcao(), 1) : funcao();
        window.location.assign(GetAuthLoginRedirect())
      }
      if (!carregando) {
        setCarregando(carregando || false);
        carregando ? setTimeout(() => funcao(), 1) : funcao();
      }
    },
    [handleClose, setCarregando],
  );

  return (
    <>
      <Typography
        aria-haspopup="true"
        className={classNames(classes.menuDrawerIcon, classes.pointer)}
        onClick={handleMenu}
      >
        <MenuIcon
          color="inherit"
          style={{ fontWeight: 'inherit', fontSize: 'inherit', marginBottom: -6 }}
        />
      </Typography>
      <Drawer
        anchor="left"
        open={Boolean(anchorMenu)}
        onClose={handleClose}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.imagemCapa}>
          <img src={logoUrl} className={classes.logo} alt="Logo Empresa"></img>
        </div>
        <Divider />
        <List>
          {rotaInvalida === false ? (
            <>
              {/* Aqui ativa o modo deslogar */}
              <ListItem
                className={classes.listHover}
                onClick={() =>
                  handleCloseWrapper(onVisualizarClick, false)
                }
              >
                <>
                  <Typography
                    color="textPrimary"
                    className={classNames(classes.btnMenu, classes.pointer)}
                  >
                    <OpenInBrowserIcon color="inherit" style={{ marginRight: 10 }} />
                    Visualizar Cardápio
                  </Typography>
                </>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(gerarQRCode)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <CropFreeIcon color="inherit" style={{ marginRight: 10 }} />
                  Gerar Código QR
                </Typography>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(onEditarConfiguracoesClick)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <SettingsIcon color="inherit" style={{ marginRight: 10 }} />
                  Configurações
                </Typography>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(abrirSacola)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <SacolaButtonDrawerMenuicon tipo='BUTTON' fill={classes.sacolaPath} />
                  Abrir Sacola
                </Typography>
              </ListItem>
              <ListItem
                className={classes.listHover}
                onClick={() => handleCloseWrapper(buscarProds)}
              >
                <Typography
                  color="textPrimary"
                  className={classNames(classes.btnMenu, classes.pointer)}
                >
                  <SearchRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                  Buscar Produto
                </Typography>
              </ListItem>
            </>
          )}

          <Divider />
          {rotaInvalida === false ? (
            <ListItem
              className={classes.listHover}
              onClick={() => handleCloseWrapper(logout, false)}
            >
              <Typography
                color="textPrimary"
                className={classNames(classes.btnMenu, classes.pointer)}
              >
                <ExitToAppRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                Deslogar
              </Typography>
            </ListItem>
          ) : (
            <ListItem
              className={classes.listHover}
              onClick={() => handleCloseWrapper(logout, somenteLeitura)}
            >
              <Typography
                color="textPrimary"
                className={classNames(classes.btnMenu, classes.pointer)}
              >
                <AccountCircleRoundedIcon color="inherit" style={{ marginRight: 10 }} />
                Entrar
              </Typography>
            </ListItem>
          )}
        </List>
      </Drawer>

      <ModalConfirmacao
        aberto={modal}
        quandoLogar={logout}
        quandoCancelar={handleModal}
      />
    </>
  );
};

export const DrawerMenu = memo(CardapioDrawerMenu);
