import React, { useCallback, memo } from 'react';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import CropFreeIcon from '@material-ui/icons/CropFree';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStyles } from './cardapio-meu-menu-style';

export interface CardapioMeuMenuProps {
  setCarregando: (carregando: boolean) => any;
  onVisualizarClick: () => any;
  onEditarConfiguracoesClick: () => any;
  gerarQRCode: () => any;
  somenteLeitura: boolean;
  contextoSelecionado?: string;
}

export const CardapioMeuMenu = ({
  setCarregando,
  onVisualizarClick,
  onEditarConfiguracoesClick,
  gerarQRCode,
  somenteLeitura,
  contextoSelecionado,
}: CardapioMeuMenuProps) => {
  const classes = useStyles();

  const handleCloseWrapper = useCallback((funcao, carregando: boolean) => {
    setCarregando(carregando || false);
    carregando ? setTimeout(() => funcao(), 1) : funcao();
  }, [setCarregando]);

  return (
    <div className={classes.meuMenu}>
      <Typography
        color="textPrimary"
        className={classNames(
          classes.btnMenu,
          contextoSelecionado === 'visualizacao' && classes.active,
        )}
        onClick={() => handleCloseWrapper(onVisualizarClick, !somenteLeitura)}
      >
        <OpenInBrowserIcon color="inherit" style={{ marginRight: 10 }} />
        Modo Visualização
      </Typography>
      <Typography
        color="textPrimary"
        className={classNames(classes.btnMenu, contextoSelecionado === 'gerar-qrcode' && classes.active,
        )}
        onClick={() => handleCloseWrapper(gerarQRCode, false)}
      >
        <CropFreeIcon color="inherit" style={{ marginRight: 10 }} />
        Gerar Código QR
      </Typography>

      <Typography
        color="textPrimary"
        className={classNames(classes.btnMenu, contextoSelecionado === 'configuracoes' && classes.active,
        )}
        onClick={() => handleCloseWrapper(onEditarConfiguracoesClick, false)}
      >
        <SettingsIcon color="inherit" style={{ marginRight: 10 }} />
        Configurações
      </Typography>

    </div>
  );
};
export const MeuMenu = memo(CardapioMeuMenu);
