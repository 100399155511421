import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
import React from 'react';

export const VoltarIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill} >
                <path d="M30.2956 12.1489L27.116 8.96924L13.5854 22.4997L27.116 36.0303L30.2956 32.8506L19.9673 22.4997L30.2956 12.1489Z" />
            </DefaultIcon>
        </>
    );
};