import { useValidationYupDocumento } from 'utils/form-validations';
import * as Yup from 'yup';

export const useNovaPessoaFormValidation = () => {
  const { documentoYup } = useValidationYupDocumento(true)
  const novaPessoaFormValidation = Yup.object().shape({
    nome: Yup.string().notRequired(),
    cpfcnpj: documentoYup(),
    telefone: Yup.string().nullable().matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Número de celular inválido'),
    email: Yup.string().notRequired().email('Digite um e-mail válido'),
  });

  return {
    novaPessoaFormValidation
  }
}