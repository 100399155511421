export const files = () => {
  const loadFile = (environment, filePath, fileName) => {
    try {
      const fileImported = require(`./${environment}/${filePath}${fileName}`);
      return fileImported.default;
    } catch {
      return '';
    }
  };

  const loadImagem = (environment, fileName) => {
    return  loadFile(environment, 'imagens/', fileName);
  
  };
  const loadLogo = (environment, fileName) => {
    return loadFile(environment, 'logos/', fileName);
  };
  return {
    loadImagem,
    loadLogo,
  };
};
