import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            padding: '7px',
        },
    },
    titulo: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    loading: {
        paddingTop: '250px',
        paddingBottom: '250px',
        display: 'flex',
        justifyContent: 'center',
        background: 'transparent',
    },
    dialog: {},
    disablePaddings: {
        padding: '0px !important',
    },
}));