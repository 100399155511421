import { Fab, Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { MesaModel } from "api/pedidos/models";
import QRCode from 'qrcode.react';
import React, { useCallback, useRef, useState } from "react";
import PrintIcon from '@material-ui/icons/Print';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportComponentAsPNG } from 'react-component-export-image';
import { useStyles } from './qr-mesa-card-style';

interface MesaQrCodeCardProps {
    componentRef: React.MutableRefObject<null>;
    empresaId: string;
    mesa: MesaModel;
    tamanho: number;
    index: number;
    nomeEmpresa: string;
    infoEmpresa: string;
    opcaoTitulo: number;
    opcaoInfo: number;
    opcaoBorda: number;
}

export const MesaQrCodeCard = (props: MesaQrCodeCardProps) => {
    const classes = useStyles();
    const matchesPrint = useMediaQuery("print")

    const qrMesaRef = useRef(null)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showButtons, setShowButtons] = useState<boolean>(true)

    const downloadMesa = useCallback(() => {
        setShowButtons(false);

        setTimeout(() => {
            exportComponentAsPNG(qrMesaRef, {
                fileName: `${(props.nomeEmpresa || '').length > 0 ? `${props.nomeEmpresa}_` : ''}QRcode`,
                html2CanvasOptions: {},
            })
            setShowButtons(true);
        }, 1)
    }, [props.nomeEmpresa])
    
    return (
        <>
            <div ref={qrMesaRef} style={{ padding: '10px' }} className={classes.offSetImpressao}>
                <div
                    className={
                        props.opcaoBorda === 1
                            ? `${classes.AreaImpressao} ${classes.borda}`
                            : classes.AreaImpressao
                    }
                >
                    <div id='printCardapio'>
                        <div className={classes.titulo}>
                            {props.opcaoTitulo === 1 ? (
                                <Typography
                                    variant="h4"
                                    component="h2"
                                    className={classes.nomeEmpresa}
                                    gutterBottom={!(props.opcaoTitulo === 1)}
                                >
                                    {props.nomeEmpresa}
                                </Typography>
                            ) : null}
                            {props.opcaoInfo === 1 ? (
                                <Typography
                                    variant="body1"
                                    component="h2"
                                    gutterBottom
                                    className={classes.infoEmpresa}
                                >
                                    {props.infoEmpresa}
                                </Typography>
                            ) : null}
                        </div>
                        <Typography variant='body1' className={classes.numMesaCard}>
                            {`Mesa: ${props.mesa.codigo}`}
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <QRCode
                                value={`${window.location.origin}/${props.empresaId}/${props.mesa.id}/mesa`}
                                size={props.tamanho}
                                renderAs="svg"
                            />
                        </div>
                    </div>
                    {showButtons && (
                        <div style={{
                            display: (!matchesPrint) ? 'flex' : 'none',
                            justifyContent: 'center',
                            marginTop: '5px'
                        }}>
                            <Tooltip title="Imprimir" placement="top">
                                <Fab size='small' color="secondary" style={{ marginRight: 10 }}
                                    onClick={() => {
                                        const teste = document.querySelector(`#printCardapio`)?.innerHTML
                                        const stringDiv = `<div style='display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;'>
                      <div style="${props.opcaoBorda ? 'border: 1px solid black;' : ''} padding: 10px">
                                ${teste}
                            </div>
                        </div>`
                                        const newWindow = window.open('');
                                        newWindow?.self.document.write(`${stringDiv}`);
                                        newWindow?.self.print();
                                        newWindow?.self.close();
                                    }}>
                                    <PrintIcon fontSize="small" className={classes.icon} />
                                </Fab>
                            </Tooltip>
                            <Tooltip title="Download" placement="top">
                                <Fab
                                    size='small'
                                    onClick={downloadMesa}
                                    color="secondary"
                                >
                                    <GetAppIcon fontSize="small" className={classes.icon} />
                                </Fab>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};