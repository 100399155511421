import { makeUseAxios, Options } from "axios-hooks";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { merge } from "lodash";
import LRU from "lru-cache";
import { ApiBaseProps } from "./api-base-props";
import { ApiBaseResponse, EnumRetornoApiBase } from './api-base-response';
import { RetornoApiModel } from "./api-response-model";
import { VariaveisAmbiente } from "ambiente";

type RequestParams = AxiosRequestConfig & {
  enviarTokenUsuario: boolean;
};

const defaultTimeout = 60 * 1000; /* 10 Segundos */
const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: `${VariaveisAmbiente.cardapioApi}/api`,
    timeout: defaultTimeout,
    timeoutErrorMessage:
      "O tempo da requisição foi excedido. Verifique sua conexão com internet e tente novamente.",
  }),
  cache: new LRU({ max: 10 }),
});


export function useApiBase<T = any>(props?: ApiBaseProps) {

  const { config, opcoes } = props || {};
  const [{ loading: carregandoAxios }, invocarAxios] = useAxios<any>(
    config || "",
    merge({ manual: true }, opcoes)
  );

  const [carregandoInterno, setCarregandoInterno] = useState(false);

  useEffect(() => {
    setCarregandoInterno(carregandoAxios);
  }, [carregandoAxios]);

  const invocarApi = useCallback(async (cfg?: RequestParams, options?: Options, tentarNovamente: boolean = true): Promise<RetornoApiModel<T>> => {
    const objRet = { tipoRetorno: EnumRetornoApiBase.Servidor, statusCode: 0 };
    let resultado: AxiosResponse<any> | undefined;
    let erro: Error | undefined;

    resultado = undefined;
    erro = undefined;

    try {
      resultado = await invocarAxios(
        {
          ...cfg,
          headers: {
            'FrontUrl': 'app.touchone.com.br',
            ...cfg?.headers,
          },
        },
        options
      );
      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(undefined, resultado);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

    } catch (e: Error | any) {
      if (VariaveisAmbiente.isDev) console.log(e);
      erro = e;

      const { tipoRetorno: tpretorno, statusCode: retStatus } = ApiBaseResponse(e, e.response);
      objRet.statusCode = retStatus;
      objRet.tipoRetorno = tpretorno;

      //TRATAMENTO DE ERRO DE API
      if (e.response && e.response?.data?.title) {
        if (e.response?.data?.errors) {
          if (!e.response?.data?.errors?.Mensagens) {
            if (e.response?.data?.errors?.Mensagens.length > 1) {
              const erroFormatado = e.response?.data?.errors?.Mensagens.join('. ');
              erro = new Error(erroFormatado);
            }
          } else {
            erro = new Error(e.response?.data?.errors?.Mensagens[0]);
          }
        } else {
          erro = new Error(e.response?.data?.title);
        }
      }

      switch (objRet.tipoRetorno) {
        case EnumRetornoApiBase.Local:
          let msg = erro?.message?.toString() || "";
          if (msg.indexOf("timeout") > -1 && msg.indexOf("exceeded") > -1) {
            erro = new Error(
              `O servidor demorou muito para processar a requisição (${(cfg?.timeout || defaultTimeout) / 1000
              }s).`
            );
          }
          else if (msg.toLowerCase().indexOf("network error") > -1) {
            erro = new Error(
              `Não foi possível conectar ao servidor. Verifique sua conexão com internet e tente novamente.` +
              (msg.length > 0 ? `Detalhe: ${msg}` : ``)
            );
          }
          break;
        case EnumRetornoApiBase.Api:
          if (retStatus === 402) {
            resultado = e.response
          }
          break;
        case EnumRetornoApiBase.Servidor:
          erro = new Error(
            `O procedimento solicitado causou um erro no servidor. Tente novamente em alguns instantes. Detalhe: ${erro?.message}`
          );
          break;
      }

      resultado = e.response
    }


    setCarregandoInterno(false);

    return {
      resultado,
      erro,
      tipoRetorno: objRet.tipoRetorno,
      statusCode: objRet.statusCode,
    };
  },
    [invocarAxios]
  );

  return {
    invocarApi,
    carregando: carregandoInterno,
  };
}
