import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiPaper-root': {
            backgroundColor: '#FFF0!important'
        },
        '& .MuiPaper-elevation24': {
            boxShadow: "none"
        },
        '& .MuiBackdrop-root': {
            backgroundColor: theme.palette.type === 'dark' ? 'rgb(0 0 0 / 90%)' : 'rgb(255 255 255 / 90%)'
        },
    },
    carregando: {
        backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
    },
}));
