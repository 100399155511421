import { useValidationYupDocumento } from 'utils/form-validations';
import * as Yup from 'yup';

export const useIdentificacaoClienteFormValidation = () => {

    const { documentoYup } = useValidationYupDocumento(true)

    const identificacaoClienteFormValidation = Yup.object().shape({
        cpfCnpj: documentoYup(),
    });

    return {
        identificacaoClienteFormValidation
    }
}

