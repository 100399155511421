import { Typography } from '@material-ui/core';
import { PedidoProdutoModel } from 'api/cardapio/models/pedido/pedido';
import React from 'react';
import { memo } from 'react';

interface InfoProdInterface {
    produto: PedidoProdutoModel;
    qtdPai: number;
}

const InfoProdutoPedido = ({ produto, qtdPai }: InfoProdInterface) => {
    
    if (produto.quantidade <= 0) {
        return null;
    }

    return (
        <div style={{ marginLeft: '15px' }}>
            <Typography variant="body2" color="textSecondary" style={{
                wordBreak: 'break-all',
            }}>
                {produto.quantidade <= 1
                    ? produto.quantidade
                    : produto.adicionalId
                        ? produto.quantidade / qtdPai
                        : 1}
                x {produto.descricao}
            </Typography>
        </div>
    );
};

export default memo(InfoProdutoPedido);
