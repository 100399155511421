export enum EnumRoundTo{
    FINANCEIRO = 0,
    MATEMATICO = 1,
}

export const roundTo = (value: number, places: number = 2, tipo: EnumRoundTo = EnumRoundTo.FINANCEIRO, truncar: boolean = false) => {
    if (tipo === EnumRoundTo.FINANCEIRO) {
        var d = places || 0;
        var m = Math.pow(10, d);
        var n = +(d ? value * m : value).toFixed(8); // Avoid rounding errors
        var i = Math.floor(n), f = n - i;
        var e = 1e-8; // Allow for rounding errors in f
        var r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n);

        const valorRetorno = d ? r / m : r
        if (truncar) {
            let arrValue = valorRetorno.toString().split('')
            arrValue.pop()
            return Number(arrValue.join(''))
        }
        return valorRetorno
    }
    const valorRetorno = +(Math.round(Number(value + "e+" + places)) + "e-" + places);
    if (truncar) {
        let arrValue = valorRetorno.toString().split('')
        arrValue.pop()
        return Number(arrValue.join(''))
    }
    return valorRetorno
}