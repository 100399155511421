import { guidEmpty } from "utils/guid-empty";
import { EnumBalanca, MovSimplesProdutoModel, ProdutoNovoSubItemModel } from "./mov-simples-produto-model";

export class ProdutoCodigoModel {
  constructor(
    public id: string = guidEmpty(),
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public codigo: string = "",
    public isEanTrib: boolean = true,
    public fator: number = 1,
    public precoFixo: number | null = 0,
    public percTabela: number | null = 0,
  ) { }
}
export class ProdutoModel {
  constructor(
    public produtoId: string = guidEmpty(),
    public produtoGradeId: string = guidEmpty(),
    public empresaId: string = guidEmpty(),
    public codigo: string = "",
    public nome: string = "",
    public ncm: string = "",
    public categoriaId: string = guidEmpty(),
    public medida: string = "",
    public medidaDesc: string = "",
    public vPreco: number = 0,
    public imagemUrl: string = "",
    public ativo: boolean = true,
    public favorito: boolean = false,
    public temImposto: boolean = false,
    public balanca: EnumBalanca = EnumBalanca.Normal,
    public grupoImpostoId: string | null = null,
    public ncmId: string | null = null,
    public codigos: Array<ProdutoCodigoModel> = [],
    public cobraTaxaServico: boolean = true,
    // a propriedade id aqui é exclusiva para o uso do carrinho e não é um id real do produto, utilizado somente para eliminar o render
    public id: string = guidEmpty(),
    public setorId: string | null = null,

    public vTrib: number = 0,
    public pTribManual: number = 0,
    public pTribFederal: number = 0,
    public pTribEstadual: number = 0,
    public pTribMunicipal: number = 0,
    public subItens: ProdutoNovoSubItemModel[] = [],
    public prodSubItem: MovSimplesProdutoModel[] = [],
    public idGroup: string | null = null,
    public tipo: number = 0,
    public contemSubItem: boolean = false,
    public infAdic: string = ''
  ) { }
}
