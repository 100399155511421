import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textField: {
        width: '450px',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            width: '260px',
        },
        marginRight: -2,
    },
    textFieldArea: {
        fieldset: {
            background: '#F00',
        },
    },
    button: {
        marginLeft: '0px',
        borderRadius: '0px 5px 5px 0px',
        padding: '10px 22px',
    },
    controleBusca: {
        height: 56,
    },
}));