import { ApiQuery } from 'api/interfaces';
import { MesaModel } from 'api/pedidos/models';
import { FetchPedidosApiWithUserJSON } from '../../utils';

class ObterListMesa implements ApiQuery<string, MesaModel> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(params: string): any[] {
        return ['ObterListMesa', params];
    }

    async execute(empresaId: string, salaoId: string) {
        return await FetchPedidosApiWithUserJSON<MesaModel>(`api/v4/empresa/${empresaId}/mesa?salaoId=${salaoId}`)    
    }
}

export const obterListMesa = new ObterListMesa();