import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import React from 'react';

var OSName = "Unknown";
if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10 / Windows 11";
if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1) OSName = "Windows 8.1";
if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export enum EnumColectionFireBase {
    VENDAS = 'vendas',
    VALORES = 'valorores',
    SESSOES = 'sessoes',
    PIX = 'pix',
    NUMERACAO = 'numeracao',
    SCANNER_COMANDA = 'scannercardapio'
}

const useFirebase = () => {
    const logError = React.useCallback(async (errorData: any, colection: EnumColectionFireBase) => {
        try {
            if (colection === EnumColectionFireBase.SCANNER_COMANDA) {
                errorData = {
                    ...errorData,
                    os: OSName,
                }
            }
            if (!db.app.options.projectId) {
                throw new Error('Variáveis de ambiente não inicializadas.')
            }
            const docRef = await addDoc(collection(db, colection), errorData);
            console.log("Log escrito: ", docRef.id);
        } catch (error) {
            console.error('Erro ao salvar o log de erro:', error);
        }
    }, []);

    return {
        logError,
        firebase: app,
    };
};

export default useFirebase;