import React from 'react';
import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

interface NotificacoesProps {
  children?: React.ReactNode;
}

export const Notificacoes = ({ children }: NotificacoesProps) => {
  const notistackRef = React.useRef<SnackbarProvider>(null);

  const onClickDismiss = React.useCallback(
    (key: any) => () => {
      notistackRef?.current?.closeSnackbar(key);
    },
    [],
  );

  return (
    <SnackbarProvider
      ref={notistackRef}
      preventDuplicate
      hideIconVariant={false}
      autoHideDuration={5000}
      style={{
        zIndex: 1000
      }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      action={(key) => (
        <Button variant="text" color="inherit" onClick={onClickDismiss(key)}>
          <strong>Fechar</strong>
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
