import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';

export interface RemoverSacolaInput {
  idProduto: string;
  empresaId: string;
}

class RemoverSacola implements ApiMutation<RemoverSacolaInput, MovSimplesProdutoModel> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ idProduto }: RemoverSacolaInput) {
    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(),
    ) as MovSimplesProdutoModel[];
    return storageCardapio.save(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(), itens.filter((x) => x.id !== idProduto && x.idGroup !== idProduto));
  }

  updateCache(input: RemoverSacolaInput, retorno: MovSimplesProdutoModel, cache: QueryCache) {
    const sessoesKey = obterSacola.getKey(input.empresaId);
    return cache.invalidateQueries(sessoesKey);
  }
}

export const removerSacola = new RemoverSacola();

export const useRemoverSacola = (
  config?: MutationConfig<MovSimplesProdutoModel, unknown, RemoverSacolaInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Item Removido',
    },
    removerSacola,
  );

  return useMutation(removerSacola.execute, MergeDeep(defaultConfig, config));
};
