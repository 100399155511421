import { useCallback } from "react";

export enum CardapioStorageKeys {
  TokenIntegrador,
  Sacola,
  MesaAtual,
  ComandaAtual,
  Empresa,
  Payment,
  Pedidos,
  DadosGerais,
  ConfigEmpresa,
  ClientePadrao,
  VendedorPadrao,
  SubItensTemporarios,
  ModificadoresTemporarios
}

export function useCardapioStorage() {

  const getRegistro = useCallback(
    (key: CardapioStorageKeys, session: boolean = true) =>
      JSON.parse(
        (session
          ? sessionStorage.getItem(CardapioStorageKeys[key].toString())
          : (localStorage.getItem(CardapioStorageKeys[key].toString()))) || "{}"
      ),
    []
  );

  const exists = useCallback((key: CardapioStorageKeys, session: boolean = true) => {
    if (session) return Boolean(sessionStorage.getItem(CardapioStorageKeys[key].toString()));
    else return Boolean(localStorage.getItem(CardapioStorageKeys[key].toString()));
  }, []);

  const setRegistro = useCallback((key: CardapioStorageKeys, obj: any, session: boolean = true) => {
    if (session) {
      sessionStorage.setItem(CardapioStorageKeys[key].toString(), JSON.stringify(obj));
    } else {
      localStorage.setItem(CardapioStorageKeys[key].toString(), JSON.stringify(obj));
    }
  }, []);

  const delRegistro = useCallback((key: CardapioStorageKeys, session: boolean = true) => {
    if (session) {
      sessionStorage.removeItem(CardapioStorageKeys[key].toString());
    } else {
      localStorage.removeItem(CardapioStorageKeys[key].toString());
    }
  }, []);

  return {
    getRegistro,
    setRegistro,
    delRegistro,
    exists,
  };
}
