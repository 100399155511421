import { guidEmpty } from "utils/guid-empty";

export class MovRegistrarPagadorModel {
    constructor(
        public nome: string | null = null,
        public cpfCnpj: string | null = null,
        public email: string | null = null,
        public fone: string | null = null
    ) { }
}

export class MovRegistrarModel {
    constructor(
        public finalizadoraId: string = guidEmpty(),
        public movId: string | null = null,
        public valor: number = 0,
        public pagador: MovRegistrarPagadorModel = new MovRegistrarPagadorModel(),
    ) { }
}