import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridSaurus: {
        top: '20px',
        height: '80px',
        position: 'fixed',
        bottom: '5px',
        right: '15px',
        opacity: '1',
    },
    gridSaurusMob: {
        top: '20px',
        height: '6em',
        position: 'fixed',
        opacity: '1',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    saurusImg: {
        height: '100%',
        margin: 'auto',
        display: 'flex',
    },
}));