import * as Yup from 'yup';

export const DeliveryDadosFormEntregaValidation = Yup.object().shape({
  lgr: Yup.string().required('O endereço é obrigatório.'),
  nro: Yup.string().required('Número é obrigatório.'),
  bairro: Yup.string().required('O bairro é obrigatório.'),
  cep: Yup.string()
    .required('CEP inválido.')
    .test('len', 'CEP inválido.', (val) => {
      const val_length_without_dashes = val?.replace(/-|_/g, '').length;
      return val_length_without_dashes === 8;
    }),
  cpl: Yup.string(),
  municipio: Yup.string().required('O município é obrigatório.'),
  uf: Yup.string().required('UF inválida.'),
  referencia: Yup.string(),
});
