export const GetAuthCallbackUrl = (rota: string) => {
  const urlAtual = new URL(window.location.href);
  const novaUrlCallback = new URL(urlAtual.origin + '/api/v4/autenticar/');
  novaUrlCallback.searchParams.set('token', '@token');
  if (rota.length > 0) {
    novaUrlCallback.searchParams.set('rota', rota);
  }
  return btoa(novaUrlCallback.href.replace(/%40/gi, '@'));
};
export const GetAuthADMRedirect = (empresaId: string) => {
  return `https://app.touchone.com.br/login?callbackUrl=${btoa(`${window.location.origin}/{empresaId}/login/{accessToken}`)}&empresaId=${empresaId}`;
}

export const GetAuthLoginRedirect = () => {
  return `https://app.touchone.com.br/login?callbackUrl=${btoa(`${window.location.origin}/{empresaId}/login/{accessToken}`)}`;
}
