import { Typography, Grid, Card, Box } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { TipoCancelamentoEnum } from 'api/pedidos/models/enum/enum-status-garcom';
import { useStyles } from './item-mesa-card-style';
import semFoto from 'arquivos-estaticos/global/imagens/semFoto.png';
import { PedidoProdutoModel } from 'api/cardapio/models/pedido/pedido';
import InfoProdutoPedido from './components/info-produto-pedido/info-produto-pedido';

export interface ProdutoPedidoCardProps {
  item: PedidoProdutoModel;
}

export const ProdutoPedidoCard = ({ item }: ProdutoPedidoCardProps) => {
  const classes = useStyles();
  const produtoImgs = JSON.parse(localStorage.getItem('ProdutoImgs')!)

  const imgProduto = produtoImgs ? produtoImgs.find((x: any) => x.produtoId === item.produtoIdReferencia)?.imagemUrl ?? null : null

  const statusPedido = useCallback((status: number) => {
    switch (status) {
      case 1:
        return TipoCancelamentoEnum.disponivel;
      case 2:
        return TipoCancelamentoEnum.Indisponivel;
      case 3:
        return TipoCancelamentoEnum.Desistencia;
      case 4:
        return TipoCancelamentoEnum.Trocado;
    }
  }, []);

  const pegarImg = useMemo(() => {
    let img = semFoto;
    if (imgProduto) {
      try {
        img = atob(imgProduto)
      } catch (err: any) {
        img = imgProduto
      }
    } else {
      img = semFoto
    }
    return img
  }, [imgProduto])

  return (
    <Card className={classes.card} style={{ opacity: (item?.status.codigo) === 1 ? 1 : 0.3 }} variant="outlined">
      <Card className={classes.item}>
        <div>
          <img
            src={pegarImg}
            className={classes.img}
            alt={item.descricao}
          />
        </div>
        <div className={classes.info}>
          <Grid container direction="column" className={classes.infoText}>
            <Grid container item>
              <Typography
                gutterBottom
                variant="h5"
                color="textPrimary"
                style={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  wordBreak: 'break-all',
                  flex: 1,
                }}
              >
                {item.descricao}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body2"
                color="textSecondary"
                align="justify"
                className={classes.textWithCrop}
                gutterBottom
              >
                Status: {statusPedido(item.status.codigo)}
              </Typography>
            </Grid>
            {item.observacao && item.observacao.length > 0 ? (
              <Grid style={{ marginTop: '.3rem' }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="justify"
                  className={classes.textWithCrop}
                  gutterBottom
                >
                  Obs.: {item.observacao}
                </Typography>
              </Grid>
            ) : null}
            {item.subItens.filter(x => !x.adicionalId).length > 0 && (
              <Grid item>
                <Typography variant='caption'>
                  <Box fontWeight={600}>
                    Inclusos
                  </Box>
                </Typography>
                {item.subItens.filter(x => !x.adicionalId).map(x => {
                  return (
                    <InfoProdutoPedido produto={x} qtdPai={item.quantidade} />
                  )
                })}
              </Grid>
            )}
            {item.subItens.filter(x => x.adicionalId).length > 0 && (
              <Grid item>
                <Typography variant='caption'>
                  <Box fontWeight={600}>
                    Adicionais
                  </Box>
                </Typography>
                {item.subItens.filter(x => x.adicionalId).map(x => {
                  return (
                    <InfoProdutoPedido produto={x} qtdPai={item.quantidade} />
                  )
                })}
              </Grid>
            )}
          </Grid>

          <Grid container justify="flex-end" alignItems="center">
            <Typography variant="h5" className={classes.textosFooter} color="textPrimary">
              {JSON.stringify(item.quantidade)} UN
            </Typography>
            <Typography variant="h5" className={classes.textoTotal} color="textPrimary">
              {(item.valorTotal + item.subItens.filter(x => x.indFin).reduce((acc, current) => acc + current.valorTotal, 0))?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Typography>
          </Grid>
        </div>
      </Card>
    </Card >
  );
};
