import { useCallback } from 'react';
import { useGetProdutoCategoria } from 'api/cardapio/queries/produto/get-produto-categoria';
import { usePutTema } from 'api/cardapio/mutations/tema/put-tema';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto/produto-categoria-model';

export const useCardapioApi = (empresaId: string) => {

  const { refetch: buscarSessoes, ...sessoes } = useGetProdutoCategoria({ empresaId }, {
    enabled: empresaId,
  });

  const [atualizarTema, atualizarTemaDados] = usePutTema();

  const rebuscarSessoes = useCallback(() => {
    buscarSessoes();
  }, [buscarSessoes]);

  return {
    dados: {
      empresaId,
      sessoes: {
        ...sessoes,
        data: Array.isArray(sessoes.data) ? sessoes.data : ([] as ProdutoCategoriaModel[]),
      },
    },
    operacoes: {
      atualizarTema: {
        atualizarTema,
        ...atualizarTemaDados,
      },
      buscarSessoes: {
        executar: rebuscarSessoes,
      },
    },
  };
};
