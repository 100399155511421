import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Fade,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './modal-styles';

export interface ModalProps {
  aberto?: boolean;
  children?: ReactNode;
  carregando?: ReactNode;
  titulo?: string;
  tamanho?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  zIndex?: string;
  disablePaddings?: boolean;
  quandoForFechado?: () => any;
  horarios?: () => any;
  hideCloseBtn?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Fade timeout={500} ref={ref} {...props} />;
});

export const Modal = ({
  aberto,
  children,
  carregando,
  titulo,
  tamanho,
  zIndex,
  disablePaddings,
  quandoForFechado,
  hideCloseBtn
}: ModalProps) => {
  const classes = useStyles();
  return (
    //Modal de Categoria
    <Dialog
      maxWidth={tamanho || 'lg'}
      open={aberto || false}
      fullWidth
      keepMounted
      disableEscapeKeyDown
      TransitionComponent={Transition}
      ref={(el: any) => {
        if (el && (zIndex || '').length > 0) {
          el.style.setProperty('z-index', zIndex, 'important');
        }
      }}
    >
      <DialogTitle disableTypography className={classes.titulo}>
        <Typography variant="h6">
          <strong>{titulo}</strong>
        </Typography>
        {!hideCloseBtn && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={quandoForFechado}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers classes={{ root: disablePaddings ? classes.disablePaddings : '' }}>
        {children}
      </DialogContent>
      {carregando}
    </Dialog>
  );
};
