import { AxiosError, AxiosResponse } from 'axios';

export enum EnumRetornoApiBase {
  Sucesso,
  Local,
  Api,
  Servidor
}

export function ApiBaseResponse(error?: AxiosError<any>, resposta?: AxiosResponse<any>) {

  const statusCode = resposta?.status || 0;
  const sucesso = Boolean(resposta!?.status >= 200 && resposta!?.status <= 299);
  const houveErro = Boolean(error) || !Boolean(resposta);
  const erroDeInternet = houveErro && Boolean(!error?.response) === true;
  const erroDeUsuario = Boolean(
    error?.response && error.response.status >= 400 && error.response.status <= 499,
  );

  return {
    tipoRetorno:
      sucesso ? EnumRetornoApiBase.Sucesso :
        (erroDeUsuario ? EnumRetornoApiBase.Api :
          erroDeInternet ? EnumRetornoApiBase.Local :
            EnumRetornoApiBase.Servidor
        ),
    statusCode
  };
}
