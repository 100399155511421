import { FetchPedidosApi } from './fetch-pedidos-api';
import { MergeDeep } from 'utils';

const FetchPedidosApiWithUser = async (
  path: RequestInfo,
  options?: any,
  empresaId?: string,
): Promise<Response> => {

  const Default: RequestInit = {
    headers: {
      'Content-Type': 'application/json',
      'Domain': 'app.touchone.com.br',
    },
  };

  const newOptions = MergeDeep(Default, options);
  return FetchPedidosApi(path, newOptions);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FetchPedidosApiWithUserJSON = async <Tipo>(
  path: RequestInfo,
  options?: any,
  empresaId?: string,
) => {
  let t = await FetchPedidosApiWithUser(path, options, empresaId).then(
    async (response) => {
      return await response.json()
    }
  );
  return t;
};
