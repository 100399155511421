import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  logo: {
    height: '150px',
    width: '150px',
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    objectFit: 'cover',
  },
  root: {
    position: 'relative',
  },
  iconButton: {
    zIndex: 1,
    position: 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  icon: {
    marginRight: '5px',
  },
}));