import { Carregando, useNotificacoes } from "componentes";
import React, { useCallback, useEffect, useState } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { useGetLocalizacaoUsuario } from "utils";
import { isValidUUIDV4 } from "utils/is-valid-id";
import { LocalizacaoErroPage } from "./components/localizacao-fora-loja-page";
import { DistanciaModel } from "api/master/models/distancia/distancia-model";
import { postDistancia } from "api/master/mutations/distancia/enviar-distancia";
import { useMasterApi } from "../hooks";
import { validarTelefone } from "utils/validar-telefone";
import { obterMesa } from "api/pedidos/queries";
import { CardapioStorageKeys, useCardapioStorage } from "utils/cardapio-storage";

export interface LocalizacaoUsuarioProps extends RouteProps {
    empresaId: string;
    mesaId: string;
}

enum EnumErroLocalizacao {
    MesaInvalida,
    ForaLoja,
    LocalizacaoRecusada
}

export const LocalizacaoUsuario: React.FC<LocalizacaoUsuarioProps> = ({ mesaId, empresaId, children, ...otherProps }: LocalizacaoUsuarioProps) => {
    const history = useHistory();

    // STATES
    const [permitirPagina, setPermitirPagina] = useState<boolean>(false)
    const [erroLocalizacao, setErroLocalizacao] = useState<EnumErroLocalizacao | null>(null)

    // REQUISIÇÕES
    const { obterLocalizacao } = useGetLocalizacaoUsuario()
    const { showErrorMessage } = useNotificacoes()
    const { dados } = useMasterApi(empresaId)
    const { setRegistro } = useCardapioStorage()

    const empresa = dados.data?.empresa

    const handleClose = () => {
        history.push(`/${empresaId}`);
    };

    const pegarMesa = useCallback(async () => {
        const [, mesaId] = window.location.pathname.split("/").filter(x => x);
        if (isValidUUIDV4(mesaId)) {
            const ret = await obterMesa.execute(empresaId, mesaId)

            if (!ret) return false
            setRegistro(CardapioStorageKeys.MesaAtual, ret, false)
            return ret
        }
    }, [empresaId, setRegistro]);

    const postDistanciaWrapper = useCallback(async (model: DistanciaModel) => {
        const res = await postDistancia.execute(model, empresaId);

        return res.dentro;
    }, [empresaId])

    const localizacaoUsuario = useCallback(async () => {
        if (mesaId !== undefined && isValidUUIDV4(mesaId)) {
            let erro = false
            let localizacao: DistanciaModel | null = null
            try {
                const res = await pegarMesa()
                if (!res) {
                    setErroLocalizacao(EnumErroLocalizacao.MesaInvalida)
                    return;
                }
            } catch (err: any) {
                setErroLocalizacao(EnumErroLocalizacao.MesaInvalida)
                return
            }
            try {
                localizacao = await obterLocalizacao();
            } catch (err: any) {
                erro = true
            }
            if (erro) {
                setErroLocalizacao(EnumErroLocalizacao.LocalizacaoRecusada)
                return
            }
            if (localizacao) {
                try {
                    const resposta = await postDistanciaWrapper({
                        latitude: localizacao.latitude,
                        longitude: localizacao.longitude,
                    });
                    if (!resposta) {
                        setPermitirPagina(false);
                        setErroLocalizacao(EnumErroLocalizacao.ForaLoja)
                        return
                    }
                    setPermitirPagina(true);
                } catch (err: any) {
                    showErrorMessage(`Erro ao buscar a sua localização atual. Detalhe: ${err.message}${validarTelefone(empresa?.fone ?? '')}`);
                }
            }
        }
    }, [empresa?.fone, mesaId, obterLocalizacao, pegarMesa, postDistanciaWrapper, showErrorMessage])

    const tentarNovamente = useCallback(() => {
        window.location.reload();
    }, [])

    useEffect(() => {
        localizacaoUsuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const mensagemErro = useCallback(() => {
        switch (erroLocalizacao) {
            case EnumErroLocalizacao.MesaInvalida:
                return {
                    titulo: 'Mesa não encontrada',
                    mensagem: 'Ocorreu um problema e não encontramos sua mesa, não achamos ou ela não existe, tente novamente ou tente outra mesa.'
                }
            case EnumErroLocalizacao.LocalizacaoRecusada:
                return {
                    titulo: 'Precisamos da sua localização para continuar',
                    mensagem: 'Para acessar o cardápio, precisamos verificar onde você está. Ative a permissão de localização no seu navegador e tente novamente!'
                }
            case EnumErroLocalizacao.ForaLoja:
                return {
                    titulo: 'Você está fora da área permitida',
                    mensagem: 'Para continuar com o acesso, você precisa estar dentro da área da nossa loja. Verifique sua localização, aproxime-se para seguir em frente e tente novamente!'
                }
            default:
                return {
                    titulo: 'Não foi possível acessar sua localização',
                    mensagem: 'Tivemos um problema ao tentar obter sua localização. Verifique as configurações do seu dispositivo e tente novamente.'
                }
        }
    }, [erroLocalizacao])

    if (erroLocalizacao) {
        return (
            <LocalizacaoErroPage
                cardapioPrincipal={handleClose}
                tentarNovamente={tentarNovamente}
                titulo={mensagemErro().titulo}
                mensagem={mensagemErro().mensagem}
            />
        )
    }

    if (!permitirPagina) {
        return (
            <Carregando
                titulo={'Carregando Dados'}
                mensagem={'Aguarde enquanto carregamos a página.'}
                modelo="fixed"
                carregando
            />
        );
    }

    return (
        <Route {...otherProps}>
            {children}
        </Route>
    )
}