import { QueryConfig } from 'react-query';
import { NotificationError, AuthError } from 'erros';
import { SaurusError } from 'erros/saurus-error';
import { ApiQuery } from 'api/interfaces';

export const useQueryConfig = <Output extends ApiQuery<any, any>>() => {
  return {
    onError: (error: any) => {
      if (error instanceof NotificationError) {
        console.log(error?.message!);
      } if (error instanceof SaurusError) {
        console.log(error?.message!);
      } else if (error instanceof AuthError) {
        window.location.reload();
      } else {
        console.log(error?.message!);
        console.log(error)
      }
      return error
    },
  } as QueryConfig<Output, unknown>;
};
