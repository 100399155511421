import { useGetEmpresa } from 'api/cardapio/queries/empresa/get-empresa';

export const useMasterApi = (empresaId: string) => {

  const { refetch: buscarDados, ...cardapioDados } = useGetEmpresa({ empresaId: empresaId }, {
    enabled: empresaId,
  });

  return {
    dados: { ...cardapioDados },
    operacoes: {
      atualizarCadastroEmpresa: {
        executar: buscarDados
      },

    },
  };
};
