import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    btnMenu: { display: 'flex', alignItems: 'center' },
    pointer: { cursor: 'pointer' },

    spanNome: { fontSize: '15px', marginLeft: '5px' },
    spanLogin: {
        fontSize: '11px',
        marginLeft: '5px',
        wordBreak: 'break-word',
        maxWidth: '200px',
    },
    expandLogin: { fontWeight: 'inherit', fontSize: '15px', marginLeft: 5, marginBottom: -3 },
    expandNome: { fontSize: '25px', fontWeight: 'inherit', marginBottom: '-7px' },
    menuConta: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5rem',
        fontWeight: 6000,
        cursor: 'pointer',
    },
    rootModal: {
        height: '16vh',
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    btnsModal: {
        display: 'flex',
        justifyContent: 'center',
    },
    btnModal: {
        fontWeight: 600,
        width: '40%',
    },
    textoModal: {
        fontSize: '1.8rem',
        marginTop: '1rem',
        fontWeight: 'bolder',
    },
}));