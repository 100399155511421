import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  adicionarProdutoSessao: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  emptyState: {
    marginTop: '70px',
    marginBottom: '100px',
  },
  divider: {
    borderTop: '1px solid ' + theme.palette.primary.light,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  btnSacola: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));
