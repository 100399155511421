interface ITemaPutModel {
    empresaId: string;
    type: number;
    primaryMain: string;
    primaryLight: string;
    primaryDark: string;
    primaryContrast: string;
    secondaryMain: string;
    secondaryLight: string;
    secondaryDark: string;
    secondaryContrast: string;
    textPrimary: string;
    textSecondary: string;
    informacaoAdicional: string;
    senhaAcesso: string;
    autoAtendimento: boolean;
    statusLoja: string;
    permiteEntrega: boolean;
    distanciaMaxima: number;
    permiteRetirada: boolean;
    tipoUsoCardapio: number;
}

export enum EnumTpAmb {
    Producao = 1,
    Homologacao = 2,
}

export enum ModEnum {
    NotaManual = 1,
    VendaSimples = 10,
    NFe = 55,
    MDFe = 58,
    NFCe = 65,
    CTe = 57,
    CTeOS = 67,
    SAT = 70
}

export class TemaPutModel {
    constructor(
        public empresaId: string = '',
        public type: number = 0,
        public primaryMain: string = '',
        public primaryLight: string = '',
        public primaryDark: string = '',
        public primaryContrast: string = '',
        public secondaryMain: string = '',
        public secondaryLight: string = '',
        public secondaryDark: string = '',
        public secondaryContrast: string = '',
        public textPrimary: string = '',
        public textSecondary: string = '',
        public informacaoAdicional: string = '',
        public senhaAcesso: string = '',
        public autoAtendimento: boolean = false,
        public statusLoja: string = '',
        public permiteEntrega: boolean = false,
        public distanciaMaxima: number = 0,
        public permiteRetirada: boolean = false,
        public tipoUsoCardapio: number = 0,
        public serie: number = 0,
        public ambiente: EnumTpAmb = EnumTpAmb.Homologacao,
        public tipoVenda: ModEnum = ModEnum.VendaSimples,
        public disponibilizarPagamentoFinalizacao: boolean = false
    ) { }

    setarPropriedades(obj: ITemaPutModel) {
        this.empresaId = obj.empresaId;
        this.type = obj.type;
        this.primaryMain = obj.primaryMain;
        this.primaryLight = obj.primaryLight;
        this.primaryDark = obj.primaryDark;
        this.primaryContrast = obj.primaryContrast;
        this.secondaryMain = obj.secondaryMain;
        this.secondaryLight = obj.secondaryLight;
        this.secondaryDark = obj.secondaryDark;
        this.secondaryContrast = obj.secondaryContrast;
        this.textPrimary = obj.textPrimary;
        this.textSecondary = obj.textSecondary;
        this.informacaoAdicional = obj.informacaoAdicional;
        this.senhaAcesso = obj.senhaAcesso;
        this.autoAtendimento = obj.autoAtendimento;
        this.statusLoja = obj.statusLoja;
        this.permiteEntrega = obj.permiteEntrega;
        this.distanciaMaxima = obj.distanciaMaxima;
        this.permiteRetirada = obj.permiteRetirada;
        this.tipoUsoCardapio = obj.tipoUsoCardapio;
    }


    atualizarHeader(params: {
        informacaoAdicional: string,
    }): TemaPutModel {
        this.informacaoAdicional = params.informacaoAdicional;
        return this;
    }

    atualizarConfiguracoes(params: {
        permiteEntrega: boolean,
        distanciaMaxima: number,
        permiteRetirada: boolean,
        senhaAcesso: string,
        autoAtendimento: boolean,
        tipoUsoCardapio: number,
        serie: number
    }): TemaPutModel {
        this.permiteEntrega = params.permiteEntrega;
        this.distanciaMaxima = params.distanciaMaxima;
        this.permiteRetirada = params.permiteRetirada;
        this.senhaAcesso = params.senhaAcesso;
        this.autoAtendimento = params.autoAtendimento;
        this.tipoUsoCardapio = params.tipoUsoCardapio;
        this.serie = params.serie
        return this;
    }

    atualizarTemaCores(params: {
        type: number,
        empresaId: string,
        primaryMain: string,
        primaryLight: string,
        primaryDark: string,
        primaryContrast: string,
        secondaryMain: string,
        secondaryLight: string,
        secondaryDark: string,
        secondaryContrast: string,
        textPrimary: string,
        textSecondary: string
    }): TemaPutModel {
        this.type = params.type;
        this.empresaId = params.empresaId;
        this.primaryMain = params.primaryMain;
        this.primaryLight = params.primaryLight;
        this.primaryDark = params.primaryDark;
        this.primaryContrast = params.primaryContrast;
        this.secondaryMain = params.secondaryMain;
        this.secondaryLight = params.secondaryLight;
        this.secondaryDark = params.secondaryDark;
        this.secondaryContrast = params.secondaryContrast;
        this.textPrimary = params.textPrimary;
        this.textSecondary = params.textSecondary;
        return this;
    }
}