import React, { useCallback } from "react";
import { useStyles } from './cardapio-header-horario-styles';
import { Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import classNames from "classnames";
import { Modal } from "componentes/modal/modal";
import { HorarioDiaModel } from "api/cardapio/models/horario/horario-dia-model";

interface CardapioHeaderHorarioProps {
    aberto: boolean;
    horarios: any;
    quandoForFechado: () => void;
}

export const CardapioHeaderHorario = ({
    aberto,
    horarios,
    quandoForFechado,
}: CardapioHeaderHorarioProps) => {
    const classes = useStyles();

    const switchDias = useCallback((dia: number) => ({
        0: 'domingo',
        1: 'segunda',
        2: 'terca',
        3: 'quarta',
        4: 'quinta',
        5: 'sexta',
        6: 'sabado',
    })[dia] || '', []);

    const getDiaSemana = useCallback((key) => ({
        domingo: 'Domingo',
        segunda: 'Segunda-feira',
        terca: 'Terça-feira',
        quarta: 'Quarta-feira',
        quinta: 'Quinta-feira',
        sexta: 'Sexta-feira',
        sabado: 'Sábados',
    })[key], []);

    const formatTime = useCallback((horarios: HorarioDiaModel[]) => {
        const diaAtual = new Date().getDay()
        let dataAtual = new Date()
        let horaAtual = `${dataAtual
            .getHours()
            .toString()
            .padStart(2, '0')}:${dataAtual
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${dataAtual.getSeconds().toString().padStart(2, '0')}`;

        if (horarios.length > 0) {
            try {
                return horarios.map(horario => {
                    return (
                        <Typography className={classNames(diaAtual === horario.diaSemana && horario.inicio <= horaAtual && horario.fim > horaAtual ? classes.destaque : null)}>
                            {horario.inicio} - {horario.fim}
                        </Typography>
                    )
                })
            } catch { }
        }
        return 'Fechado';
    }, [classes.destaque]);

    const sortHorarios = useCallback((horarios: HorarioDiaModel[]) => {
        try {
            let horariosFormatados = {};
            let diaSemana = new Date().getDay();

            for (let i = 0; i < 7; i++) {
                if (diaSemana === 7) {
                    diaSemana = 0;
                }
                // eslint-disable-next-line no-loop-func
                horariosFormatados[switchDias(diaSemana)] = horarios.filter(x => x.diaSemana === diaSemana);
                diaSemana++;
            }
            return horariosFormatados;
        } catch (exception) {
            return horarios;
        }
    }, [switchDias]);



    const horarioSort = sortHorarios(horarios);
    const destaque = switchDias(new Date().getDay())

    return (
        <Modal
            tamanho="xs"
            aberto={aberto}
            titulo="Horário de Funcionamento"
            quandoForFechado={quandoForFechado}
        >
            <div>
                <List component="ul" aria-label="horario" disablePadding>
                    {Object.keys(horarioSort || {}).map((keyName, i, array) => (
                        <div key={i}>
                            <ListItem classes={{ root: classes.item }}>
                                <ListItemText
                                    classes={{
                                        root: classes.listItem,
                                        primary:
                                            classNames(classes.listPrimary, destaque === keyName && classes.destaque),
                                        secondary:
                                            classNames(classes.listSecondary, destaque === keyName && classes.destaque),
                                    }}
                                    primary={getDiaSemana(keyName)}
                                    secondary={formatTime(horarioSort[keyName])}
                                />
                            </ListItem>
                            {array.length !== (i + 1) && (
                                <Divider className={classes.divider} />
                            )}
                        </div>
                    ))}
                </List>
            </div>
        </Modal>
    );
}