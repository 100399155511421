import React, { ReactElement } from 'react';
import BgImg from '../../../../arquivos-estaticos/global/imagens/bg.png';
import { useStyles } from './pagina-master-styles';

interface MasterPaginaProps {
    children: ReactElement;
}

export const MasterPagina = ({ children }: MasterPaginaProps) => {
    const classes = useStyles({ BgImg });
    return (
        <React.Fragment>
            <div className={classes.bg}></div>
            <div className={classes.conteudo}>
                {children}
            </div>
        </React.Fragment>
    );
};
