import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
      },
      typography: ({ strong, variant }: any) => ({
        color: theme.palette.text.primary,
        textShadow: '2px 2px 5px rgba(0,0,0,0.2)',
        marginRight: '10px',
        fontWeight: strong ? 'inherit' : 300,
        fontSize: variant === 'h3' ? '2.375rem' : '1.75rem',
      }),
      paper: {
        margin: 0,
        padding: '2.5px 15px',
        minWidth: '300px',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        display: 'flex',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      input: {
        padding:'15px 0px',
        width: '100%',
      },
      inputRoot: {
        width: '100%',
        paddingRight:'10px',
        '& input::placeholder': {
          color: theme.palette.text.primary,
          opacity: 0.8,
        },
      },
      iconButton: {
        padding: '8px',
      },
      icon: {
        fontSize: '1rem',
      },
      marginRight: {
        marginRight: '5px',
      },
      textContainer: {
        display: 'flex',
        alignItems: 'center',
      },
}));