import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { SalaoModel } from 'api/pedidos/models/salao-model/salao-model';

class ObterSalao implements ApiQuery<string, SalaoModel> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: string): any[] {
    return ['ObterSalao', params];
  }

  async execute(empresaId: string) {
    return await FetchCardapioApiAsPublicUserJSON<SalaoModel>(empresaId, `api/v4/empresa/${empresaId}/salao`);
  }
}

export const obterSalao = new ObterSalao();