import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { QueryCache } from 'react-query';
import { LogoModel } from 'api/cardapio/models/tema/logo-model';
import { getTema } from 'api/cardapio/queries/tema/get-tema';

export interface PutTemaLogoProps {
  empresaId: string;
  imagem: LogoModel;
}

class PutTemaLogo implements ApiMutation<PutTemaLogoProps, void> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  async execute(empresa: PutTemaLogoProps) {
    const res = await FetchCardapioApiAsMasterUserJSON(`api/v4/empresa/${empresa.empresaId}/imagem/logo`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(empresa.imagem),
    });

    return res;
  }

  updateCache(input: PutTemaLogoProps, retorno: void, cacheInstance: QueryCache) {
    const temaKey = getTema.getKey({ empresaId: input.empresaId! });
    return cacheInstance.invalidateQueries(temaKey);
  }
}

export const atualizarTemaLogo = new PutTemaLogo();