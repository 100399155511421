import { TokenIntegradorModel } from "api/master/models/token/token-integrador-model";
import { useCallback } from "react";
import { decodeToken } from "react-jwt";

export const ConvertTokenIntegrador = (token: string): TokenIntegradorModel | undefined => {
  let tokenDecode: TokenIntegradorModel | undefined;
  try {
    const utf8 = require('utf8');
    tokenDecode = decodeToken(token) as TokenIntegradorModel;
    tokenDecode.saudacao = utf8.decode(tokenDecode.saudacao);
    tokenDecode.originalToken = token;
    tokenDecode.empresa = JSON.parse(JSON.parse(JSON.stringify(tokenDecode.empresa)));
    tokenDecode.usuarioId = tokenDecode.sub || tokenDecode.jti;
  } catch (e: any) {
    tokenDecode = undefined
  }
  return tokenDecode;
};

export const useTokenIntegrador = () => {
  const isTokenExpired = useCallback((token: TokenIntegradorModel): boolean => {
    if (!token.exp) return true;
    return token.exp * 1000 <= new Date().getTime();
  }, []);

  const isTokenValid = useCallback((token: TokenIntegradorModel) => {
    return !(Object.keys(token).length === 0) && !isTokenExpired(token);
  }, [isTokenExpired]);

  return {
    isTokenExpired,
    isTokenValid
  }
}