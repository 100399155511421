import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    imagemCapa: ({ capa }: any) => ({
        backgroundImage: capa ? `url(${capa})` : 'none',
        height: '108px',
        backgroundRepeat: 'no-repeat',
        borderRadius: 'none',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',

        alignItems: 'center',
        justifyContent: 'center',
    }),
    btnMenu: { display: 'flex', alignItems: 'center' },
    pointer: { cursor: 'pointer' },
    btnBar: { color: '#FFFFFF', fontSize: '1.5rem', fontWeight: 500 },
    drawer: {
        width: '60%',
    },
    logo: {
        marginRight: 'unset',
        height: '80px',
        width: 80,
        objectFit: 'cover',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#fff',
    },
    listHover: {
        '&:hover,&:active,&:focus': {
            backgroundColor:
                theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
    },
    menuDrawerIcon: {
        marginRight: 'auto',
        fontSize: '2.5rem',
    },
    sacolaPath: { fill: theme.palette.text.primary },
    sacola: { marginRight: '10px', marginLeft: '2px' },
}));