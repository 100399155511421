export type TipoNotificationError = 'success' | 'warning' | 'error' | 'info' | undefined;
export class NotificationError extends Error {
  constructor(
    public tipo: TipoNotificationError,
    public readonly mensagem: string,
    public readonly titulo?: string,
    public readonly detalhe?: string,
    public readonly permitirNovamente?: boolean
  ) {
    super(mensagem);
    Object.freeze(this);
  }
}
