import React, { useCallback, useMemo, useRef, useState } from "react"
import { EnumFasesSacola } from "../../../cardapio-sacola";
import { Box, Button, Divider, Fade, Grid, IconButton, Slide, Typography, useTheme } from "@material-ui/core";
import { useStyles } from './formas-pagamento-styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { usePedidos } from "componentes/providers/pedidos-provider";
import { Carregando, useNotificacoes } from "componentes";
import { PixIcon } from "componentes/icons/pix-icon";
import { NaEntregaIcon } from "componentes/icons/na-entrega-icon";
import { FormaPagamentoIcon } from "componentes/icons/forma-pagamento-icon";
import { ErroIcon } from "componentes/icons/erro-icon";
import { TentarNovamenteIcon } from "componentes/icons/tentar-novamente-icon";

export interface FormasPagamentoProps {
    faseSacola: EnumFasesSacola;
    entrarFases: (fase: EnumFasesSacola) => void;
    fecharSacola: () => void;
}

export enum TpFormaPagamentoEnum {
    Entrega,
    PIX,
    Cartao
}

export enum EnumFalhaFormaPag {
    Nenhuma,
    Pix,
    Entrega
}

export const FormasPagamento = ({ entrarFases, faseSacola, fecharSacola }: FormasPagamentoProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const nextOrBack = useRef<0 | 1>(1)

    const colorSacola = useMemo(() => {
        return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary;
    }, [theme.palette.primary.main, theme.palette.text.primary, theme.palette.type]);

    const faseFormas = faseSacola === EnumFasesSacola.FormaPagamento

    const { showSuccessMessage } = useNotificacoes()
    const [falha, setFalha] = useState<boolean>(false)
    const [msgErro, setMsgErro] = useState<string>('')

    const {
        carregandoRegistrarPix,
        handleRegistroPix,
        cancelarPedido
    } = usePedidos()

    const proximo = useCallback((fase: EnumFasesSacola) => {
        nextOrBack.current = 1

        setTimeout(() => {
            entrarFases(fase)
        }, 50)

        nextOrBack.current = 0
    }, [entrarFases])

    const voltar = useCallback(() => {
        nextOrBack.current = 0

        setTimeout(() => {
            entrarFases(EnumFasesSacola.Sacola)
        }, 50)

        nextOrBack.current = 1
    }, [entrarFases])

    const clickPix = useCallback(async () => {
        try {
            setMsgErro('')
            setFalha(false)
            await handleRegistroPix()
            proximo(EnumFasesSacola.PagamentoPix)
        } catch (err: any) {
            setMsgErro(err.message)
            setFalha(true)
        }
    }, [handleRegistroPix, proximo])

    const clickEntrega = useCallback(async () => {
        proximo(EnumFasesSacola.FazendoPedido)
    }, [proximo])

    const handleCancelarPedido = useCallback(() => {
        cancelarPedido()
        showSuccessMessage('Pedido cancelado!')
        fecharSacola()
    }, [cancelarPedido, fecharSacola, showSuccessMessage])

    return (
        <Slide direction={nextOrBack.current === 1 ? "left" : 'right'} mountOnEnter unmountOnExit in={faseFormas}>
            <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
                <div className={classes.header}>
                    {!(falha || carregandoRegistrarPix) && (
                        <IconButton
                            onClick={voltar}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        >
                            <ArrowBackIosIcon
                                style={{ paddingLeft: '7px', fontSize: '32px', color: colorSacola }}
                            />
                        </IconButton>
                    )}
                    <label
                        htmlFor="NomeNoPedido"
                        style={{
                            fontWeight: 700,
                            fontSize: "24px",
                            fontStyle: "revert",
                            color: colorSacola
                        }}
                    >
                        FORMAS DE PAGAMENTO
                    </label>

                </div>
                <Divider style={{ marginTop: '1rem' }} />
                <div className={classes.scrollable} style={{
                    height: '100%',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {carregandoRegistrarPix
                        ? (
                            <Fade in>
                                <div>
                                    <Carregando
                                        carregando
                                        titulo="Aguarde..."
                                        mensagem={"Estamos gerando o pagamento PIX."}
                                    />
                                </div>
                            </Fade>
                        )
                        : falha ? (
                            <Fade in>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box textAlign={"center"}>
                                            <Typography>
                                                Ops. Ocorreu um problema ao iniciar o pagamento pelo PIX. Gostaria de tentar novamente?
                                            </Typography>
                                            {msgErro && (
                                                <Typography>
                                                    Detalhe: {msgErro}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            size="large"
                                            onClick={clickPix}
                                            className={classes.espacamento}
                                        >
                                            <TentarNovamenteIcon tipo="BUTTON_PRIMARY" />
                                            Tentar novamente
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            size="large"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            className={classes.espacamento}
                                            onClick={clickEntrega}
                                        >
                                            <NaEntregaIcon tipo="BUTTON_PRIMARY" />
                                            Na entrega (Maquininha)
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            color={
                                                theme.palette.type === "light"
                                                    ? "primary"
                                                    : "inherit"
                                            }
                                            fullWidth
                                            onClick={handleCancelarPedido}
                                        >
                                            <ErroIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                            Cancelar pedido
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Fade>
                        ) : (
                            <Fade in>
                                <div>
                                    <div className={classes.content}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <FormaPagamentoIcon
                                                        tipo={
                                                            theme.palette.type === "light"
                                                                ? 'BUTTON'
                                                                : 'BUTTON_PRIMARY'
                                                        }
                                                        style={{
                                                            height: 50,
                                                            width: 50
                                                        }} />
                                                </Box>
                                                <Box mb={3}>
                                                    <Typography variant="h4" style={{
                                                        fontWeight: 700
                                                    }}>
                                                        Formas de Pagamento
                                                    </Typography>
                                                </Box>
                                                <Typography variant="body1" style={{ marginBottom: 48 }}>
                                                    Este pedido pode ser pago antecipadamente via PIX ou realizar o pagamento presencial na maquininha no momento da entrega do pedido.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    size="large"
                                                    onClick={clickPix}
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.espacamento}
                                                    fullWidth
                                                >
                                                    <PixIcon tipo="BUTTON_PRIMARY" />
                                                    Pagar via PIX
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    size="large"
                                                    variant="outlined"
                                                    fullWidth
                                                    color={theme.palette.type === "light" ? "primary" : "inherit"}
                                                    onClick={clickEntrega}
                                                    className={classes.espacamento}
                                                >
                                                    <NaEntregaIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
                                                    Na entrega (Maquininha)
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Fade >
                        )}
                </div>
            </Box>
        </Slide >
    )
}