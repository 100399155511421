import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    searchBarContainer: ({ modo, fixo }: any) => ({
        position: 'relative',
        width: modo === 'static' ? '100%' : '100%',
        borderRadius: modo === 'dinamic' ? 0 : theme.shape.borderRadius,
        backgroundColor: theme.palette.type === 'light' ? 'rgba(0,0,0,0.10)' : 'rgba(255,255,255,0.3)',
    }),
    searchBar: ({ modo }: any) => ({
        display: 'flex',
        borderRadius: modo === 'dinamic' ? 0 : theme.shape.borderRadius
    }),
    inputRoot: {
        color: 'inherit',
        padding: '0 15px',
        '& input::placeholder': {
            color: theme.palette.text.primary,
            opacity: 1,
        },
    },
    input: ({ modo }: any) => ({
        padding: modo === 'static' ? '10px' : theme.spacing(3, 1, 3, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
    }),
    searchIcon: {
        color: theme.palette.text.primary,
    },
    cancelSearchIcon: ({ modo }: any) => ({
        color: theme.palette.text.primary,
        position: 'absolute',
        right: 12,
        top: modo === 'static' ? -2 : 12,
        margin: 'auto',
    }),
}));