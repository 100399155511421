import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterSacola, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';

export interface AtualizarSacolaTodaInput {
  produtos: MovSimplesProdutoModel[];
  empresaId: string;
  naoInvalidaCache?: boolean
}

class AtualizarSacola implements ApiMutation<AtualizarSacolaTodaInput, MovSimplesProdutoModel[]> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ produtos }: AtualizarSacolaTodaInput) {

    return storageCardapio
      .save(CardapioStorageKeys[CardapioStorageKeys.Sacola].toString(), produtos)
  }

  updateCache(input: AtualizarSacolaTodaInput, retorno: MovSimplesProdutoModel[], cache: QueryCache,) {
    const sessoesKey = obterSacola.getKey(input.empresaId);
    if (input.naoInvalidaCache) return
    return cache.invalidateQueries(sessoesKey);
  }
}

export const atualizarSacola = new AtualizarSacola();

export const useAtualizarSacolaToda = (
  config?: MutationConfig<MovSimplesProdutoModel[], unknown, AtualizarSacolaTodaInput>
) => {

  const defaultConfig = useMutationConfig(
    {
      sucesso: '',
    },
    atualizarSacola,
  );

  return useMutation(atualizarSacola.execute, MergeDeep(defaultConfig, config));
};
