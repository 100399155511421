import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { MeuMenu, MenuConta, BarraPesquisa, DrawerMenu } from '..';
import { CategoriaTabs } from '../cardapio-categoria-tabs'
import { useMediaQuery, useTheme, AppBar, Container, IconButton, Fade, Grid, Box } from '@material-ui/core';
import { Carregando } from 'componentes';
import { useHistory } from 'react-router-dom';
import { Titulo } from 'componentes';
import { isEmpty } from 'lodash';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { MergedCardapio } from 'api/merged';
import { useStyles } from './cardapio-header-navbar-styles';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto-categoria/produto-categoria-model';
import { useTokenUsuario } from 'api/master/queries/token/obter-token-usuario';
import { ButtonResumoPedido } from './../../cardapio-resumo-pedido/components/buttom-resumo-pedido/'
import { usePedidos } from 'componentes/providers/pedidos-provider';
import classNames from 'classnames';

export interface CardapioHeaderNavBarProps {
  somenteLeitura: boolean;
  categorias?: Array<ProdutoCategoriaModel>;
  configurando: boolean;
  mostarQrCode: boolean;
  termoPesquisa?: string;
  categoriaSelecionada?: string;
  referencia?: any;
  contextoSelecionado?: string;
  mesaId: string;
  statusPedido: boolean;
  categoriaClick: (categoria: string) => any;
  quandoPesquisar?: (event: ChangeEvent<HTMLInputElement>) => any;
  logout: () => any;
  quandoSacolaClick: () => any;
  quandoGarcomClick: () => any;
  setConfigurando: () => void;
  setGerarQrCode: () => void;
  navegarEditar: () => void;
  limparTermo: () => any;
  logar: () => any;
  setStatusPedido: (value: boolean) => void;
  empresaMerged: MergedCardapio | undefined
}

export const CardapioHeaderNavBar = ({
  somenteLeitura,
  categorias,
  configurando,
  mostarQrCode,
  termoPesquisa,
  categoriaSelecionada,
  referencia,
  contextoSelecionado,
  mesaId,
  categoriaClick,
  quandoPesquisar,
  logout,
  quandoSacolaClick,
  quandoGarcomClick,
  setConfigurando,
  setGerarQrCode,
  navegarEditar,
  limparTermo,
  logar,
  empresaMerged,
  statusPedido,
  setStatusPedido,
}: CardapioHeaderNavBarProps) => {

  const { configuracoes, empresa } = empresaMerged!;

  // DEPENDENCIAS
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mesaIdValido = isValidUUIDV4(mesaId);
  const { usuarioToken, RotaValida } = useTokenUsuario()
  const rotaInvalida = !RotaValida(empresa.id)
  const history = useHistory();
  const capa = configuracoes.capa

  // STATES E STYLES
  const [pesquisa, definirPesquisa] = useState(false);
  const classes = useStyles({ capa, categorias, pesquisa, configurando, mostarQrCode });
  const [carregando, setCarregando] = useState(false);
  const [mostrarCategorias, setMostrarCategorias] = useState(false);
  const { getPedido, pedidoAtual } = usePedidos()

  useEffect(() => {
    getPedido();
  }, [getPedido])

  useEffect(() => {
    setTimeout(() => {
      setMostrarCategorias(
        isEmpty(categorias) === false && Array.isArray(categorias) && !configurando && !mostarQrCode,
      );
    }, 1);
  }, [categorias, somenteLeitura, configurando, mostarQrCode]);

  useEffect(() => {
    if (!rotaInvalida) {
      definirPesquisa(false);
    }
  }, [rotaInvalida]);

  const onVisualizarClick = useCallback(() => {
    history.push(`/${empresa.id}`);
  }, [history, empresa]);

  const onEditarConfiguracoesClick = useCallback(() => {
    setConfigurando();
  }, [setConfigurando]);

  const onGerarQRCode = useCallback(() => {
    setGerarQrCode();
  }, [setGerarQrCode]);

  const onPesquisaClick = React.useCallback(() => {
    definirPesquisa((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isEmpty(pedidoAtual)) {
      setStatusPedido(false)
    }
    else {
      setStatusPedido(true);
    }
  }, [pedidoAtual, setStatusPedido])

  const validarBarraPesquisa = useCallback(() => {
    switch (pesquisa) {
      case mesaIdValido:
        return true;
      case somenteLeitura:
        return true;
      case isMobile && mesaIdValido:
        return true;
      case isMobile && rotaInvalida:
        return true;
    }
  }, [mesaIdValido, isMobile, pesquisa, rotaInvalida, somenteLeitura])

  return (
    <>
      <div>
        <AppBar
          ref={referencia}
          position="static"
          className={classes.appBar}
          style={{ transition: 'height 0.3s ease-in-out  !important' }}
          color="default"
          elevation={4}
        >
          <Container fixed style={{
            marginTop: 8,
            marginBottom: 8
          }}>
            <Grid container spacing={2}>
              {isMobile ? (
                <>
                  {!mesaIdValido && (
                    <>
                      {!rotaInvalida && (
                        <Grid item xs={2}>
                          <DrawerMenu
                            capa={capa}
                            logoUrl={configuracoes.logo}
                            somenteLeitura={somenteLeitura}
                            rotaInvalida={rotaInvalida}
                            setCarregando={setCarregando}
                            gerarQRCode={onGerarQRCode}
                            onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                            onVisualizarClick={onVisualizarClick}
                            logout={logout}
                            abrirSacola={quandoSacolaClick}
                            buscarProds={onPesquisaClick}
                            mesaId={mesaId}
                          />
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={!rotaInvalida || mesaIdValido ? 8 : 10}>
                    <Box display={"flex"} alignItems={"center"} height={"100%"}>
                      {Boolean(empresaMerged?.configuracoes.logo) ? (
                        <img src={empresaMerged?.configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                      ) : null}
                      <Titulo style={{ fontSize: '24px' }} className={classes.limitarTitulo}>
                        {empresa?.nomeFantasia}
                      </Titulo>
                    </Box>
                  </Grid>
                  {!mesaIdValido && (
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Fade in={!pesquisa} timeout={200}>
                        <IconButton
                          onClick={onPesquisaClick}
                          aria-label="pesquisar"
                          aria-haspopup="true"
                          className={classes.searchIcon}
                          style={{
                            pointerEvents: !pesquisa ? 'all' : 'none',
                            opacity: !pesquisa ? 1 : 0,
                            padding: 4
                          }}
                        >
                          <SearchRoundedIcon style={{ fontSize: '35px' }} />
                        </IconButton>
                      </Fade>
                    </Grid>
                  )}

                  {mesaIdValido && (
                    <>
                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={7} style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}>
                            <Fade in={!pesquisa} timeout={200}>
                              <IconButton
                                onClick={onPesquisaClick}
                                aria-label="pesquisar"
                                aria-haspopup="true"
                                className={classes.searchIcon}
                                style={{
                                  pointerEvents: !pesquisa ? 'all' : 'none',
                                  opacity: !pesquisa ? 1 : 0,
                                  padding: 4
                                }}
                              >
                                <SearchRoundedIcon style={{ fontSize: '35px' }} />
                              </IconButton>
                            </Fade>
                          </Grid>
                          <Grid item xs={6} sm={5} style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}>
                            <ButtonResumoPedido
                              mesaId={mesaId}
                              empresaId={empresa.id}
                              quandoClicado={quandoGarcomClick}
                              colorBadge="secondary"
                              corLogo="textPrimary"
                              size="35"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!rotaInvalida && (
                    <>
                      <Grid item xs={!rotaInvalida ? 2 : mesaIdValido ? 6 : 2} style={{ display: 'flex' }}>
                        {Boolean(empresaMerged?.configuracoes.logo) ? (
                          <img src={empresaMerged?.configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                        ) : null}
                      </Grid>

                      {/*Barra de Edição, configuração, QRcode e visualizão*/}
                      <Grid item xs={7} style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                        <MeuMenu
                          setCarregando={setCarregando}
                          gerarQRCode={onGerarQRCode}
                          onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                          somenteLeitura={somenteLeitura}
                          contextoSelecionado={contextoSelecionado}
                          onVisualizarClick={onVisualizarClick}
                        />
                      </Grid>
                    </>
                  )}

                  {mesaIdValido && (
                    <>
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                          <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                            {Boolean(empresaMerged?.configuracoes.logo) ? (
                              <img src={empresaMerged?.configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                            ) : null}
                            <BarraPesquisa
                              pesquisa={false}
                              termoPesquisa={termoPesquisa}
                              onPesquisaClick={onPesquisaClick}
                              quandoPesquisar={quandoPesquisar}
                              limparPesquisa={limparTermo}
                              fixo={true && !configurando}
                              modo="static"
                            />
                          </Box>
                          <ButtonResumoPedido
                            mesaId={mesaId}
                            empresaId={empresa.id}
                            quandoClicado={quandoGarcomClick}
                            colorBadge="secondary"
                            corLogo="textPrimary"
                            size="35"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}


              {/* Modal de pesquisa do produto */}
              {(!isMobile && !mesaIdValido) && (
                <Grid item xs={!rotaInvalida ? 3 : 12} style={{
                  display: 'flex',
                  justifyContent: !rotaInvalida ? 'flex-end' : 'center',
                  alignItems: 'center',
                  width: '100%',
                }}>
                  <>
                    <Fade in={!pesquisa} timeout={200}>
                      {!rotaInvalida ? (
                        <>
                          {!configurando ? (
                            <IconButton
                              onClick={onPesquisaClick}
                              aria-label="pesquisar"
                              aria-haspopup="true"
                              className={classes.searchIcon}
                              style={{
                                pointerEvents: !pesquisa ? 'all' : 'none',
                                opacity: !pesquisa ? 1 : 0,
                                padding: 4,
                                marginRight: theme.spacing(2)
                              }}
                            >
                              <SearchRoundedIcon style={{ fontSize: '35px' }} />
                            </IconButton>
                          ) : (
                            <div></div>
                          )}
                          <MenuConta
                            logout={logout}
                            rotaInvalida={rotaInvalida}
                            setCarregando={setCarregando}
                            somenteLeitura={somenteLeitura}
                            nomeUsuario={usuarioToken?.saudacao}
                            tema={configuracoes}
                            empresa={empresa}
                          />
                        </>
                      ) : (
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                          {Boolean(empresaMerged?.configuracoes.logo) ? (
                            <img src={empresaMerged?.configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                          ) : null}
                          <BarraPesquisa
                            pesquisa={false}
                            termoPesquisa={termoPesquisa}
                            onPesquisaClick={onPesquisaClick}
                            quandoPesquisar={quandoPesquisar}
                            limparPesquisa={limparTermo}
                            fixo={true && !configurando}
                            modo="static"
                          />
                        </Box>
                      )}
                    </Fade>
                  </>
                </Grid>
              )}
            </Grid>
          </Container>

          <Fade in={mostrarCategorias} mountOnEnter unmountOnExit timeout={200}>
            <CategoriaTabs
              categorias={categorias}
              categoriaClick={categoriaClick}
              categoriaSelecionada={categoriaSelecionada}
            />
          </Fade>

          {validarBarraPesquisa() && (
            <BarraPesquisa
              pesquisa={pesquisa && !configurando}
              termoPesquisa={termoPesquisa}
              onPesquisaClick={onPesquisaClick}
              quandoPesquisar={quandoPesquisar}
              limparPesquisa={limparTermo}
              modo="dinamic"
            />
          )}
        </AppBar>
      </div >
      <Carregando
        carregando={carregando}
        modelo="fixed"
        titulo={'Carregando...'}
        mensagem={`Aguarde enquanto carregamos a ${somenteLeitura ? 'edição' : 'visualização'
          } do cardápio...`}
        className={classes.carregando}
      />

    </>
  );
};