export class EmpresaModel {
    constructor(
        public id: string = '',
        public contratoId: string = '',
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public razaoSocial: string = '',
        public nomeFantasia: string = '',
        public dNascimento: string = '',
        public cnae: string = '',
        public regime: number = 0,
        public fone: string = '',
        public site: string = '',
        public infAdic: string = '',
        public cep: string = '',
        public logradouro: string = '',
        public configuracoes: Array<ConfigEmpresaModel> = [],
        public numero: string = '',
        public complemento: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public cMunicipio: string = '',
        public uf: string = '',
        public cuf: string = '',
        public segmento: string = '',
        public segmentoId: string = '',
        public tipo: number = 0,
        public email: string = '',
    ) { }
}

export class ConfigEmpresaModel {
    constructor(
        public valor: string = '',
        public empresaId: string = '',
        public codigo: number = 0
    ) { }
}
