import { EnumTpAmb, ModEnum } from "./tema-put-model";

export class TemaModel {
  constructor(
    public empresaId: string = '',
    public logo: string = '',
    public capa: string = '',
    public type: number = 0,
    public primaryMain: string = '',
    public primaryLight: string = '',
    public primaryDark: string = '',
    public primaryContrast: string = '',
    public secondaryMain: string = '',
    public secondaryLight: string = '',
    public secondaryDark: string = '',
    public secondaryContrast: string = '',
    public textPrimary: string = '',
    public textSecondary: string = '',
    public informacaoAdicional: string = '',
    public senhaAcesso: string = '',
    public autoAtendimento: boolean = false,
    public statusLoja: string = '',
    public permiteEntrega: boolean = false,
    public distanciaMaxima: number = 0,
    public permiteRetirada: boolean = false,
    public tipoUsoCardapio: number = 0,
    public obrigaPagamento: boolean = true,
    public serie: number = 0,
    public tipoVenda: ModEnum = ModEnum.VendaSimples,
    public ambiente: EnumTpAmb = EnumTpAmb.Homologacao,
    public disponibilizarPagamentoFinalizacao: boolean = false
  ) { }
}