import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    botao: {
        background: 'rgba(0,0,0, 0.4)',
        border: '0px solid rgba(255,255,255, 0.3)',
        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.25)',
        color: theme.palette.common.white,
        padding: '5px 15px',
        '&:hover': {
            background: 'rgba(0,0,0, 0.6) !important',
            boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.2)',
        },
    },
    iconButton: {
        background: 'rgba(0,0,0, 0.3)',
        border: '0px solid rgba(255,255,255, 0.3)',
        boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
        color: theme.palette.common.white,
        '&:hover': {
            background: 'rgba(0,0,0, 0.6) !important',
        },
    },
}));