import React from 'react';
import { Typography, Grid, useTheme, useMediaQuery, Button } from '@material-ui/core';
import OlaImg from 'arquivos-estaticos/global/imagens/ola.png';
import { useResources } from 'resources';
import { SimplesPagina } from '../templates';
import { GetAuthLoginRedirect } from 'utils';
import { useStyles } from './novo-cadastro-styles';


export const NovoCadastroPaginaHOC = () => {
  return <NovoCadastroPagina />;
};

export const NovoCadastroPagina = () => {
  const tema = useTheme();
  const isMobile = useMediaQuery(tema.breakpoints.down('xs'));
  const classes = useStyles();

  const novoCardapioClick = () => {
    window.location.assign("https://app.touchone.com.br/novo-contrato")
  };

  const jaTenhoUmCardapioClick = () => {
    window.location.assign(GetAuthLoginRedirect());
  }

  const resources = useResources();

  return (
    <SimplesPagina>
      <Grid
        container
        spacing={2}
        className={classes.gridRoot}
        direction={isMobile ? 'column' : 'row-reverse'}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Grid container justify="center">
            <Grid item xs={6} sm={8}>
              <img className={classes.olaImg} src={OlaImg} alt="Imagem não encontrado" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            align={isMobile ? 'center' : 'left'}
            gutterBottom
          >
            <strong>{resources.paginas.novoCadastro.titulo}</strong>
          </Typography>
          <div className={classes.bemvindo}>
            <Typography variant="h5" align={isMobile ? 'center' : 'left'}>
              {resources.paginas.novoCadastro.subtitulo}
            </Typography>
          </div>
          <br />
          <br />
          <Typography variant="subtitle1" align={isMobile ? 'justify' : 'left'}>
            {resources.paginas.novoCadastro.primeiroParagrafo}
          </Typography>
          <br />
          <Typography variant="subtitle1" align={isMobile ? 'justify' : 'left'}>
            {resources.paginas.novoCadastro.segundoParagrafo}
          </Typography>
          <div>
            <Button
              fullWidth={isMobile}
              onClick={novoCardapioClick}
              variant="contained"
              color="primary"
              size="large"
              style={{
                marginBottom: tema.spacing(1),
                marginTop: tema.spacing(1),
                marginRight: isMobile ? 0 : tema.spacing(1)
              }}
            >
              Quero um Cardápio!
            </Button>
            <Button
              fullWidth={isMobile}
              onClick={jaTenhoUmCardapioClick}
              variant="contained"
              color="primary"
              size="large"
            >
              Já Tenho um Cardápio!
            </Button>
          </div>
        </Grid>
      </Grid>
    </SimplesPagina>
  );
};
