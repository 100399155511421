import { fade, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  card: {
    boxShadow: `${theme.shadows[0]}`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${fade(theme.palette.divider, 0.1)}`,
    position: 'relative',
    minHeight: 160,
    flex: 1
  },
  conteudo: {
    display: 'flex !important',
    overflow: 'hidden',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },
  cardContent: ({ imagem }: any) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: theme.spacing(2, 2, 2, imagem ? 0 : 2),
    justifyContent: 'space-between',
    height: '100%',
  }),
  text: {
    textOverflow: 'ellipsis',
    lineHeight: 1.3,
    overflow: 'hidden',
    display: '-webkit-box',
    boxOrient: 'vertical',
  },
  nome: {
    lineClamp: 1,
    fontSize: '1.2rem',
    fontWeight: 600
  },
  descricao: {
    lineClamp: 2,
  },
  valor: {
    color: theme.palette.text.primary,
  },
  img: {
    width: '110px',
    height: '110px',
    borderRadius: theme.shape.borderRadius,
    flex: '1 0 auto',
    margin: '10px',
    objectFit: 'cover',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '110px',
      height: '110px',
    },
  },
  deleteButton: {
    padding: '9px',
  },
  deleteIcon: {
    marginRight: '5px',
  },
  medida: {
    fontSize: '1rem',
  },
}));