import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { ComandaModel } from 'api/pedidos/models/comanda-model';

class ObterComanda implements ApiQuery<string, Array<ComandaModel>> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: string): any[] {
    return ['ObterComanda', params];
  }

  async execute(empresaId: string, codigo: string) {
    return await FetchCardapioApiAsPublicUserJSON<Array<ComandaModel>>(empresaId, `api/v4/empresa/${empresaId}/comanda?codigoComanda=${codigo}`);
  }
}

export const obterComanda = new ObterComanda();