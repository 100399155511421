import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import React from 'react';
import { memo } from 'react';

interface InfoProdInterface {
  produto: MovSimplesProdutoModel;
  qtdPai: number;
  qtdTotal: MovSimplesProdutoModel[];
  incluso?: boolean
}

const InfoProduto = ({ produto, qtdPai, qtdTotal, incluso }: InfoProdInterface) => {
  if (produto.infoSubItem?.qPadrao === 0 && produto.qCom < 1) {
    return null;
  }

  const qtd = produto.qCom <= 0
  ? produto.qCom
  : produto.idAdicional
  ? produto.qCom / qtdPai
  : produto.qCom ?? 0

  return (
    <div style={{ marginLeft: '10px' }}>
      <p> {incluso ? qtd : produto.qComModificador > 0 ? qtd > qtdTotal.reduce((acc, current) => acc + current.qCom, 0) ? produto.qComModificador : `${qtd}/${qtdTotal.filter(p => p.modificadorId === produto.modificadorId).reduce((acc, current) => acc + current.qCom, 0)}` : qtd}
        x {produto.xProd}
      </p>

      {produto.prodSubItem.length > 0 &&
        produto.prodSubItem.map((p, index) => (
          <InfoProduto
            incluso={incluso}
            key={index}
            produto={{ ...p, qCom: produto.qCom === 0 ? 0 : p.qCom }}
            qtdPai={qtdPai}
            qtdTotal={produto.prodSubItem.filter(p => p.qCom > 0)}
          />
        ))}
    </div>
  );
};

export default memo(InfoProduto);
