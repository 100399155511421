import React, { ReactFragment } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './empty-state-styles';
import classNames from 'classnames';

interface EmptyStateProps {
  titulo?: ReactFragment;
  mensagem?: ReactFragment;
  className?: string;
}

export const EmptyState = React.forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    {
      titulo = 'Não encontramos nada',
      mensagem = 'Tente de uma outra forma',
      className,
    }: EmptyStateProps,
    ref,
  ) => {
    const classes = useStyles();

    return (
      <div ref={ref} className={classNames(classes.root, className)}>
        <Typography variant="h5" align="center" gutterBottom>
          <strong>{titulo}</strong>
        </Typography>
        <Typography variant="body2" align="center">
          {mensagem}
        </Typography>
      </div>
    );
  },
);
