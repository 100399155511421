import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  item: {
    padding: 0,
  },
  destaque: {
    fontWeight: 'bold',
  },
  listItem: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: theme.spacing(.5),
  },
  listPrimary: { marginRight: '1rem', flex: 1 },
  listSecondary: {
    textAlign: 'end'
  },
  divider: {
    backgroundColor: theme.palette.grey[750],
  }
}))