import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: '100%',
  },
  textInfo: {
    marginBottom: theme.spacing(2)
  },
  cardImg: {
    display: "flex",
    objectFit: "cover",
    zIndex: 0,
    position: 'relative',
    background: theme.palette.common.white,
    width: "75px",
    height: "75px ",
    borderRadius: "5px",
  },
  cardImgInfo: {
    display: "flex",
    objectFit: "cover",
    zIndex: 0,
    position: 'relative',
    background: theme.palette.common.white,
    width: "40px",
    height: "40px ",
    borderRadius: "5px",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.secondary}`,
  },
  titulo: {
    fontWeight: 700,
    fontSize: '1.25rem',
    marginBottom: theme.spacing(3),
    padding: '0 8px'
  },
  skeleton: {
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  erroIcon: {
    '& svg': {
      width: 96,
      height: 96,
      fill: theme.palette.error.main,
    },
    marginBottom: theme.spacing(2),
  },
  badgeInfoModificador: {
    minWidth: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    padding: 4,
    background: theme.palette.grey[600]
  }
}));
