import { Box, Button, Fade, Grid, Slide, Typography, useTheme } from "@material-ui/core";
import { EnumFasesSacola } from "../../../cardapio-sacola";
import { useStyles } from "./fazendo-pedido-styles";
import React, { useCallback, useEffect, useState } from "react";
import { usePedidos } from "componentes/providers/pedidos-provider";
import { Carregando, useNotificacoes } from "componentes";

export interface FazendoPedidoProps {
    faseSacola: EnumFasesSacola;
    entrarFases: (fase: EnumFasesSacola) => void;
    fecharSacola: () => void;
    setScannerComanda: (open: boolean) => void;
    handlePedidoFluxo: () => void;
}

export const FazendoPedido = ({
    entrarFases,
    faseSacola,
    fecharSacola,
    setScannerComanda,
    handlePedidoFluxo,
}: FazendoPedidoProps) => {
    const classes = useStyles()
    const theme = useTheme()
    const { handlePedido, cancelarPedido } = usePedidos()
    const { showSuccessMessage } = useNotificacoes()
    const { setCodComanda } = usePedidos()
    const [erro, setErro] = useState<string>('')

    const faseFazendoPedido = faseSacola === EnumFasesSacola.FazendoPedido

    const fazerPedido = useCallback(async () => {
        try {
            setErro('')
            await handlePedido()
            showSuccessMessage('Pedido realizado com sucesso!')
            entrarFases(EnumFasesSacola.Sacola)
            fecharSacola()
        }
        catch (err: any) {
            if (err.message.includes('comanda não foi localizada')) {
                setCodComanda(null)
            }
            setErro(err.message)
        }
    }, [entrarFases, fecharSacola, handlePedido, setCodComanda, showSuccessMessage])

    const handleCancelarPedido = useCallback(() => {
        cancelarPedido()
        showSuccessMessage('Pedido cancelado!')
        entrarFases(EnumFasesSacola.Sacola)
        fecharSacola()
    }, [cancelarPedido, entrarFases, fecharSacola, showSuccessMessage])

    useEffect(() => {
        if (faseFazendoPedido) fazerPedido()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faseFazendoPedido])

    return (
        <Slide direction={faseSacola < EnumFasesSacola.FazendoPedido ? 'right' : "left"} mountOnEnter unmountOnExit in={faseFazendoPedido}>
            <Box height={"100%"} width={"100%"} className={classes.boxContainer}>
                <div className={classes.scrollable} style={{
                    height: '100%',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {!erro ? (
                        <Fade in>
                            <div>
                                <Carregando
                                    carregando
                                    titulo='Preparando pedido...'
                                    mensagem={'Aguarde enquanto tentamos encerrar o seu pedido'}
                                />
                            </div>
                        </Fade>
                    ) : (
                        <Fade in>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box textAlign={"center"}>
                                        <Typography>
                                            {erro}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        size="large"
                                        onClick={() => {
                                            handlePedidoFluxo()
                                        }}
                                    >
                                        {erro.includes('comanda não foi localizada') ? 'Ler a comanda novamente' : 'Tentar novamente'}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size="large" variant="outlined" color={theme.palette.type === 'dark' ? "inherit" : "primary"} fullWidth onClick={handleCancelarPedido}>
                                        Cancelar pedido
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    )}
                </div>
            </Box>
        </Slide>

    )
}