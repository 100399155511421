import { ApiQuery } from 'api/interfaces';
import { FetchCardapioApiAsPublicUserJSON } from 'api/cardapio/utils';
import { useQuery, QueryConfig } from 'react-query';
import { useQueryConfig } from 'api/utils/use-query-config';
import { MergeDeep } from 'utils';
import { TemaModel } from 'api/cardapio/models/tema/tema-model';

const tema = 'Tema';

interface TemaModelProps {
  empresaId: string;
}

class GetTema implements ApiQuery<TemaModelProps, TemaModel> {
  constructor() {
    this.execute = this.execute.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  getKey(params: TemaModelProps): any[] {
    return ['GetTema', params];
  }

  async execute(_: any, empresa: TemaModelProps) {
    return await FetchCardapioApiAsPublicUserJSON<TemaModel>(empresa?.empresaId!, `api/v4/empresa/${empresa.empresaId}/tema`);
  }
}

export const getTema = new GetTema();

export const useGetTema = (props: TemaModelProps, config: QueryConfig<TemaModel>) => {
  const defaultConfig = useQueryConfig();

  const res = useQuery(getTema.getKey(props), getTema.execute, MergeDeep(defaultConfig, config));
  try {
    localStorage.setItem(tema, JSON.stringify(res.data));
  } catch (err: any) {
    console.log(err.message);
  }
  return res
};
