import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

export function useNotificacoes() {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = useCallback(
    (message: any, type: 'warning' | 'success' | 'info' | 'error' | undefined = undefined) => {
      enqueueSnackbar(String(message), {
        variant: type,
      });
    },
    [enqueueSnackbar],
  );

  const showWarningMessage = useCallback((message: any) => showMessage(message, 'warning'), [
    showMessage,
  ]);

  const showSuccessMessage = useCallback((message: any) => showMessage(message, 'success'), [
    showMessage,
  ]);

  const showInfoMessage = useCallback((message: any) => showMessage(message, 'info'), [
    showMessage,
  ]);

  const showErrorMessage = useCallback((message: any) => showMessage(message, 'error'), [
    showMessage,
  ]);



  return {
    showMessage,
    showErrorMessage,
    showSuccessMessage,
    showInfoMessage,
    showWarningMessage,
  };
}
