import { TokenGestaoEmpresaModel } from "./token-gestao-empresa-model";

export class TokenIntegradorModel{
    constructor(
        public sub: string = "",
        public usuarioId = "",
        public email: string = "",
        public confirmado: string = "",
        public jti: string = "",
        public nbf: number = 0,
        public iat: number = 0,
        public contratoId: string = "",
        public saudacao: string = "",
        public imagem: string = "",
        public jwrefresh: string = "",
        public empresa: Array<TokenGestaoEmpresaModel> = new Array<TokenGestaoEmpresaModel>(),
        public exp: number = 0,
        public iss: string = "",
        public originalToken: string = "",
        public dContrato: string = "",
    ){}
}