import { DefaultIconProps } from './default-icon/default-icon-props';
import { DefaultIcon } from './default-icon/default-icon';
import React from 'react';

export const MeusPedidosIcon = (props: DefaultIconProps) => {
    return (
        <>
            <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill} >
                <svg  viewBox='0 0 58 58'>
                    <path d="M51.7364 4.38447C51.7364 2.60882 51.4583 2.32001 49.6827 2.32001C36.0978 2.32001 22.513 2.32001 8.91746 2.32001C7.24877 2.32001 6.95996 2.59812 6.95996 4.26681C6.95996 20.3547 6.95996 36.4426 6.95996 52.5411C6.95996 54.1991 7.27017 54.52 8.90676 54.52C22.5237 54.52 36.1406 54.52 49.7682 54.52C51.4155 54.52 51.7364 54.1991 51.7364 52.5625C51.7364 44.5507 51.7364 36.5388 51.7364 28.527C51.7364 20.4723 51.7364 12.4284 51.7364 4.38447ZM48.1958 52.0491C35.6379 52.0277 23.0692 52.0277 10.5113 52.0491C9.69832 52.0491 9.4309 51.8993 9.4416 51.0115C9.48439 43.4489 9.46299 35.8863 9.46299 28.313C9.46299 20.8146 9.48439 13.3162 9.4416 5.80714C9.4416 4.91931 9.69832 4.76956 10.5113 4.76956C23.0692 4.79095 35.6379 4.79095 48.1958 4.76956C49.0088 4.76956 49.2655 4.91931 49.2655 5.80714C49.2334 20.8681 49.2334 35.9398 49.2655 51.0008C49.2655 51.8886 49.0088 52.0491 48.1958 52.0491Z" />
                    <path d="M33.7768 24.6761C30.5464 24.6761 27.3053 24.6548 24.0749 24.6975C23.3154 24.7082 23.0801 24.505 23.1122 23.7455C23.1871 21.9271 22.8983 22.2159 24.6311 22.2052C30.8459 22.1945 37.05 22.2159 43.2648 22.1838C44.0671 22.1838 44.3024 22.3977 44.2703 23.2C44.2061 24.6868 44.2596 24.6868 42.8049 24.6868C40.6548 24.6868 38.5155 24.6868 36.3654 24.6868C35.5097 24.6761 34.6433 24.6761 33.7768 24.6761Z" />
                    <path d="M33.7769 32.1425C36.9431 32.1425 40.12 32.1639 43.2862 32.1318C43.9922 32.1211 44.3024 32.2387 44.281 33.0517C44.2169 34.6241 44.2703 34.6241 42.6979 34.6241C36.5473 34.6241 30.3967 34.6134 24.2568 34.6455C23.4224 34.6455 23.0587 34.5064 23.1229 33.5651C23.2299 31.9071 22.9304 32.1639 24.567 32.1532C27.6369 32.1318 30.7069 32.1425 33.7769 32.1425Z" />
                    <path d="M33.6591 14.7282C30.4608 14.7282 27.2517 14.7068 24.0534 14.7389C23.3902 14.7496 23.08 14.6426 23.1121 13.8725C23.1656 12.268 23.1121 12.268 24.7487 12.268C30.8993 12.268 37.0392 12.2893 43.1899 12.2466C44.1098 12.2359 44.3879 12.5247 44.2595 13.3697C44.2274 13.5623 44.2274 13.7548 44.2595 13.9473C44.3558 14.5891 44.0777 14.7496 43.4573 14.7496C40.1948 14.7068 36.9323 14.7282 33.6591 14.7282Z" />
                    <path d="M33.7769 42.1011C36.9752 42.1011 40.1843 42.1225 43.3826 42.0797C44.1207 42.069 44.3025 42.2829 44.2811 42.9996C44.2383 44.5613 44.2704 44.572 42.7087 44.572C36.526 44.572 30.354 44.5507 24.1713 44.5934C23.3048 44.6041 23.0053 44.3581 23.1444 43.5238C23.1765 43.3419 23.1765 43.1387 23.1444 42.9461C23.0481 42.2723 23.3262 42.0797 23.9894 42.0904C27.2412 42.1225 30.5144 42.1011 33.7769 42.1011Z" />
                    <path d="M17.4859 24.6868C17.4538 24.6868 17.4217 24.6868 17.3896 24.6868C15.4535 24.6868 15.4642 24.6868 15.6354 22.74C15.6674 22.3229 15.8172 22.1838 16.213 22.1945C17.1008 22.2052 17.9993 22.2159 18.8872 22.1945C19.315 22.1838 19.4006 22.3764 19.422 22.7614C19.5611 24.6868 19.5824 24.6868 17.4859 24.6868Z" />
                    <path d="M17.5819 32.1425C17.7424 32.1425 17.9028 32.1425 18.0633 32.1425C19.4218 32.1425 19.3683 32.1424 19.4432 33.4902C19.4967 34.3888 19.272 34.7952 18.32 34.6455C17.9135 34.5813 17.4964 34.6348 17.0792 34.6348C15.4105 34.6134 15.7314 34.838 15.6351 33.2335C15.5817 32.3564 15.8384 32.0034 16.7262 32.1425C17.0043 32.1852 17.3038 32.1425 17.5819 32.1425Z" />
                    <path d="M17.4856 14.7282C17.261 14.7282 17.0257 14.6961 16.8117 14.7389C15.7955 14.9314 15.5709 14.4822 15.6351 13.5409C15.7314 12.1075 15.4639 12.2893 16.9401 12.2679C17.3252 12.2679 17.7103 12.2679 18.0953 12.2679C19.4217 12.2679 19.3576 12.2786 19.4538 13.5622C19.5287 14.5356 19.2292 14.91 18.2665 14.7389C18.0098 14.6854 17.7423 14.7282 17.4856 14.7282Z" />
                    <path d="M17.5819 42.1011C17.614 42.1011 17.646 42.1011 17.6781 42.1011C19.5928 42.1011 19.5715 42.1011 19.4324 44.0372C19.4003 44.4758 19.2506 44.5827 18.8548 44.572C18.3413 44.5507 17.8279 44.572 17.3144 44.572C15.5495 44.572 15.3569 44.3153 15.6351 42.529C15.6885 42.1867 15.8276 42.1011 16.1271 42.1011C16.6299 42.1118 17.1112 42.1011 17.5819 42.1011Z" />
                </svg>
            </DefaultIcon>
        </>
    );
};
