import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        right: '30px',
        bottom: '0px',
    },
    labelShrinked: {
        right: '10px',
    },
}));