import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles<Theme, any>((theme) => ({
  footer: {
    boxShadow: '-2px 0px 5px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    overflow: 'hidden',
    padding: '20px',
    paddingBottom: '15px',
    position: 'relative',
  },
  logo: {
    height: 50,
    width: 50,
    margin: 0,
    marginRight: '1rem',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  containerEmpresa: {
    zIndex: 1,
    width: '100%',
    position: 'relative',
    flex: '1 0 auto',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerBg: {
    zIndex: 0,
    opacity: 0.3,
    backgroundPosition: 'bottom center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: (props: any) => props.capa ? `url("${props.capa}")` : '',
    backgroundSize: "cover",
  },
}));

