import { useWebServiceSaurusUtils } from 'webservice/base';
import { useCallback } from 'react';
import { stringNumeros } from 'utils/string-numeros';

export function useConsultaCep() {
  const { invocarApi, ...outros } = useWebServiceSaurusUtils({
    operacao: 'retConsultaCep',
  });

  const consultarCep = useCallback(
    (cep: string) => {
      const xml = (senha: string) => `
        <?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
          <soap12:Body>
            <retConsultaCep xmlns="http://saurus.net.br/">
              <xXMLParametros><![CDATA[<xmlIntegracao><CEP>${stringNumeros(cep)}</CEP></xmlIntegracao>]]></xXMLParametros>
              <xSenha>${senha}</xSenha>
            </retConsultaCep>
          </soap12:Body>
        </soap12:Envelope>
    `;

      return invocarApi({
        xml,
      });
    },
    [invocarApi],
  );

  return {
    ...outros,
    consultarCep,
  };
}
