import { VariaveisAmbiente } from "ambiente";
import { useApiBase } from "api/base";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

export function useGetProdutoSubItens() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();
    const { empresaId } = useParams<any>()

    const getProdutoSubItens = useCallback(
        (produtoId: string, variacaoId: string) => {
            return invocarApi({
                url: `api/v4/empresa/${empresaId}/produtos/${produtoId}/variacao/${variacaoId}/subitens`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Domain': 'app.touchone.com.br',
                },
                enviarTokenUsuario: false,
                baseURL: VariaveisAmbiente.cardapioApi,
            });
        },
        [empresaId, invocarApi]
    );

    return {
        ...outrasPropriedades,
        getProdutoSubItens,
    };
}