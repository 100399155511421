import { ApiMutation } from 'api/interfaces';
import { FetchCardapioApiAsMasterUser } from 'api/cardapio/utils';
import { QueryCache } from 'react-query';
import { CapaModel } from 'api/cardapio/models/tema/capa-model';
import { getTema } from 'api/cardapio/queries/tema/get-tema';

export interface PutTemaCapaProps {
  empresaId: string;
  imagem: CapaModel;
}

class PutTemaCapa implements ApiMutation<PutTemaCapaProps, void> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  async execute(empresa: PutTemaCapaProps) {
    await FetchCardapioApiAsMasterUser(`api/v4/empresa/${empresa.empresaId}/imagem/capa`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(empresa.imagem),
    });
  }

  updateCache(input: PutTemaCapaProps, retorno: void, cacheInstance: QueryCache) {
    const temaKey = getTema.getKey({empresaId: input.empresaId!});
    return cacheInstance.invalidateQueries(temaKey);
  }
}

export const atualizarTemaCapa = new PutTemaCapa();