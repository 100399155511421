import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 9,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 .5rem',
    },
    sacola: { height: '35px' },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textos: {
        color: 'white'
    }
}));