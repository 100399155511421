import { guidEmpty } from "utils/guid-empty";
import { EnumTipoContato } from "../enuns/enum-tipo-contato";

export class PessoaModel {
    constructor(
        public id: string = guidEmpty(),
        public contratoId: string = guidEmpty(),
        public regimeTributarioId: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public representanteId: string = guidEmpty(),
        public tpCadastro: number = 2,
        public cpfcnpj: string = '',
        public ierg: string = '',
        public im: string = '',
        public indIEDest: number = 9,
        public nome: string = '',
        public fantasia: string = '',
        public infOperador: string = '',
        public infInterno: string = '',
        public status: number = 0,
        public contatos: ContatoModel[] = [],
    ) { }
}

export class ContatoModel {
    constructor(
        public id: string | null = null,
        public cadastroId: string | null = null,
        public tipo: EnumTipoContato = EnumTipoContato.TELEFONE,
        public valor: string = '',
    ) { }
}


export class PessoaModelForm {
    constructor(
        public nome: string = '',
        public cpfcnpj: string = '',
        public telefone: string = '',
        public email: string = '',
    ) { }
}