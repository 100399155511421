import { DistanciaModel } from 'api/master/models/distancia/distancia-model';
import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { ApiQuery } from 'api/interfaces';
import { DistanciaResponseModel } from 'api/master/models/distancia/distancia-response-model';

class PostDistancia implements ApiQuery<string, DistanciaResponseModel> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(params: string): any[] {
        return ['PostDistancia', params];
    }

    async execute(model: DistanciaModel, empresaId: string) {
        return await FetchCardapioApiAsMasterUserJSON<DistanciaResponseModel>(`api/v4/empresa/${empresaId}/distancia`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(model),
        });
    }
}

export const postDistancia = new PostDistancia();