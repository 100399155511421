import { DefaultIconProps } from "./default-icon/default-icon-props";
import { DefaultIcon } from "./default-icon/default-icon"
import React from "react";

export const CadeadoIcon = (props: DefaultIconProps) => {
  return (
    <>
      <DefaultIcon tipo={props.tipo} class={props.class} style={props.style} fill={props.fill}>
        <path d="M 25 4 C 18.9375 4 14 8.9375 14 15 L 14 20.84375 C 11.527344 23.519531 10 27.078125 10 31 C 10 39.273438 16.726563 46 25 46 C 33.273438 46 40 39.273438 40 31 C 40 27.078125 38.472656 23.519531 36 20.84375 L 36 15 C 36 8.9375 31.0625 4 25 4 Z M 25 6 C 29.984375 6 34 10.015625 34 15 L 34 19.03125 C 31.488281 17.136719 28.378906 16 25 16 C 21.621094 16 18.511719 17.136719 16 19.03125 L 16 15 C 16 10.015625 20.015625 6 25 6 Z M 25 18 C 32.191406 18 38 23.808594 38 31 C 38 38.191406 32.191406 44 25 44 C 17.808594 44 12 38.191406 12 31 C 12 23.808594 17.808594 18 25 18 Z M 25 28 C 23.34375 28 22 29.34375 22 31 C 22 32.300781 22.839844 33.398438 24 33.8125 L 24 36 C 24 36.550781 24.449219 37 25 37 C 25.550781 37 26 36.550781 26 36 L 26 33.8125 C 27.160156 33.398438 28 32.300781 28 31 C 28 29.34375 26.65625 28 25 28 Z" />
      </DefaultIcon>
    </>
  );
};
