import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  form: { padding: '.5rem', marginBottom: '.5rem' },
  typographyMarginTop: {
    marginTop: '1rem',
  },
  typographyMarginBottom: { marginBottom: '1rem' },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignitens: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  offSetImpressao: {
    display: 'flex',
    justifyContent: 'center',
  },
  AreaImpressao: { width: 'fit-content', padding: '1rem' },
  [`@media print`]: {
    AreaImpressao: {
      display: 'flex',
      justifyContent: 'center',
      alignitens: 'center',
      textAlign: 'center',
      margin: 'auto',
      marginTop: '3rem',
    },
  },
  borda: {
    border: `1px solid black`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  icon: { color: theme.palette.primary.contrastText },
  carregando: {
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(0,0,0,0.9)' : 'rgba(255,255,255,0.9)',
  },
  nomeEmpresa: {
    color: theme.palette.text.primary,
  },
  infoEmpresa: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
  },
  opcoes: {
    display: 'flex',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fieldTamanho: {
    marginRight: '.5rem',
  },
  qrCodeContainer: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: '1200px'
  },
  cardQrCode: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  titulo: {
    textAlign: 'center'
  }
}));