import { ApiMutation } from 'api/interfaces';
import { MutationConfig, QueryCache, useMutation } from 'react-query';
import { obterDadosDelivery, DadosDelivery, CardapioStorageKeys, storageCardapio } from 'storage';
import { MergeDeep } from 'utils';
import { useMutationConfig } from 'api/utils';

export interface AtualizarDadosDeliveryInput {
  dados: DadosDelivery;
  empresaId: string;
}

class AtualizarDadosDelivery implements ApiMutation<AtualizarDadosDeliveryInput, DadosDelivery> {
  constructor() {
    this.execute = this.execute.bind(this);
  }

  execute({ dados, empresaId }: AtualizarDadosDeliveryInput) {

    let itens = storageCardapio.load(
      CardapioStorageKeys[CardapioStorageKeys.DadosDelivery].toString(),
    ) as DadosDelivery[];
    let index = -1;

    if (Object.entries(itens).length === 0) {
      itens = [] as DadosDelivery[];
    } else {
      index = itens.findIndex((val) => val.idEmpresa === empresaId);
    }

    dados.idEmpresa = empresaId;

    if (index !== -1) {
      itens[index] = dados;
    } else {
      itens.push(dados);
    }
    return storageCardapio.save(
      CardapioStorageKeys[CardapioStorageKeys.DadosDelivery].toString(),
      itens,
    );
  }

  updateCache(input: AtualizarDadosDeliveryInput, retorno: DadosDelivery, cache: QueryCache) {
    const sessoesKey = obterDadosDelivery.getKey({ empresaId: input.empresaId });
    return cache.invalidateQueries(sessoesKey);
  }
}

export const atualizarDadosDelivery = new AtualizarDadosDelivery();

export const useAtualizarDadosDelivery = (
  config?: MutationConfig<DadosDelivery, unknown, AtualizarDadosDeliveryInput>,
) => {
  const defaultConfig = useMutationConfig(
    {
      sucesso: 'Dados Atualizados!',
    },
    atualizarDadosDelivery,
  );

  return useMutation(atualizarDadosDelivery.execute, MergeDeep(defaultConfig, config));
};
