import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridRoot: {
        minHeight: '100vh',
    },
    tristeImg: {
        objectFit: 'contain',
        width: '100%',
        margin: '0 auto',
    },
    btnRetry: {
        textTransform: 'none',
        marginTop: '10px',
    },
    break: {
        flexBasis: '100%',
        height: '0'
    }
}));