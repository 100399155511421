import React, { useCallback, useState } from 'react';
import { CategoriaProps } from './categoria-props';
import { Button, ButtonBase } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './categoria-styles';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
export const Categoria = ({
  carregando,
  somenteLeitura,
  categoria,
  onClick,
}: CategoriaProps) => {
  const classes = useStyles();
  const carregandoCategoria = carregando || Boolean(categoria) === false;
  // eslint-disable-next-line
  const [acoes, definirAcoes] = useState(false);

  const handleAcoesOpen = useCallback(() => {
    definirAcoes(true);
  }, []);

  const handleAcoesClose = useCallback(() => {
    definirAcoes(false);
  }, []);

  const onClickWrap = useCallback(() => {
    if (onClick) {
      onClick(categoria);
    }
  }, [categoria, onClick]);

  const fotoCategoria = useCallback(() => {
    try {
      return atob(categoria?.foto ?? '')
    } catch {
      return categoria?.foto ?? ''
    }
  }, [categoria?.foto])

  if (carregandoCategoria) {
    return <Skeleton variant="rect" height="85px" width="175px" />;
  }

  const statusCat = somenteLeitura ? null : (
    <span className={classes.status}>
      {categoria?.ativo === true && (
        <div>
          <DoneIcon fontSize="small" />
          Ativo
        </div>
      )}
      {categoria?.ativo !== true && (
        <div>
          <CloseIcon fontSize="small" />
          Inativo
        </div>
      )}
    </span>
  );

  return (
    <div
      role="presentation"
      className={classes.botaoContainer}
      onMouseEnter={handleAcoesOpen}
      onMouseLeave={handleAcoesClose}
      onFocus={handleAcoesOpen}
      onBlur={handleAcoesClose}
    >
      {Boolean(categoria?.categoriaPaiId) === false ? (
        <Button
          type="button"
          variant="contained"
          disableElevation
          color="primary"
          style={{
            backgroundColor: categoria?.cor || undefined,
            opacity: categoria?.ativo === true ? 1 : 0.6,
            backgroundImage: `url(${fotoCategoria()})`,
            position: 'relative',
          }}
          className={classes.botao}
          onClick={onClickWrap}
        >
          {categoria?.foto! && (
            <div style={{
              backgroundColor: categoria?.cor || undefined,
              position: 'absolute',
              borderRadius: 5,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
              opacity: 0.4,
            }}></div>
          )}
          {statusCat}
          <strong style={{ position: 'relative', zIndex: 1, textShadow: '1px 1px 3px rgba(0,0,0,0.3)' }} className={classNames(classes.texto, classes.textoBtn)}>
            {categoria?.nome}
          </strong>
        </Button>
      ) : (
        <ButtonBase
          type="button"
          className={classes.botao}
          style={{
            backgroundImage: `url(${fotoCategoria()})`,
            opacity: categoria?.ativo === true ? 1 : 0.8,
          }}
          onClick={onClickWrap}
          focusRipple
        >
          <span
            className={classes.backdrop}
            style={{
              backgroundColor: categoria?.cor || undefined,
            }}
          />
          <span className={classes.textoContainer}>
            {statusCat}
            <strong className={classNames(classes.texto, classes.textoImg)}>
              {categoria?.nome}
            </strong>
          </span>
        </ButtonBase>
      )}
    </div>
  );
};
