import React, { useCallback, memo } from 'react';
import { Typography, Dialog } from '@material-ui/core';
import { Botao, useNotificacoes } from 'componentes';
import { useStyles } from './cardapio-modal-confirmacao-adm-styles';

export interface ModalConfirmacaoAdmProps {
  aberto: boolean;
  quandoCancelar: () => any;
  quandoLogar: () => any;
}


export const ModalConfirmacaoAdmComp = ({
  aberto,
  quandoCancelar,
  quandoLogar,
}: ModalConfirmacaoAdmProps) => {
  const classes = useStyles();

  const { showInfoMessage } = useNotificacoes();

  const cancelarLogin = useCallback(() => {
    showInfoMessage(
      'Por enquanto o login está restrito apenas aos administradores dos cardápios...',
    );
    quandoCancelar();
  }, [quandoCancelar,showInfoMessage]);

  return (
    <>
      <Dialog open={aberto}>
        <div className={classes.rootModal}>
          <Typography className={classes.textoModal}>Você é Administrador do Cardápio?</Typography>
          <div className={classes.btnsModal}>
            <Botao
              className={classes.btnModal}
              color="secondary"
              style={{ marginRight: '1rem' }}
              onClick={cancelarLogin}
            >
              Não
            </Botao>
            <Botao 
            className={classes.btnModal} 
            color="primary" 
            onClick={quandoLogar}>
              Sim
            </Botao>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const ModalConfirmacao = memo(ModalConfirmacaoAdmComp);
