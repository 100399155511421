import { VariaveisAmbiente } from "ambiente";
import { useApiBase } from "api/base";
import { useCallback } from "react";

export function useGetMovPagamentoById() {
    const { invocarApi, ...outrasPropriedades } = useApiBase();

    const getMovPagamentoById = useCallback(
        (id: string, empresaId: string) => {
            return invocarApi({
                url: `api/v4/empresa/${empresaId}/pagamento/${id}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Domain': 'app.touchone.com.br',
                },
                enviarTokenUsuario: false,
                baseURL: VariaveisAmbiente.masterApi,
            });
        },
        [invocarApi]
    );

    return {
        ...outrasPropriedades,
        getMovPagamentoById,
    };
}