import { FetchCardapioApiAsMasterUserJSON } from 'api/cardapio/utils';
import { ApiQuery } from 'api/interfaces';
import { IdentidadeModel } from 'api/master/models/identidade/identidade-model';

class PostIdentidade implements ApiQuery<string, IdentidadeModel> {
    constructor() {
        this.execute = this.execute.bind(this);
        this.getKey = this.getKey.bind(this);
    }

    getKey(params: string): any[] {
        return ['PostIdentidade', params];
    }

    async execute(model: IdentidadeModel) {
        return await FetchCardapioApiAsMasterUserJSON<IdentidadeModel>(`api/v4/identidade/verificar-acesso`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(model),
        });
    }
}

export const postIdentidade = new PostIdentidade();