import React, { useCallback, useRef } from 'react';
import { Grid, Paper, makeStyles, TextField } from '@material-ui/core';
import { UfSelect, CepInput } from 'componentes';
import { useFormik } from 'formik';
import { DadosDelivery } from 'storage/delivery/models';
import { DeliveryDadosFormEntregaValidation } from './delivery-dados-entrega-validation';
import { DeliveryDadosInstancia } from './delivery-dados-instancia';
const useStyles = makeStyles((theme) => ({
  form: {},
}));

export interface DeliveryDadosEntregaFormProps {
  dadosDelivery: DadosDelivery | undefined;
}

export const DeliveryDadosEntregaForm = React.forwardRef<
  DeliveryDadosInstancia,
  DeliveryDadosEntregaFormProps
>(({ dadosDelivery }: DeliveryDadosEntregaFormProps, ref) => {
  const classes = useStyles();
  const inputNumeroRef = useRef<HTMLDivElement | null>(null);

  React.useImperativeHandle(ref, () => ({
    submit: triggerSubmit,
  }));
  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      lgr: dadosDelivery?.lgr,
      nro: dadosDelivery?.nro,
      bairro: dadosDelivery?.bairro,
      cep: dadosDelivery?.cep,
      cpl: dadosDelivery?.cpl,
      uf: dadosDelivery?.uf,
      municipio: dadosDelivery?.municipio,
      referencia: dadosDelivery?.referencia,
    },
    validationSchema: DeliveryDadosFormEntregaValidation,
    onSubmit: () => {},
  });

  const quandoEncontrarUmCepExecutar = useCallback(
    (dados: any) => {
      setFieldValue('lgr', dados.consultaCEP.Logradouro, true);
      setFieldValue('bairro', dados.consultaCEP.Bairro, true);
      setFieldValue('municipio', dados.consultaCEP.Municipio, true);
      setFieldValue('uf', dados.consultaCEP.UF, true);
      inputNumeroRef?.current?.focus();
    },
    [setFieldValue, inputNumeroRef],
  );

  const triggerSubmit = () => {
    handleSubmit();
    if (Object.entries(errors).length > 0 || values.lgr === undefined) {
      return { sucesso: false, data: undefined, erros: errors };
    }
    return { sucesso: true, data: values, erros: undefined };
  };

  return (
    <Paper className={classes.form} elevation={0} component="form">
      <Grid spacing={1} container>
        <Grid item xs={12} sm={3}>
          <CepInput
            label="CEP"
            placeholder="CEP"
            name="cep"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cep}
            quandoEncontrarUmCepExecutar={quandoEncontrarUmCepExecutar}
            error={Boolean(errors.cep && touched.cep)}
            helperText={errors.cep && touched.cep ? errors.cep : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            label="Logradouro"
            placeholder="Logradouro"
            name="lgr"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lgr}
            error={Boolean(touched.lgr && errors.lgr)}
            helperText={touched.lgr && errors.lgr ? errors.lgr : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            inputRef={inputNumeroRef}
            label="Número"
            name="nro"
            placeholder="Número"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nro}
            error={Boolean(touched.nro && errors.nro)}
            helperText={touched.nro && errors.nro ? errors.nro : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Bairro"
            name="bairro"
            placeholder="Bairro"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.bairro}
            error={Boolean(touched.bairro && errors.bairro)}
            helperText={touched.bairro && errors.bairro ? errors.bairro : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Município"
            name="municipio"
            placeholder="Município"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.municipio}
            error={Boolean(touched.municipio && errors.municipio)}
            helperText={touched.municipio && errors.municipio ? errors.municipio : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <UfSelect
            {...{
              label: 'UF',
              placeholder: 'UF',
              name: 'uf',
              error: Boolean(touched.uf && errors.uf),
              helperText: Boolean(touched.uf && errors.uf) ? errors.uf : undefined,
            }}
            onBlur={handleBlur}
            ufValue={values.uf ? values.uf : ''}
            onInputChange={(event, newValue) => {
              if (event) {
                setFieldValue('uf', newValue, true);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Complemento"
            name="cpl"
            placeholder="Complemento"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cpl}
            error={Boolean(touched.cpl && errors.cpl)}
            helperText={touched.cpl && errors.cpl ? errors.cpl : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Referência"
            name="referencia"
            placeholder="Referência"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.referencia}
            error={Boolean(touched.referencia && errors.referencia)}
            helperText={touched.referencia && errors.referencia ? errors.referencia : undefined}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
});
