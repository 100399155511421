import { MovRegistrarResponseModel } from "api/cardapio/models/mov-pag/mov-registrar-response"
import React, { useCallback, useMemo } from "react"
import QRCode from 'qrcode.react';
import { Box, Button, Card, Divider, Fade, Grid, Typography, useTheme } from "@material-ui/core";
import { TextFieldSaurus } from "componentes/inputs/text-field-saurus";
import { Carregando, useNotificacoes } from "componentes";
import { CopiarIcon } from "componentes/icons/copiar-icon";
import { useStyles } from "./pagamento-pix-detalhes-styles";
import { CardapioStorageKeys, useCardapioStorage } from "utils/cardapio-storage";
import { CardapioDadosProps } from "modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props";
import { EnumStatusPix } from "../../pagamento-pix";
import { toDecimalString } from "utils/to-decimal";
import { ErroIcon } from "componentes/icons/erro-icon";
import { MovSimplesProdutoModel } from "api/cardapio/models/produto/mov-simples-produto-model";

export interface PagamentoPixProps {
    model: MovRegistrarResponseModel;
    carregandoCriarPedido: boolean;
    statusPix: EnumStatusPix;
    handlePedido: () => Promise<void>;
    cancelarPedido: () => void;
    fechar: () => void;
    pedidoErro: boolean;
    handlePedidoPix: () => Promise<void>;
}

export const PagamentoPixDetalhes = ({ model, carregandoCriarPedido, statusPix, handlePedido, cancelarPedido, pedidoErro, handlePedidoPix }: PagamentoPixProps) => {
    const { showSuccessMessage, showErrorMessage } = useNotificacoes();
    const { getRegistro } = useCardapioStorage()
    const dadosGerais = getRegistro(CardapioStorageKeys.DadosGerais, false) as CardapioDadosProps;
    const sacola = getRegistro(CardapioStorageKeys.Sacola, false) as MovSimplesProdutoModel[] | null
    const { empresa } = dadosGerais
    const classes = useStyles()
    const theme = useTheme()

    const copiarCodigo = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(model.pixCopiaCola)
            showSuccessMessage('Código copiado!')
        } catch (err: any) {
            showErrorMessage('Ocorreu um erro ao tentar copiar o código, tente novamente.')
        }
    }, [model.pixCopiaCola, showErrorMessage, showSuccessMessage])

    const btnCopiar = useMemo(() => (
        <Box mt={"2px"}>
            <Button className={classes.btnIcon} variant="text" onClick={copiarCodigo}>
                <CopiarIcon tipo={theme.palette.type === "light" ? 'BUTTON' : 'BUTTON_PRIMARY'} />
            </Button>
        </Box>
    ), [classes.btnIcon, copiarCodigo, theme.palette.type])

    const tempoExpiracao = useMemo(() => {
        const data = new Date(model.dataRegistro)

        const expiracao = new Date(data.setMinutes(data.getMinutes() + 30))
        const horas = ('00' + expiracao.getHours()).substr(-2)
        const minutos = ('00' + expiracao.getMinutes()).substr(-2)
        return `${horas}:${minutos}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const preco = (sacola ?? [])?.reduce((a, b) => a + b.vFinal, 0) ?? 0


    return (
        <>
            {carregandoCriarPedido && (
                <Fade in>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Carregando
                                carregando
                                modelo="normal"
                                titulo="Preparando pedido..."
                                mensagem={
                                    statusPix === EnumStatusPix.Aceito
                                        ? "Seu pagamento foi aceito e agora estamos preparando seu pedido, por favor aguarde."
                                        : "Agora estamos preparando seu pedido, por favor aguarde."
                                }
                            />
                        </Grid>
                    </Grid>
                </Fade>
            )}
            {pedidoErro && (
                <Fade in>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box textAlign={"center"} mb={3}>
                                <Box mb={3}>
                                    <ErroIcon tipo={theme.palette.type === 'dark' ? "BUTTON_PRIMARY" : 'BUTTON'} style={{ width: 60, height: 60 }} />
                                </Box>
                                <Typography>
                                    Ocorreu um problema ao consultar o pagamento, pague na entrega ou entre em contato conosco pelo telefone {empresa.fone}.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} container justify="center">
                            <Button variant="contained" size="large" onClick={handlePedidoPix}>
                                Pagar na entrega
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justify="center">
                            <Button
                                variant="outlined"
                                color={theme.palette.type === "light" ? "primary" : "inherit"}
                                onClick={cancelarPedido}
                            >
                                Cancelar Pedido
                            </Button>
                        </Grid>
                    </Grid>
                </Fade >
            )}
            {statusPix === EnumStatusPix.Neutro && !carregandoCriarPedido && !pedidoErro ? (
                <Fade in>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Box textAlign={"center"} mb={3} color="textPrimary">
                                <Typography variant="h5" style={{ fontWeight: 700, fontSize: 32 }}>
                                    Aguardando pagamento por PIX
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" mb={3}>
                                <Card style={{ boxShadow: 'none', backgroundColor: theme.palette.type === 'light' ? '#f2f2f2' : "rgb(85 85 85)" }}>
                                    <Box p={1} minWidth={300} textAlign="center">
                                        <Typography color="textPrimary" variant="body1" style={{ fontWeight: 500 }}>
                                            Valor à pagar
                                        </Typography>
                                        <Divider style={{ marginTop: theme.spacing(1) }} />
                                        <Typography color={theme.palette.type === "light" ? "primary" : "textPrimary"} variant="h5" style={{ fontWeight: 500, marginTop: theme.spacing(1) }}>
                                            R$ {toDecimalString(preco ?? 0)}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Box>
                            <Box width="100%" justifyContent={"center"} display={"flex"} mb={3} className={classes.boxQRCode}>
                                <QRCode
                                    bgColor={"#FFFFFF"}
                                    value={model.pixCopiaCola}
                                    size={250}
                                    renderAs="svg"
                                />
                            </Box>
                            <Box textAlign={"center"} mb={1}>
                                <Typography>
                                    Escaneie o código QR ou copie o código abaixo para pagar via PIX:
                                </Typography>
                            </Box>
                            <TextFieldSaurus
                                color="primary"
                                value={model.pixCopiaCola}
                                className={classes.inputPix}
                                endAdornmentButton={btnCopiar}
                            />
                            <Box mt={1} textAlign="center">
                                <Typography>
                                    O tempo para pagar irá acabar às: <span style={{ fontWeight: 600, fontSize: 20 }}>{tempoExpiracao}</span>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Fade>
            ) : !carregandoCriarPedido && statusPix === EnumStatusPix.Cancelado && !pedidoErro && (
                <Fade in>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box textAlign={"center"}>
                                <Typography variant="h6">
                                    PIX cancelado, ultrapassou o tempo limite de pagamento. Gostaria de pagar na entrega ou cancelar o pedido?
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Button variant="contained" fullWidth onClick={handlePedido}>
                                Pagar na Entrega
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Button
                                variant="outlined"
                                fullWidth
                                color={theme.palette.type === "light" ? "primary" : "inherit"}
                                onClick={cancelarPedido}
                            >
                                Cancelar Pedido
                            </Button>
                        </Grid>
                    </Grid>
                </Fade>
            )
            }
        </>
    )
}