export const ImagemBlobParaBase64 = (arquivo: File): Promise<string> => {
  return new Promise((resolve) => {
    if (!arquivo) {
      resolve('');
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target?.result?.toString() || '');
    };

    reader.readAsDataURL(arquivo);
  });
};
