import { Typography } from "@material-ui/core";
import { MesaModel } from "api/pedidos/models";
import React from "react";
import { MesaQrCodeCard } from "./qr-mesa-card";

export interface MesaQrCodeListDataProps {
    componentRef: React.MutableRefObject<null>;
    list: Array<MesaModel>;
    empresaId: string;
    tamanho: number;
    mesaMin: number;
    mesaMax: number;
    nomeEmpresa: string;
    infoEmpresa: string;
    opcaoTitulo: number;
    opcaoInfo: number;
    opcaoBorda: number;
}

export const MesaQrCodeListData = (props: MesaQrCodeListDataProps) => {
    return (
        <>
            {props.list.length === 0 && (
                <Typography
                    variant="body1"
                    color="primary"
                    style={{
                        fontSize: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    Nenhum mesa encontrada
                </Typography>
            )}

            {props.list.filter(x => {
                try {
                    let ret = false;
                    if (props.mesaMin) {
                        ret = parseInt(x.codigo) < props.mesaMin;
                    }
                    if (props.mesaMax && !ret) {
                        ret = parseInt(x.codigo) > props.mesaMax;
                    }

                    return !ret;
                } catch (e: any) {
                    return true;
                }
            }).map((item, index) => {
                return (
                    <>
                        <MesaQrCodeCard
                            mesa={item}
                            empresaId={props.empresaId}
                            tamanho={props.tamanho}
                            index={index}
                            nomeEmpresa={props.nomeEmpresa}
                            componentRef={props.componentRef}
                            infoEmpresa={props.infoEmpresa}
                            opcaoInfo={props.opcaoInfo}
                            opcaoTitulo={props.opcaoTitulo}
                            opcaoBorda={props.opcaoBorda}
                        />
                    </>
                );
            })}
        </>
    );
};